<template>
    <div>
        <div class="modal" id="newQuestionModal" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">New Question</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div class="modal-body justify-content-center">
                        <div style="display: flex;">
                            <input class="form-control" v-model="question.text" placeholder="Question">
                        </div>
                        <div style="text-align: center;">
                            <button type="button" class="btn_post_n mt-3" @click="createQuestion()">Create question</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {successMessage} from "../../../services/messages";

export default {
    name: "NewQuestionModal",
    props: ['modal'],
    data() {
        return {
            question: {
                text: null
            }
        };
    },
    methods: {
        async createQuestion() {
            await this.$http.post(`${this.$http.apiUrl()}cms/create-question`, this.question);
            successMessage("New question successfully created");
            this.$emit('refresh');
            this.modal.hide();
        },
    },
}
</script>
