<template>
    <div class="modal" id="instagramModalPostPreview" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-body box_md_t" v-if="publish_model.platform === 'instagram'">
                    <div class="row">
                        <div class="col-12 col-lg-6 left-part">
                            <div class="slide-image-container">
                                <carousel :scrollPerPage="false" :perPage="1" :centerMode="true" :navigationEnabled="false" :paginationEnabled="true">
                                    <slide v-for="image in publish_model.image_job.finished_worked_images">
                                        <img class="postPreview__image" :src="image.image_url">
                                        <div>
                                            <div class="row mt-3">
                                                <div class="col-12">
                                                    <label class="mt-3 postPreview__hashtags-label">Tag Products</label>
                                                    <div v-if="publish_model.tag_products[image.id]">
                                                        <div v-for="product in publish_model.tag_products[image.id]">
                                                            {{product.product_name || product.product_id}}
                                                        </div>
                                                    </div>
                                                    <div class="box_select" v-if="user_instagram && user_instagram.instagram_connect.product_tags == 1">
                                                        <div class="select_calendar" @click="select_product = !select_product">
                                                            <div class="title_s">
                                                                Product
                                                            </div>
                                                            <div class="col-2 text-end">
                                                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M18.8528 6.94727C18.6066 6.70121 18.2729 6.56299 17.9248 6.56299C17.5768 6.56299 17.243 6.70121 16.9969 6.94727L10.5 13.4441L4.00315 6.94727C3.75561 6.70819 3.42407 6.5759 3.07994 6.57889C2.73581 6.58188 2.40661 6.71991 2.16327 6.96326C1.91992 7.20661 1.78189 7.5358 1.7789 7.87993C1.77591 8.22406 1.9082 8.5556 2.14728 8.80314L9.57209 16.228C9.81822 16.474 10.152 16.6122 10.5 16.6122C10.8481 16.6122 11.1818 16.474 11.428 16.228L18.8528 8.80314C19.0988 8.55701 19.2371 8.22324 19.2371 7.87521C19.2371 7.52718 19.0988 7.1934 18.8528 6.94727Z" fill="#494949"/>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div v-if="select_product" class="calendar_box">
                                                            <div class="postPreview__hashtags-block with_underline d-flex flex-column">
                                                                <div class="box_search_i w-100">
                                                                    <input @keyup="keypressSearchProduct" type="text" class="search_prod_inp w-100" placeholder="name">
                                                                    <img class="search_icon" src="@/assets/icons/search.svg" alt="">
                                                                </div>
                                                                <h3 class="product-not-found" v-if="instagram_products.length === 0">Products not found</h3>
                                                                <div v-for="(prod, num) in instagram_products">
                                                                    <div v-if="num < current_prods_page * 2 && num >= (current_prods_page - 1) * 2" class="box_search_prod_shopify">
                                                                        <div class="prod_info">
                                                                            <div class="prod_info_item">{{ prod.product_name }}</div>
                                                                            <div class="prod_info_item prod_info_secondary">ID {{ prod.product_id }}</div>
                                                                        </div>
                                                                        <div class="prod_options_container">
                                                                            <div class="prod_image_item">
                                                                                <img class="prod_image" v-if="prod.image_url" :src="prod.image_url" alt="">
                                                                            </div>
                                                                            <div class="prod_add_container">
                                                                                <div class="box_add_p">
                                                                                    <button v-if="publish_model.tag_products[image.id] != undefined &&
                                                                                            isInstagramProductSelected(prod.product_id, image.id)"
                                                                                            class="btn_added_prod" @click="addProd(prod, image.id)">Added</button>
                                                                                    <button v-else-if="publish_model.tag_products[image.id] == undefined ||
                                                                                            (publish_model.tag_products[image.id] != undefined &&
                                                                                            publish_model.tag_products[image.id].length < 5)"
                                                                                            class="btn_add_prod" @click="addProd(prod, image.id)">Add</button>
                                                                                    <button v-else class="btn_add_prod disabled" disabled>Add</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="mt-4 d-flex justify-content-between align-items-center w-100 mb-3">
                                                                    <button :disabled="current_prods_page <= 1"
                                                                            class="btn-carousel"
                                                                            :class="{disabled: current_prods_page <= 1}"
                                                                            @click="current_prods_page--">Prev</button>
                                                                    <span class="mt-3">{{current_prods_page}}</span>
                                                                    <button :disabled="current_prods_page >= instagram_products.length / 2"
                                                                            class="btn-carousel"
                                                                            :class="{disabled: current_prods_page >= instagram_products.length / 2}"
                                                                            @click="current_prods_page++">Next</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </slide>
                                </carousel>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 right-part with_left-border">
                            <div class="row w-100">
                                <div class="col-12 d-flex flex-column justify-content-between">
                                    <div class="row">
                                        <div class="col-4 offset-8 text-end">
                                            <img class="postPreview__delete_icon cp" src="@/assets/icons/trash.png" @click="deletePost">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <label class="postPreview__description-label">Description</label>
                                            <input class="postPreview__description with_underline" type="text" v-model="publish_model.description" placeholder="Description">
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-12">
                                            <label class="mt-3 postPreview__hashtags-label">Tag Users</label>
                                            <div class="postPreview__hashtags-block with_underline">
                                                <img class="postPreview__hashtags-icon" src="@/assets/icons/Edit_underline.svg">
                                                <input type="text" class="postPreview__hashtags" v-model="publish_model.tag_users" placeholder="ex: user_name,user_name_2">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-12">
                                            <label class="mt-3 postPreview__hashtags-label">Hashtags</label>
                                            <div class="postPreview__hashtags-block with_underline">
                                                <img class="postPreview__hashtags-icon" src="@/assets/icons/Edit_underline.svg">
                                                <input type="text" class="postPreview__hashtags" v-model="publish_model.hashtags" placeholder="ex: #babyonesie #babyfashion">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-12">
                                            <label class="mt-3 postPreview__date-time-label">Facebook page posts</label>
                                            <div class="postPreview__date-time-block with_underline" @click="autoPostFacebook">
                                                <div v-if="publish_model.facebook_pages.length > 0">
                                                    <div class="cp" v-for="facebookPage in publish_model.facebook_pages">{{facebookPage.name}} ({{facebookPage.category}})</div>
                                                </div>
                                                <div v-else>
                                                    <div class="cp">No selected pages</div>
                                                </div>
                                            </div>
                                            <div v-if="auto_post_facebook" class="mt-2">
                                                <div v-for="page in facebook_pages" class="row">
                                                    <div class="col-8">{{page.name}} ({{page.category}})</div>
                                                    <div class="col-4">
                                                        <button class="facebook-btn" :class="{active: isInSelectedFacebookPages(page.id)}" @click="selectFacebookPage(page.id)">
                                                            Select
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-12">
                                            <label class="mt-3 postPreview__date-time-label">Scheduled Time</label>
                                            <div class="postPreview__date-time-block with_underline">
                                                <img src="@/assets/icons/timer-icon.svg">
                                                <span class="postPreview__date-time cp" @click="show_calendar = !show_calendar">
                                                    {{publish_model.available_at}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="show_calendar" class="row mt-3">
                                        <div class="col-12">
                                            <SelectTime :available_at="publish_model.available_at" @timeChanged="timeChanged"/>
                                            <Calendar :selectedDateInput="selected_date" @selected_date="selectedDate"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row w-100 mt-3">
                                <div class="col-12 d-flex justify-content-between">
                                    <div class="modal-btn btn-cancel" data-bs-dismiss="modal" aria-label="Close">Back</div>
                                    <div class="modal-btn btn-save" @click="save">Save</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {errorMessage} from "../../services/messages";
import {mapMutations} from "vuex";
import moment from 'moment';
import {Modal} from "bootstrap";
import { Carousel, Slide } from 'vue-carousel';
import Calendar from "@/components/Calendar";
import helpFunctions from "../../helpFunctions";
import SelectTime from "../SelectTime";
import ('@/css/carousel.css');

export default {
    name: "InstagramPost",
    props: ['user_id'],
    components: {
        SelectTime,
        Carousel,
        Slide,
        Calendar,
    },
    data() {
        return {
            show_calendar: false,

            am_pm: 'AM',
            hour: '0',
            minute: '00',
            selected_date: null,

            modalPostPreview: null,
            auto_post_facebook: false,
            user_instagram: false,
            instagram_products: [],
            facebook_pages: false,
            select_product: false,

            current_prods_page: 1,

            publish_model: {}
        }
    },
    async mounted() {
        this.modalPostPreview = new Modal(document.getElementById('instagramModalPostPreview'));
        await this.getUserInstagram();
    },
    destroyed() {
        if (this.modalPostPreview) {
            this.modalPostPreview = null;
        }
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
            'setUser',
        ]),
        async getUserInstagram() {
            this.showLoader();
            try {
                const result = await this.$http.getAuth(`${this.$http.apiUrl()}is-instagram-connect`);
                this.user_instagram = result?.data?.data || null;
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        async load() {
            this.publish_model.available_at = moment.utc(this.publish_model.available_at).local().format('YYYY-MM-DD HH:mm:ss');
            this.am_pm = moment(this.publish_model.available_at).format('A');
            this.hour = moment(this.publish_model.available_at).format('h');
            this.minute = moment(this.publish_model.available_at).format('mm');
            this.selected_date = moment(this.publish_model.available_at).format('YYYY-MM-DD');

            Object.values(this.publish_model.tag_products).forEach(image_object => {
                image_object.forEach(product => {
                    let index = this.instagram_products.findIndex((obj) => obj.product_id === product.product_id);
                    if (index < 0) {
                        this.instagram_products.push(product);
                    }
                });
            });

            this.modalPostPreview.show();
        },
        selectedDate(data) {
            this.selected_date = data;
            this.publish_model.available_at = helpFunctions.getFormattedDateTime(this.selected_date, this.hour, this.minute, this.am_pm);
        },
        timeChanged(new_time) {
            this.hour = new_time[0];
            this.minute = new_time[1];
            this.am_pm = new_time[2];
            this.publish_model.available_at = helpFunctions.getFormattedDateTime(this.selected_date, this.hour, this.minute, this.am_pm);
        },
        deletePost() {
            this.$emit("deletePost");
        },
        postDeleted() {
            this.modalPostPreview.hide();
        },
        async save() {
            this.showLoader();
            this.publish_model.date_publication = helpFunctions.getFormattedDateTime(this.selected_date, this.hour, this.minute, this.am_pm);
            this.publish_model.timezone = moment.tz.guess(true);
            this.publish_model._method = 'Put';
            if(this.user_id) {
                this.publish_model.user_id = this.user_id;
            }
            try {
                await this.$http.postAuth(`${this.$http.apiUrl()}schedule/` + this.publish_model.schedule_id, this.publish_model);
                this.$emit("getCalendar");
                this.modalPostPreview.hide();
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }

            this.hideLoader();
        },
        addProd(prod, image_id) {
            let savedProduct = this.publish_model.tag_products[image_id].find(obj => {
                return obj.product_id === prod.product_id;
            });
            if (savedProduct === undefined) {
                if (this.publish_model.tag_products[image_id].length < 5) {
                    let added_prods = this.publish_model.tag_products;
                    added_prods[image_id].push({
                        product_id: prod.product_id,
                        product_name: prod.product_name,
                        image_url: prod.image_url
                    });
                    this.publish_model.tag_products = added_prods;
                }
            }
            else {
                let index = this.publish_model.tag_products[image_id].findIndex((obj) => obj.product_id === prod.product_id);
                if (index > -1) {
                    this.publish_model.tag_products[image_id].splice(index, 1);
                }
            }
            this.select_product = !this.select_product;
            this.select_product = !this.select_product;
        },
        isInstagramProductSelected(product_id, image_id) {
            let index = this.publish_model.tag_products[image_id].findIndex((obj) => obj.product_id === product_id);
            return index > -1;
        },
        selectFacebookPage(id) {
            let page = this.publish_model.facebook_pages.filter(obj => { return obj.id === id; });
            if (page.length <= 0) {
                this.publish_model.facebook_pages.push(this.facebook_pages.filter(obj => { return obj.id === id; })[0]);
            } else {
                this.publish_model.facebook_pages.splice(this.publish_model.facebook_pages.findIndex(obj => obj.id === id));
            }
            this.auto_post_facebook = this.publish_model.facebook_pages.length > 0;
        },
        isInSelectedFacebookPages(id) {
            return this.publish_model.facebook_pages.filter(obj => { return obj.id == id; }).length > 0;
        },
        async autoPostFacebook() {
            if (!this.auto_post_facebook) {
                this.showLoader();
                try {
                    let resp = await this.$http.getAuth(`${this.$http.apiUrl()}search-pages-facebook`);
                    if (resp.data.data) {
                        this.facebook_pages = resp.data.data;
                    }
                } catch (e) {
                    const message = e?.response?.data?.error?.message || 'ERROR';
                    errorMessage(message);
                }
                this.hideLoader();
            }
            this.auto_post_facebook = !this.auto_post_facebook;
        },
        async keypressSearchProduct(event) {
            let search_text = event.target.value;
            if (search_text && search_text.length > 2) {
                this.showLoader();
                try {
                    const result = await this.$http.postAuth(`${this.$http.apiUrl()}search-product-instagram`, { search: search_text });
                    this.instagram_products = result?.data?.data;
                } catch (e) {
                    const message = e?.response?.data?.error?.message || 'ERROR';
                    errorMessage(message);
                }
                this.hideLoader();
            } else {
                this.instagram_products = [];
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.disabled {
    cursor: default;
    opacity: 0.5;
}
.select_calendar {
    display: flex;
    justify-content: space-between;
    padding: 21px 24px 21px 34px;
    height: 65px;
    align-items: center;
    cursor: pointer;
}
.box_select {
    cursor: pointer;
    margin-top: 27px;
    background: white;
    border-radius: 5px;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.15));
}
.search_prod_inp {
    background: white;
    border: 0.3px solid #494949;
    box-sizing: border-box;
    border-radius: 100px;
    height: 41px;
    width: 100%;
    padding-left: 45px;
}
.search_icon {
    position: relative;
    left: 15px;
    top: -35px;
    cursor: pointer;
}
.box_search_prod_shopify {
    padding: 5px;
    width: 100%;
    margin-top: 33px;
    background: white;
    border: 0.2px solid #494949;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.prod_image {
    max-height: 150px;
}
.slide-image {
    &-container {
        max-width: 350px;
        //display: inline-table;
        //max-height: 350px;
        //margin-bottom: 180px;
    }
}
.modal-dialog {
    max-width: 900px !important;
    width: 900px !important;
}
.postPreview {
    &__delete_icon {
        max-width: 18px;
        max-height: 18px;
    }
    &__description {
        width: 100%;
        border: none;
        font-size: 20px;
        line-height: 44px;
        font-weight: 600;
        color: #494949;

        &-label {
            font-size: 12px;
        }
    }
    &__hashtags {
        width: 100%;
        border: none;
        font-size: 16px;
        line-height: 30px;

        &-icon {
            width: 18px;
            height: 18px;
            margin-right: 12px;
        }
        &-block {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: -5px;
        }
        &-label {
            font-size: 12px;
        }
    }
    &__date-time {
        margin-left: 10px;
        font-size: 14px;
        line-height: 30px;

        &-block {
        }
        &-label {
            font-size: 12px;
        }
    }
    &__picture-name {
        &-icon {
            width: 18px;
            height: 18px;
            margin-right: 12px;
        }
        &-field {
            border: none;
            width: 100%;
        }
        &-block {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px;
        }
        &-label {
            font-size: 12px;
        }
    }
    &__alt-text {
        &-icon {
            width: 18px;
            height: 18px;
            margin-right: 12px;
        }
        &-field {
            border: none;
            width: 100%;
        }
        &-block {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px;
        }
        &-label {
            font-size: 12px;
        }
    }
}
.with_underline {
    border-bottom: 0.5px solid rgba(73, 73, 73, 0.25);
}
.with_left-border {
    border-left: 0.5px solid rgba(73, 73, 73, 0.25);
}
.modal-body {
    padding: 0;
}
.modal-content {
    background-color: white!important;
    border-radius: 12px;
    border: none;
}
.left-part {
    padding: 52px 32px 52px 52px;
    display: flex;
    justify-content: center;
}
.right-part {
    padding: 52px 52px 52px 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.modal-btn {
    width: 142px;
    height: 48px;

    display: flex;
    justify-content: center;
    align-items: center;

    background: white;
    border: 0.5px solid #494949;
    border-radius: 100px;

    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #494949;

    cursor: pointer;

    &.btn-save {
        background: #FCF5EF;
    }
    &:hover {
        border-width: 2px;
    }
}

@media only screen and (max-width: 992px) {
    .slide-image-container {
        max-width: 95% !important;
    }
    .box_select {
        width: 90%;
    }
    .calendar_box {
        padding: 14px 28px 30px 28px;
    }
    .modal-dialog {
        width: 100% !important;
        margin-left: 0;
        margin-right: 0;
    }
    .modal-content {
        background-color: white!important;
        margin: 16px 16px 70px;
    }
    .left-part {
        padding: 25px 25px 10px 25px !important;
    }
    .right-part {
        padding: 10px 25px 25px 25px !important;
    }
    .slide-image {
        &-container {
            max-width: 250px;
            //max-height: 250px;
        }
    }
}
</style>
