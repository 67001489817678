<template>
    <div>
        <CmsHeader :error-message="errorMessage" :success-message="successMessage" />
        <CmsBody :error-message="errorMessage" :success-message="successMessage" />
    </div>
</template>

<script>
import CmsHeader from "../components/cms/CmsHeader";
import CmsBody from "../components/cms/CmsBody";

export default {
    name: "Cms",
    components: {
        CmsHeader,
        CmsBody
    },
    data() {
        return {
            errorMessage: "",
            successMessage: "",
        }
    },
}
</script>
