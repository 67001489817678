import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        //stripeKey: 'pk_test_51KoBxjJDWX8dXJe36Nl0GQpegKAG0crdsyGP5fyLrtPxbkrjevFioMqrQgzRS6u6JuAFHzx0QLIfo8uO9rQwRVbC00Z3QGDTwY',
        //PROD STRIPE KEY
        stripeKey: 'pk_live_51KoBxjJDWX8dXJe36gj0uyaSFrdJGAWiDTErn2spmf3lBNnvXSPlLKC3CyEuIpypCkjTfv4I2xXYX1K1eY6rPgtx00hDgosoYI',
        loading: false,
        user: {},
        phone: null,
        update_user_profile: null,
        logo: 'https://strobeart.s3.amazonaws.com/files/logo/1692731339ST-logo_2.svg',
        defaultColor: '#D8C3AF',
        defaultFont: 'Montserrat',
        instagramLink: 'https://www.instagram.com/drskinfeed/?hl=en',
        facebookLink: 'https://www.facebook.com/drskinfeed/',
        tiktokLink: 'https://www.tiktok.com/@dr.skinfeed',
        stylesSet: false,
        isLogged: (_ => {
            return !!localStorage.getItem('strobeart_jwt');
        })(),
    },
    mutations: {
        setUser(state, data) {
            state.user = data;
        },
        setUpdateUserProfile(state, data) {
            state.update_user_profile = data;
        },
        setPhone(state, data) {
            state.phone = data;
        },
        showLoader(state) {
            state.loading = true;
        },
        hideLoader(state) {
            state.loading = false;
        },
        setLogged(state, value) {
            state.isLogged = !!value;
        },
        setLogo(state, data) {
            state.logo = data;
        },
        setDefaultColor(state, data) {
            state.defaultColor = data;
        },
        setDefaultFont(state, data) {
            state.defaultFont = data;
        },
        setInstagramLink(state, data) {
            state.instagramLink = data;
        },
        setFacebookLink(state, data) {
            state.facebookLink = data;
        },
        setTiktokLink(state, data) {
            state.tiktokLink = data;
        },
        setStyles(state, data) {
            state.stylesSet = data;
        },
        askNotificationPermission() {
            function handlePermission(permission)
            {
                // notificationBtn.style.display =
                //     Notification.permission === 'granted' ? 'none' : 'block';
            }
            function checkNotificationPromise() {
                try {
                    Notification.requestPermission().then();
                } catch (e) {
                    return false;
                }
                return true;
            }
            if (!('Notification' in window)) {
                console.log("This browser does not support notifications.");
            } else if (checkNotificationPromise()) {
                Notification.requestPermission().then((permission) => {
                    handlePermission(permission);
                });
            } else {
                Notification.requestPermission((permission) => {
                    handlePermission(permission);
                });
            }
        },
        makeNotification(state, data) {
            return new Notification(data.title, {
                body: data.text,
                icon: data.img
            });
        }
    },
    getters: {
        getUser(state) {
            return state.user;
        },
        getStripeKey(state) {
            return state.stripeKey;
        },
        isUpdatedUserProfile(state) {
           return state.update_user_profile;
        },
        getPhone(state) {
            return state.phone;
        },
        isLogged(state) {
            return state.isLogged;
        },
        getLogo(state) {
            return state.logo;
        },
        getDefaultColor(state) {
            return state.defaultColor;
        },
        getDefaultFont(state) {
            return state.defaultFont;
        },
        getInstagramLink(state) {
            return state.instagramLink;
        },
        getFacebookLink(state) {
            return state.facebookLink;
        },
        getTiktokLink(state) {
            return state.tiktokLink;
        },
        getStyles(state) {
            return state.stylesSet;
        },
    },
    actions: {},
    modules: {}
})
