<template>
    <div style="margin-left: 25px;">
        <div class="color_picker">
            Pick color:
            <input id="colorPicker" class="form-control w-25" style="margin-left: 25px; height: 40px;" v-model="colorInfo" type="color">
            <input id="colorInput" class="form-control w-25" style="margin-left: 25px;" v-model="colorInfo">
        </div>
        <div class="dropdown" style="margin-top: 25px;">
            <select class="form-select w-25" aria-label="Default select example" v-model="defaultFont">
                <option :value="font.family" v-for="font in fonts" :style="{fontFamily: font.family}" >{{ font.family }}</option>
            </select>
        </div>
        <div style="margin-top: 25px;">
            <button type="button" class="btn_post_n2" @click="openModal()">Select new logo!</button>
        </div>
        <div style="margin-top: 25px; margin-bottom: 25px;">
            <button type="button" class="btn_post_n" @click="saveChanges()">Save changes!</button>
        </div>
        <AddNewLogo />
    </div>
</template>

<script>
import AddNewLogo from "./Modals/AddNewLogoModal";
import {Modal} from "bootstrap";
import {mapMutations} from "vuex";
import {errorMessage, successMessage} from "../../services/messages";

export default {
    name: "CmsUiChanges",
    components: {
        AddNewLogo,
    },
    data() {
        return {
            addFileModal: null,
            fonts: [],
            defaultFont: null,
            defaulColorInfo: '#ffffff',
            colorInfo: '#ffffff'
        };
    },
    mounted() {
        this.loadGoogleFonts();
        this.getCurrentStyles();
        this.addFileModal = new Modal(document.getElementById('addFileModal'));
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
            'setDefaultColor',
            'setDefaultFont'
        ]),
        async loadGoogleFonts() {
            let allFonts = await this.$http.get(('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyCW6IYr0wPkY5m6kKnRmZ8RJee_M_La4JI&sort=popularity'));
            this.fonts = [];
            for (let i = 0; i < 50; i++) {
                this.fonts.push(allFonts.data.items[i]);
            }
        },
        openModal() {
            this.addFileModal.show();
        },
        async getCurrentStyles() {
            let response = await this.$http.get(`${this.$http.apiUrl()}cms/get-current-styles`);
            this.colorInfo = response.data.data.main_color;
            this.defaultFont = response.data.data.font_family;
        },
        async saveChanges() {
            try {
                let response = await this.$http.post(`${this.$http.apiUrl()}cms/update-style`, {
                        'font_family': this.defaultFont,
                        'main_color': this.colorInfo
                    });
                if(response.data.success) {
                    successMessage('Main style as Font Family and Main color updated');
                    localStorage.setItem('defaultColor', this.colorInfo);
                    localStorage.setItem('defaultFont', this.defaultFont);
                    this.setDefaultColor(this.colorInfo);
                    this.setDefaultFont(this.defaultFont);
                }
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
        }
    }
}
</script>

<style scoped>
.color_picker {
    margin-top: 25px;
    display: flex;
    width: 100%;
}
</style>
