<template>
    <MainLayout>
        <div class="container body_active">
            <div class="row m_b_14" :class="isMobile ? 'm_t_34' : 'm_t_51'">
                <div class="col-12 text-start">
                    <div class="inbox main_text">
                        Inbox
                    </div>
                </div>
            </div>
            <div class="row" :class="isMobile ? 'm_b_45' : 'm_b_82'">
                <router-link :to="{ name: 'ChatsList'}" class="col-12 text-start text-decoration-none align-content-center cp">
                    <div class="message_box white_background message_text main_text d-flex align-items-center text-truncate">
                        <svg width="29" height="22" viewBox="0 0 29 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="2" y="1" width="25.8667" height="20" rx="4" stroke="#494949" stroke-width="2"/>
                            <path d="M1.3667 4.7666L14.5667 12.4666L28.5 4.7666" stroke="#494949" stroke-width="2"/>
                        </svg>
                        You have <span class="count_message"><ChatsMessageCount/></span> new messages.
                    </div>
                </router-link>
            </div>
            <ActiveJobs ref="active_jobs" :showCreateJob="false" :isMobile="isMobile" @getJobs="getJobs" />
        </div>
    </MainLayout>
</template>

<script>
import {mapMutations, mapGetters} from 'vuex';
import MainLayout from "@/layouts/MainLayout";
import TypeUserEnum from "@/enums/TypeUserEnum";
import ChatsMessageCount from "../components/ChatsMessageCount";
import { errorMessage } from '../services/messages';
import ActiveJobs from "../components/ActiveJobs";
import ('../css/custom.css');

export default {
    name: 'BusinessHomePage',
    components: {
        ActiveJobs,
        MainLayout,
        ChatsMessageCount,
    },
    data() {
        return {
            jobs: []
        }
    },
    async mounted() {
        this.user = JSON.parse(localStorage.getItem('strobeart_user'));
        if (!this.user || this.user.type_user !== TypeUserEnum.BUSINESS) {
            this.$router.push({to: '/login', name: "Login"}).then();
        }
        await this.getJobs();
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
        ]),
        async getJobs() {
            this.showLoader();
            const result = await this.$http.getAuth(`${this.$http.apiUrl()}get-user-owned-jobs`);
            this.hideLoader();
            if (!result?.data?.success) {
                return errorMessage('OOPS... Something went wrong...');
            }
            this.jobs = result?.data?.data?.jobs || [];
            //SHOULD FILL PREVIEW ARRAY
            let image_preview_urls = {};
            this.jobs.forEach(job => {
                image_preview_urls[job.id] = null;
            });
            //SHOULD FILL PREVIEW ARRAY
            this.$refs.active_jobs.load(image_preview_urls, this.jobs);
        },
    },
    computed: {
        ...mapGetters([
            'getUser'
        ]),
        isMobile() {
            return window.innerWidth <= 992;
        },
    },
}
</script>

<style lang="scss" scoped>
.body_active {
    height: 100%;
}
.main_text {
    color: #494949;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
}
.inbox {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
}
.message_text {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
}
.white_background {
    background: white;
}
.message_box {
    width: 414px;
    height: 65px;
    padding: 10px 36px;
    border-radius: 5px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);

    svg {
        margin-right: 22px;
        min-width: 27.87px;
        min-height: 22px;
    }
}
.count_message {
    padding: 0 5px;
}

@media only screen and (max-width: 992px) {
    .message_box {
        width: 100%;

        @media only screen and (max-width: 576px) {
            padding: 10px 10px;
        }
    }
}
</style>
