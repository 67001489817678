<template>
    <DatePicker
        mode='range'
        :is-expanded = "true"
        title-position="left"
        @dayclick="onDayClick"
        :min-date="new Date()"
        v-model="selectedDate"
        show-caps/>
</template>

<script>
import Calendar from 'v-calendar/lib/components/calendar.umd';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import {setupCalendar} from 'v-calendar';
import moment from 'moment-timezone';

setupCalendar({
    componentPrefix: 'vc',
});

export default {
    name: 'MyCalendar',
    props: {
        selectedDateInput: {
            default: null
        }
    },
    components: {
        Calendar,
        DatePicker,
    },
    data() {
        return {
            selectedDate: null,
        }
    },
    mounted() {
        this.selectedDate = this.selectedDateInput;
    },
    methods: {
        onDayClick() {
            this.selectedDate = moment(this.selectedDate).format('YYYY-MM-DD');
            this.$emit('selected_date', this.selectedDate);
        }
    },
}
</script>

<style>
.is-disabled {
    pointer-events: none;
}
</style>
