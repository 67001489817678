<template>
    <div class="modal" id="addFileModal" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Add New File</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                </div>
                <div class="modal-body">
                    <div class="mb-3">
                        <label for="fileNameInput1" class="form-label">File name</label>
                        <input type="text" :class="{ 'is-invalid': upload_file.file_name_valid }" class="form-control" v-model="upload_file.file_name" id="fileNameInput1" placeholder="File name">
                        <div class="invalid-feedback">{{ upload_file.file_name_valid }}</div>
                    </div>
                    <div class="mb-3">
                        <label for="fileInput2" class="form-label">File</label>
                        <input ref="fileupload" @change="uploadFileChange" type="file" :class="{ 'is-invalid': upload_file.file_valid }" class="form-control" id="fileInput2" placeholder="File">
                        <div class="invalid-feedback">{{ upload_file.file_valid }}</div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn_modal_t1" data-bs-dismiss="modal">Cancel</button>
                    <button class="btn_modal_t2" @click="uploadFile">Upload</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Modal} from "bootstrap";
import {errorMessage, successMessage} from "../../../services/messages";
import {mapMutations} from "vuex";
import helpFunctions from "../../../helpFunctions";

export default {
    name: "AddNewLogo",
    data() {
        return {
            addFileModal: null,
            upload_file: {},
        }
    },
    async mounted() {
        this.upload_file = helpFunctions.upload_file;
        this.addFileModal = new Modal(document.getElementById('addFileModal'));
    },
    destroyed() {
        if (this.addFileModal) {
            this.addFileModal = null;
        }
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
            'setLogo',
        ]),
        load() {
            this.addFileModal.show();
            this.clearUploadFile();
        },
        uploadFileChange(e) {
            const tmpFiles = e.target.files;
            if (tmpFiles.length === 0) {
                return false;
            }
            this.upload_file.file = tmpFiles[0];
        },
        async uploadFile() {
            if (!this.upload_file.file_name) {
                this.upload_file.file_name_valid = 'This field is required';
                return;
            }
            if (!this.upload_file.file) {
                this.upload_file.file_valid = 'This field is required';
                return;
            }
            try {
                this.showLoader();
                let data = new FormData();
                data.append('file', this.upload_file.file);
                data.append('file_name', this.upload_file.file_name);
                let response = await this.$http.postAuth(`${this.$http.apiUrl()}cms/upload-file`, data);
                if(response.data.success) {
                    successMessage("Logo uploaded successfully");
                    localStorage.setItem('logo', response.data.data.path_to_logo);
                    this.setLogo(response.data.data.path_to_logo);
                }
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
            this.clearUploadFile();
            this.addFileModal.hide();
        },
        clearUploadFile() {
            this.upload_file.file_name = '';
            this.upload_file.file_name_valid = false;
            this.upload_file.file = null;
            this.upload_file.file_valid = false;
            this.$refs.fileupload.value = null;
        },
    },
}
</script>

<style lang="scss" scoped>
.form-control.is-invalid, .form-control:invalid, .form-select:invalid, .form-select.is-invalid, .is-invalid_c {
    border-color: #dc3545 !important;
}
.invalid-feedback, .invalid-feedback_c {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: #dc3545;
}
.modal-title {
    width: 100%;
    text-align: center;
}
.modal-footer {
    border-top: none;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    gap: 35px;
}
.btn_modal_t1 {
    border: 0.5px solid #494949;
    box-sizing: border-box;
    border-radius: 100px;
    background: white;
    height: 47px;
    width: 148px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #494949;
}

@media (max-width: 992px) {
    .modal-footer {
        border-top: none;
        display: flex;
        justify-content: center;
        padding-bottom: 30px;
        gap: 15px;
    }
}
</style>
