<template xmlns="http://www.w3.org/1999/html">
    <div>
        <div class="sidebar_mobile" id="sidebar_mobile">
            <div class="main_part">
                <div class="sidebarLogo text-center">
                    <router-link class="navbar-brand logo" :to="{ name: 'Login' }" @click.native="closeSidebar">
                        <img class="mx-3 my-5" :src="this.getLogo" alt="strobeartLogo"/>
                    </router-link>
                </div>
                <div class="actionsList" v-if="this.isLogged">
                    <div class="sidebar-nav-link">
                        <router-link class="navbar-brand" exact-active-class="route-active" :to="{ name: 'Home' }" @click.native="closeSidebar">
                             <img class="logo_project" src="@/assets/icons/home.svg" alt="home"/>
                             <span class="nav-link-text">Home</span>
                        </router-link>
                    </div>
                    <div class="sidebar-nav-link">
                        <div id="jobs_actions" class="d-flex justify-content-between align-items-center jobs_actions"
                             @click="openMenu(($parent.user.type_user == 'business' ? '#jobsBusiness' : '#jobsEditor') + ', #jobs_actions')">
                            <div>
                                <img class="logo_project" src="@/assets/icons/jobs.svg" alt="jobs"/>
                                <span class="mt-3 nav-link-text">Jobs</span>
                            </div>
                            <span class="open_list">+</span>
                        </div>
                        <div id="jobsBusiness" class="jobs_box" v-if="$parent.user.type_user == 'business'">
                            <div class="d-flex align-items-center">
                                <span class="open_list ms-0">+</span>
                                <router-link class="navbar-brand nav-link-text" :to="{ name: 'ScheduleGrid'}" @click.native="closeSidebar">
                                    Manage posts
                                </router-link>
                            </div>
                            <div class="d-flex align-items-center mt-4">
                                <span class="open_list ms-0">+</span>
                                <router-link class="navbar-brand nav-link-text" :to="{ name: 'Jobs'}" @click.native="closeSidebar">
                                    Add Style Guide
                                </router-link>
                            </div>
                            <div class="d-flex align-items-center mt-4">
                                <span class="open_list ms-0">+</span>
                                <router-link class="navbar-brand nav-link-text" :to="{ name: 'AddNewJob'}" @click.native="closeSidebar">
                                    Add New Job
                                </router-link>
                            </div>
                            <div class="d-flex align-items-center mt-4">
                                <span class="open_list ms-0">+</span>
                                <router-link class="navbar-brand nav-link-text" :to="{ name: 'PastJobs'}" @click.native="closeSidebar">
                                    Past Jobs
                                </router-link>
                            </div>
                        </div>
                        <div id="jobsEditor" class="jobs_box" v-if="$parent.user.type_user == 'editor'">
                            <div class="d-flex align-items-center">
                                <span class="open_list ms-0">+</span>
                                <router-link class="navbar-brand nav-link-text" :to="{ name: 'Home', hash: '#active-jobs'}" @click.native="closeSidebar">
                                    Active Jobs
                                </router-link>
                            </div>
                            <div class="d-flex align-items-center mt-4">
                                <span class="open_list ms-0">+</span>
                                <router-link class="navbar-brand nav-link-text" :to="{ name: 'PastJobs'}" @click.native="closeSidebar">
                                    Past Jobs
                                </router-link>
                            </div>
                        </div>
                    </div>
<!--                    <div class="sidebar-nav-link" v-if="$parent.user.type_user == 'business'">-->
<!--                        <router-link class="navbar-brand logo" exact-active-class="route-active"-->
<!--                                     :to="{ name: 'Login' }" @click.native="closeSidebar">-->
<!--                            <img class="logo_project" src="@/assets/icons/find_editor.svg" alt="findEditor"/>-->
<!--                            <span class="nav-link-text">Find An Editor</span>-->
<!--                        </router-link>-->
<!--                    </div>-->
                    <div class="sidebar-nav-link">
                        <router-link class="navbar-brand" exact-active-class="route-active" :to="{ name: 'ChatsList' }" @click.native="closeSidebar">
                            <img class="logo_project" src="@/assets/icons/sidebar_mail.svg" alt="settings"/>
                            <span class="nav-link-text">Messages</span>
                        </router-link>
                    </div>
                    <div class="sidebar-nav-link">
                        <div class="navbar-brand" @click="openAccounts">
                            <img class="logo_project" src="@/assets/icons/bi_person-fill.svg"/>
                            <span class="nav-link-text">Accounts</span>
                        </div>
                    </div>
                    <div class="sidebar-nav-link">
                        <div id="info_pages_header" class="d-flex justify-content-between align-items-center jobs_actions" @click="openMenu('#info-pages, #info_pages_header')">
                            <div>
                                <img class="logo_project" src="@/assets/icons/info.svg" alt="jobs"/>
                                <span class="mt-3 nav-link-text">Info pages</span>
                            </div>
                            <span class="open_list">+</span>
                        </div>
                        <div id="info-pages" class="jobs_box">
                            <div class="d-flex align-items-center">
                                <span class="open_list ms-0">+</span>
                                <router-link class="navbar-brand nav-link-text" :to="{ name: 'HowItWorks'}" @click.native="closeSidebar">
                                    How It Works
                                </router-link>
                            </div>
                            <div class="d-flex align-items-center mt-4">
                                <span class="open_list ms-0">+</span>
                                <router-link class="navbar-brand nav-link-text" :to="{ name: 'Prices'}" @click.native="closeSidebar">
                                    Prices
                                </router-link>
                            </div>
                            <div class="d-flex align-items-center mt-4">
                                <span class="open_list ms-0">+</span>
                                <router-link class="navbar-brand nav-link-text" :to="{ name: 'About'}" @click.native="closeSidebar">
                                    About
                                </router-link>
                            </div>
                            <div class="d-flex align-items-center mt-4">
                                <span class="open_list ms-0">+</span>
                                <router-link class="navbar-brand nav-link-text" :to="{ name: 'PrivacyPolicy'}" @click.native="closeSidebar">
                                    Privacy Policy
                                </router-link>
                            </div>
                            <div class="d-flex align-items-center mt-4">
                                <span class="open_list ms-0">+</span>
                                <router-link class="navbar-brand nav-link-text" :to="{ name: 'TermsOfUse'}" @click.native="closeSidebar">
                                    Terms Of Use
                                </router-link>
                            </div>
                            <div class="d-flex align-items-center mt-4">
                                <span class="open_list ms-0">+</span>
                                <router-link class="navbar-brand nav-link-text" :to="{ name: 'ContactUs'}" @click.native="closeSidebar">
                                    Contact Us
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="sidebar-nav-link settings">
                        <router-link class="navbar-brand mt-5" exact-active-class="route-active" :to="{ name: 'Settings' }" @click.native="closeSidebar">
                            <span class="settings-text">Settings</span>
                        </router-link>
                    </div>
                </div>
                <div v-if="!this.isLogged">
                    <ul class="navbar-nav w-100 d-flex justify-content-between mb-2 mb-lg-0">
                        <li class="nav-item w-100">
                            <ul class="navbar-nav ms-auto text-center me-auto d-flex justify-content-around sidebar-mobile-option">
                                <li class="header-nav col">
                                    <span class="fw-bolder">HOW IT WORKS</span>
                                </li>
                            </ul>
                            <HeaderDropdown class="header-dropdown"/>
                        </li>
                    </ul>
                </div>
                <div class="bottom_actions d-flex align-items-center justify-content-around">
                    <span class="btn_logout" @click="logOut" v-if="this.isLogged"> Log Out</span>
                    <div class="d-flex justify-content-between social-buttons">
                        <a target="_blank" :href="this.getInstagramLink">
                            <img class="logo_project" src="@/assets/icons/Instagram.svg" alt="instagram"/>
                        </a>
                        <a target="_blank" :href="this.getFacebookLink">
                            <img class="logo_project" src="@/assets/icons/Facebook.svg" alt="facebook"/>
                        </a>
                        <a target="_blank" :href="this.getTiktokLink">
                            <img class="logo_project" src="@/assets/icons/TikTok.svg" alt="tiktok"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="close_part" id="close_part" @click="closeSidebar(); closeAccounts()"/>
        <div class="accounts_mobile" id="accounts_mobile">
            <div class="accounts_mobile__head">
                <img class="accounts_mobile__close-icon" src="@/assets/icons/close-cross.svg" @click="closeAccounts">
                <span class="accounts_mobile__head-text">Linked Accounts</span>
            </div>
            <div class="accounts_mobile__body">
                <div class="accounts_mobile__item with_underline" v-if="instagram_connect">
                    <div class="accounts_mobile-item__icon">
                        <img src="@/assets/icons/Instagram_icon.svg">
                    </div>
                    <div class="accounts_mobile-item__wrapper">
                        <div class="accounts_mobile-item__name">
                            {{instagram_connect_data.name}}
                        </div>
                        <div class="accounts_mobile-item__type">Instagram</div>
                        <div class="phone__block cp pb-1 mt-2" @click="unlinkInstagram">
                            <div class="text2">
                                <button class="btn-c3">Disconnect</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accounts_mobile__item with_underline" v-if="shopify_connect">
                    <div class="accounts_mobile-item__icon">
                        <img src="@/assets/icons/Shopify_icon.svg">
                    </div>
                    <div class="accounts_mobile-item__wrapper">
                        <div class="accounts_mobile-item__name">
                            {{shopify_connect_data.name}}
                        </div>
                        <div class="accounts_mobile-item__type">Shopify</div>
                        <div class="phone__block cp pb-1 mt-2" @click="unlinkShopify">
                            <div class="text2">
                                <button class="btn-c3">Disconnect</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accounts_mobile__item" v-if="amazon_connect">
                    <div class="accounts_mobile-item__icon">
                        <img src="@/assets/icons/Amazon_icon.png">
                    </div>
                    <div class="accounts_mobile-item__wrapper">
                        <div class="accounts_mobile-item__name">
                        </div>
                        <div class="accounts_mobile-item__type">Amazon</div>
                        <div class="phone__block cp pb-1 mt-2" @click="unlinkAmazon">
                            <div class="text2">
                                <button class="btn-c3">Disconnect</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import SentNewPassword from "../views/SetNewPassword";
import HeaderDropdown from "./HeaderDropdown";
import helpFunctions from "../helpFunctions";

export default {
    name: 'MobileSidebar',
    components: {SentNewPassword, HeaderDropdown},
    data() {
        return {
            instagram_connect_data: [],
            instagram_connect: false,
            shopify_connect_data: [],
            shopify_connect: false,
            amazon_connect: false,
        }
    },
    mounted() {
        if(this.isLogged) {
            this.connections();
        }
    },
    computed: {
        ...mapGetters([
            'isLogged',
            'getLogo',
            'getInstagramLink',
            'getFacebookLink',
            'getTiktokLink',
        ]),
    },
    methods: {
        ...mapMutations([
            'setUpdateUserProfile',
            'showLoader',
            'hideLoader',
        ]),
        closeSidebar() {
            document.getElementById('sidebar_mobile').classList.remove('active');
            document.getElementById('close_part').classList.remove('active');
            document.body.style.overflow = 'auto';
        },
        logOut() {
            delete localStorage.strobeart_user;
            delete localStorage.strobeart_jwt;
            delete localStorage.logo;
            delete localStorage.defaultColor;
            delete localStorage.defaultFont;
            delete localStorage.instagramLink;
            delete localStorage.facebookLink;
            delete localStorage.tiktokLink;
            this.closeSidebar();
            this.$router.push({to: '/login', name: "Login"}).then();
        },
        openMenu(selector) {
            document.querySelectorAll(selector).forEach(element => element.classList.toggle('active'));
            this.closeAccounts();
            // document.querySelector(selector + ' .jobs_actions').classList.toggle('active');
        },
        async openAccounts() {
            this.closeSidebar();
            this.showLoader();
            document.body.style.overflow = 'hidden';
            try {
                let resp = await this.$http.getAuth(`${this.$http.apiUrl()}user-connections`);
                if (resp.data?.data?.instagram?.data) {
                    this.instagram_connect_data = resp.data.data.instagram.data[0];
                }
                if (resp.data?.data?.shopify) {
                    this.shopify_connect_data = resp.data.data.shopify;
                }
                this.hideLoader();
            } catch (error) {
                console.log(error);
            }
            document.querySelector('#accounts_mobile').classList.add('active');
            document.getElementById('close_part').classList.add('active');
        },
        closeAccounts() {
            document.querySelector('#accounts_mobile').classList.remove('active');
            document.getElementById('close_part').classList.remove('active');
            document.body.style.overflow = 'auto';
        },
        async connections() {
            try {
                let resp = await this.$http.getAuth(`${this.$http.apiUrl()}profile/setting`);
                this.instagram_connect = helpFunctions.isEmptyObject(resp?.data?.data?.user?.login_instagram || {});
                this.shopify_connect = helpFunctions.isEmptyObject(resp?.data?.data?.user?.shopify_connect || {});
                this.amazon_connect = helpFunctions.isEmptyObject(resp?.data?.data?.user?.aws || {});
            } catch (error) {
                console.log(error);
            }
        },
        async unlinkInstagram() {
            if (window.confirm("Do you really want to disconnect instagram?")) {
                await this.$http.postAuth(`${this.$http.apiUrl()}profile/unlink-instagram`);
                this.instagram_connect = false;
            }
        },
        async unlinkShopify() {
            if (window.confirm("Do you really want to disconnect shopify?")) {
                await this.$http.postAuth(`${this.$http.apiUrl()}profile/unlink-shopify`);
                this.shopify_connect = false;
            }
        },
        async unlinkAmazon() {
            if (window.confirm("Do you really want to disconnect amazon?")) {
                await this.$http.postAuth(`${this.$http.apiUrl()}profile/unlink-amazon`);
                this.amazon_connect = false;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.btn-c3 {
    padding-left: 15px;
    padding-right: 15px;
}
.sidebar-mobile-option {
    width: 90%;
    border: solid 1px #D8D8D8;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
}
.header-nav a {
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #696969;
}
.header-nav.header-nav_active a {
    border-bottom: 3px solid #D3C2B1;
    padding-bottom: 1px;
}
.sidebar_mobile {
    position: absolute;
    top: 0px !important;
    left: 0px !important;
    width: 77%;
    height: 100%;
    z-index: 99999;
    transition: .5s;
    transform: translateX(-100%);

    .main_part {
        background: white;
        width: 100%;
        height: 100%;
        opacity: 1;
        border-top-right-radius: 40px;
        position: relative;
        z-index: 99999;
        border-bottom-right-radius: 50px;
        box-shadow: 0px 0px 14px rgba(189, 189, 189, 0.17);
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        .jobs_actions.active img, .route-active img {
            filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(320deg) brightness(80%) contrast(70%) drop-shadow(0px 0px 2px rgba(158, 97, 106, .3));
        }
        .jobs_actions.active span, .route-active span {
            color: #9E616A !important;
        }
        .jobs_box {
            max-height: 0;
            overflow: hidden;
            margin-top: 0px;
            transition: all 0.5s ease-out;
        }
        .jobs_box.active {
            max-height: 350px;
            margin-top: 20px;
            transition: all 0.5s ease-out;
        }
        .actionsList {
            margin-bottom: 80px;

            .sidebar-nav-link {
                margin: 0px 12px;
                padding: 20px 30px;
                border-bottom: 0.3px solid rgba(105, 105, 105, 0.32);
                border-width: thin;

                .nav-link-text {
                    margin-left: 20px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    color: #494949;
                }
                .open_list {
                    font-size: 25px;
                }
            }
            .settings {
                margin-left: 30px;
                opacity: 0.5;
                border: none !important;
                padding: 15px 10px !important;

                .settings-text {
                    color: #696969;
                    text-decoration: none;
                    font-size: 14px;
                }
            }
        }
        .logo_project {
            width: 24px;
            height: 24px;
        }
        .bottom_actions {
            position: fixed;
            bottom: 0;
            width: 100%;
            border-top: 0.3px solid rgba(105, 105, 105, 0.32);
            border-width: thin;
            background: white;
            border-bottom-right-radius: 50px;

            .btn_logout {
                padding: 25px 20px;
                border-right: 0.3px solid rgba(105, 105, 105, 0.32);
                border-width: thin;
                color: #696969;
                opacity: 0.5;
                font-size: 14px;
                text-align: center;
                flex-basis: 50%;
                text-decoration: none;
            }
            .social-buttons {
                height: 42px;
                flex-basis: 50%;
                padding: 0 20px;
            }
        }
    }
    .main_part::-webkit-scrollbar {
        display: none;
    }
}
.sidebar_mobile.active {
    transform: translateX(0%);
}
.close_part {
    position: absolute;
    width: 0%;
    height: 100%;
    background: #494949;
    opacity: 0;
    right: 0;
    top: 0;
    z-index: 9999;
    transition: .5s ease-out;
}
.close_part.active {
    width: 100%;
    opacity: 0.4;
    z-index: 9999;
}
.accounts_mobile {
    position: fixed;
    bottom: 0px !important;
    left: 0px !important;
    width: 100%;
    min-height: 40%;
    z-index: 99999;

    background: white;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    transition: .5s;
    transform: translateY(100%);

    &.active {
        transition: .5s;
        transform: translateY(0%);
    }
    &__head {
        padding: 15px;
        border-bottom: 0.3px solid rgba(105, 105, 105, 0.32);
        display: flex;
        justify-content: space-between;

        &-text {
            width: 100%;
            text-align: center;
            font-weight: 500;
        }
    }
    &__body {
        padding: 15px;
    }
    &__close-icon {
        max-width: 20px;
        max-height: 20px;
    }
    &__item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px;

        img {
            width: 100%;
        }
    }
    &-item {
        &__icon {
            width: 40px;
        }
        &__wrapper {
            width: 100%;
            margin-left: 10px;
        }
        &__name {
            font-size: 14px;
        }
        &__type {
            font-size: 11px;
        }
    }
}
.with_underline {
    border-bottom: 0.5px solid rgba(73, 73, 73, 0.45);
}
</style>
