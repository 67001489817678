<template>
    <div class="modal" id="userDetails" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div v-if="user" class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">User Details</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                </div>
                <div class="modal-body justify-content-center modal_user_details">
                    <img v-if="user.avatar && user.avatar.url" class="guest_avatar" :src="user.avatar.url"/>
                    <img v-else class="guest_avatar" src='@/assets/images/sbcf-default-avatar.png'/>
                    <div v-if="user.name" style="margin-top: 15px;">{{ user.name }}</div>
                    <div v-if="user.email" style="margin-top: 5px;">{{ user.email }}</div>
                    <div v-if="user.type_user" style="margin-top: 5px;">{{ user.type_user }}</div>
                    <div v-if="user.location" style="margin-top: 5px;">Location: {{ user.location }}</div>
                    <div v-if="user.english_level" style="margin-top: 5px;">English Level: {{ user.english_level }}</div>
                    <div v-if="user.phone" style="margin-top: 5px;">Phone number: {{ user.phone }}</div>
                    <div v-if="user.business_name" style="margin-top: 5px;">Business Name: {{ user.business_name }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "UserDetails",
    data() {
        return {
            user: null,
        }
    },
    methods: {
        async getUserDetails(id) {
            let response = await this.$http.get(`${this.$http.apiUrl()}cms/get-user/` + id);
            this.user = response.data.data;
        }
    }
}
</script>

<style lang="scss" scoped>
.modal_user_details {
    .guest_avatar {
        width: 75px;
        border-radius: 50%;
    }
}
</style>
