<template>
    <div>
        <div @mouseleave="changeShowDropdown(false)">
            <div class="header" :class="{'without-background': !backgroundHeader}" >
                <nav class="navbar navbar-expand-lg navbar-light ">
                    <div class="container-fluid">
                        <router-link v-if="this.isLogged" class="navbar-brand logo" :to="{ name: 'Login' }">
                            <img class="logo_project" :src="this.getLogo" alt="strobeart"/>
                        </router-link>
                        <router-link v-else class="navbar-brand logo" :to="{ name: 'About' }">
                            <img class="logo_project" :src="this.getLogo" alt="strobeart"/>
                        </router-link>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false"
                                aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"/>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
                            <ul v-if="!this.isLogged" class="navbar-nav w-100 d-flex justify-content-between mb-2 mb-lg-0">
                                <li class="nav-item w-100">
                                    <ul class="navbar-nav w-50 ms-auto me-auto d-flex justify-content-around">
                                        <li class="header-nav me-5" @mouseenter="changeShowDropdown(true)">
                                            <router-link :to="{ name: 'HowItWorks' }">HOW IT WORKS</router-link>
                                        </li>
                                        <li class="header-nav me-5">
                                            <router-link :to="{ name: 'Prices' }">PRICING</router-link>
                                        </li>
                                    </ul>
                                </li>
                                <li class="nav-item text-end">
                                    <router-link class="header-button" :to="{ name: 'Login' }">Sign In</router-link>
                                </li>
                            </ul>
                            <ul v-if="this.isLogged" class="navbar-nav w-100 d-flex justify-content-between mb-2 mb-lg-0">
                                <li class="nav-item w-100">
                                    <ul class="navbar-nav w-50 ms-auto me-auto d-flex justify-content-around header-top-menu" v-if="user">
                                        <li class="header-nav me-5" :style="$route.name==='Home' ? 'border-bottom: 3px solid ' + getDefaultColor : ''">
                                            <router-link :to="{ name: 'Home' }" >
                                                Home
                                            </router-link>
                                        </li>
                                        <li class="header-nav me-5" :style="$route.name==='Jobs' ? 'border-bottom: 3px solid ' + getDefaultColor : ''">
                                            <router-link :to="{ name: 'Jobs' }" >
                                                Jobs
                                            </router-link>
                                        </li>
                                        <li v-if="user.type_user === 'business'" class="header-nav me-5" :style="$route.name==='ScheduleGrid' ? 'border-bottom: 3px solid ' + getDefaultColor : ''">
                                            <router-link :to="{ name: 'ScheduleGrid' }" >
                                                Manage posts
                                            </router-link>
                                        </li>
                                    </ul>
                                </li>
                                <li class="nav-item pr-22" v-if="user">
                                    <router-link class="nav-link pt-3" :to="{ name: 'ChatsList' }">
                                        <svg width="29" height="22" viewBox="0 0 29 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="2" y="1" width="25.8667" height="20" rx="4" stroke="#494949" stroke-width="2"/>
                                            <path d="M1.3667 4.7666L14.5667 12.4666L28.5 4.7666" stroke="#494949" stroke-width="2"/>
                                        </svg>
                                        <div class="header-message">
                                            <ChatsMessageCount v-if="isLogged" :need_red="false"/>
                                        </div>
                                    </router-link>
                                </li>
                                <li class="nav-item" v-click-outside="closeDropdown">
                                    <img v-if="avatar" class="avatar" :src=avatar alt="" @click="openDropdown()">
                                    <img v-else class="avatar" src='@/assets/images/sbcf-default-avatar.png' alt="" @click="openDropdown()">
                                    <div class="user-nav-dropdown" hidden>
                                        <router-link class="nav-link" :to="{ name: 'Profile' }">
                                            <div class="mb-4">
                                                <img class="mr-11" src="@/assets/icons/user.svg" alt="icon-user.svg"/>
                                                Profile
                                            </div>
                                        </router-link>
                                        <router-link class="nav-link" :to="{ name: 'Settings' }">
                                            <div class="mb-4">
                                                <img class="mr-11" src="@/assets/icons/user-settings.svg" alt="icon-user-settings.svg"/>
                                                Settings
                                            </div>
                                        </router-link>
                                        <router-link v-if="user.type_user === 'business'" class="nav-link" :to="{ name: 'BrandImage' }">
                                            <div class="mb-4">
                                                <img class="mr-11" src="@/assets/icons/chat-gpt.svg" alt="chat-gpt.svg"/>
                                                Brand Image
                                            </div>
                                        </router-link>
                                        <div class="btn_logout desc_st" @click="logOut()">Log out</div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
            <HeaderDropdown v-if="showDropdown" class="header-dropdown"/>
        </div>
        <div v-if="this.isLogged && isTypeUser" class="header_mobile">
            <div class=" align-self-center">
                <img src="@/assets/icons/sidebar_menu.svg" @click="openSidebar">
                <MobileSidebar/>
            </div>
            <div class="">
                <router-link class="navbar-brand logo" :to="{ name: 'Login' }" @click.native="closeSidebar">
                    <img class="header_mobile_logo" src="@/assets/icons/Logo_circle.png" alt="strobeartLogo"/>
                </router-link>
            </div>
            <div class="">
                <router-link :to="{name: 'Settings'}">
                    <img v-if="avatar" class="avatar" :src=avatar alt="">
                    <img v-else class="avatar" src='@/assets/images/sbcf-default-avatar.png' alt="">
                </router-link>
            </div>
        </div>
        <div v-if="!this.isLogged" class="header_mobile_logout">
            <div class=" align-self-center">
                <img src="@/assets/icons/sidebar_menu.svg" @click="openSidebar">
                <MobileSidebar :isLogged="this.isLogged"/>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import Vue from 'vue';
import MobileSidebar from './MobileSidebar';
import HeaderDropdown from './HeaderDropdown';
import ChatsMessageCount from "../components/ChatsMessageCount";
import ('../css/custom.css');

Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            if (!(el == event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    },
});

export default {
    name: "Header",
    components: { MobileSidebar, ChatsMessageCount, HeaderDropdown },
    props: {
        'backgroundHeader': {
            default: true
        }
    },
    data() {
        return {
            user: {},
            showDropdown: false,
        }
    },
    mounted() {
        this.user = JSON.parse(localStorage.getItem('strobeart_user'));
        if(!this.getStyles) {
            this.loadSiteSettings();
        }
    },
    computed: {
        ...mapGetters([
            'isLogged',
            'getUser',
            'isUpdatedUserProfile',
            'getStyles',
            'getLogo',
            'getDefaultColor',
            'getDefaultFont',
            'getInstagramLink',
            'getFacebookLink',
            'getTiktokLink',
        ]),
        avatar() {
            return this.user?.avatar?.url || null;
        },
        isTypeUser() {
            return this.user?.type_user || null;
        }
    },
    methods: {
        ...mapMutations([
            'setUpdateUserProfile',
            'setLogo',
            'setDefaultColor',
            'setDefaultFont',
            'setInstagramLink',
            'setFacebookLink',
            'setTiktokLink',
            'setStyles',
        ]),
        logOut() {
            delete localStorage.strobeart_user;
            delete localStorage.strobeart_jwt;
            delete localStorage.logo;
            delete localStorage.defaultColor;
            delete localStorage.defaultFont;
            delete localStorage.instagramLink;
            delete localStorage.facebookLink;
            delete localStorage.tiktokLink;
            this.$router.push({to: '/login', name: "Login"}).then();
        },
        openDropdown() {
            document.querySelector('.user-nav-dropdown').toggleAttribute('hidden');
        },
        closeDropdown() {
            document.querySelector('.user-nav-dropdown').setAttribute('hidden','hidden');
        },
        openSidebar() {
            document.querySelector('#accounts_mobile').classList.remove('active');
            document.body.style.overflow = 'hidden';
            document.getElementById('sidebar_mobile').classList.toggle('active');
            document.getElementById('close_part').classList.toggle('active');
        },
        changeShowDropdown(val) {
            this.showDropdown = val;
        },
        async loadSiteSettings() {
            if(!localStorage.getItem('logo')) {
                const response = await this.$http.get(`${this.$http.apiUrl()}get-site-settings`);
                if (response.data.data) {
                    localStorage.setItem('logo', response.data.data.style.path_to_logo);
                    localStorage.setItem('defaultColor', response.data.data.style.main_color);
                    localStorage.setItem('defaultFont', response.data.data.style.font_family);
                    localStorage.setItem('instagramLink', response.data.data.links.instagram);
                    localStorage.setItem('facebookLink', response.data.data.links.facebook);
                    localStorage.setItem('tiktokLink', response.data.data.links.tiktok);
                    this.setLogo(response.data.data.style.path_to_logo);
                    this.setDefaultColor(response.data.data.style.main_color);
                    this.setDefaultFont(response.data.data.style.font_family);
                    this.setInstagramLink(response.data.data.links.instagram);
                    this.setFacebookLink(response.data.data.links.facebook);
                    this.setTiktokLink(response.data.data.links.tiktok);
                    this.setStyles(true);
                }
            }
            else {
                this.setLogo(localStorage.getItem('logo'));
                this.setDefaultColor(localStorage.getItem('defaultColor'));
                this.setDefaultFont(localStorage.getItem('defaultFont'));
                this.setInstagramLink(localStorage.getItem('instagramLink'));
                this.setFacebookLink(localStorage.getItem('facebookLink'));
                this.setTiktokLink(localStorage.getItem('tiktokLink'));
                this.setStyles(true);
            }
        }
    },
    watch: {
        isUpdatedUserProfile() {
            this.user = JSON.parse(localStorage.getItem('strobeart_user'));
            this.setUpdateUserProfile(false);
        }
    }
}
</script>

<style lang="scss" scoped>
.navbar-brand {
    padding: 0;
}
.navbar {
    padding: 0;
}
.header_mobile_logout {
    display: none;
}
.header {
    padding: 18px 96px;
    position: fixed;
    width: 100%;
    z-index: 999;
    background: white;

    @media only screen and (max-width: 1108px) {
        padding: 18px 20px;
    }
}
.header-top-menu {
    @media only screen and (max-width: 1615px) {
        width: 80% !important;
    }
    @media only screen and (max-width: 1240px) {
        width: 100% !important;
    }
}
.header.without-background {
    background: none;
}
.user-nav-dropdown {
    position: fixed;
    z-index: 999;
    padding: 31px;
    border-radius: 10px;
    border: 0.3px solid #cfd2d7;
    background: white;
    right: 0;
    transform: translate(-15%, 7%);
    box-shadow: 2px 7px 15px -11px rgba(0,0,0,0.79);
}
.btn_logout {
    cursor: pointer;
    padding: 11px 38px;
    background: #FCF5EF;
    border: 0.3px solid #a2a4a8;
    border-radius: 50px;
    color: #494949;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}
@media only screen and (max-width: 992px) {
    .header {
        padding: 15px 15px;
    }
}
.navbar-brand.logo {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 44px;
    color: black;
}
.navbar-expand-lg .navbar-nav .nav-link {
    padding: 0;
}
.navbar-light .navbar-nav .nav-link {
    color: #494949;
}
.nav-item .nav-link {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #494949;
}
.navbar-nav {
    display: flex;
    align-items: center;
}
.avatar {
    border-radius: 50%;
    width: 45px;
    height: 45px;
    object-fit: cover;
}
.navbar-nav .nav-item .nav-link.disable_link:hover {
    color:#494949;
}
.navbar-nav .nav-item .nav-link.disable_link, .text_name {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #494949;
}
.header_mobile {
    display: none;
}
.logo_project {
    width: 160px;
}
@media only screen and (max-width: 992px) {
    .header {
        display: none;
    }
    .header_mobile_logout {
        display: flex;
        padding: 15px 10px 15px 15px;
        justify-content: space-between;
        align-items: center;

        .navbar-brand {
            margin-right: 0;
        }
        &_logo {
            height: 50px;
            width: 50px;
            margin-left: 27px;
        }
    }
    .header_mobile {
        display: flex;
        padding: 50px 20px 20px 20px;
        justify-content: space-between;
        align-items: center;

        .navbar-brand {
            margin-right: 0;
        }
        &_logo {
            height: 50px;
            width: 50px;
            margin-left: 27px;
        }
    }
}
.header-nav a {
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #696969;
}
.header-message {
    position: relative;
    top: -30px;
    left: 20px;

    width: 18px;
    height: 18px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: white;
    background: #9E616A;
}
.header-button {
    width: 145px;
    padding: 14px 42px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: white;
    background: linear-gradient(180deg, #E27582 0%, #FEC0C8 99.5%), #FCF5EF;
    border: 0.5px solid white;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    text-decoration: none;
}
.header-dropdown {
    position: fixed;
    top: 92px;
    z-index: 9;
}
</style>
