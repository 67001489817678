<template>
    <div class="modal" id="jobDetails" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div v-if="job" class="modal-content">
                <div class="modal-header">
                    <h5 v-if="job.name" class="modal-title">Name: {{job.name}}</h5>
                    <h5 v-else class="modal-title">No name</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                </div>
                <div class="modal-body justify-content-center modal_job_details">
                    <div class="img_block">
                        <img class="preview_image" v-for="src in job.img_src" :src="src"/>
                    </div>
                    <div v-if="job.editing_level" style="margin-top: 5px;">Editing Level: {{job.editing_level}}</div>
                    <div v-if="job.style_guide" style="margin-top: 5px;">Style: {{job.style_guide}}</div>
                    <div v-if="job.color_profile" style="margin-top: 5px;">Color Profile: {{job.color_profile}}</div>
                    <div v-if="job.other" style="margin-top: 5px;">Other: {{job.other}}</div>
                    <div v-if="job.add_logo" style="margin-top: 5px;">Add Logo: {{job.add_logo}}</div>
                    <div v-if="job.add_watermark" style="margin-top: 5px;">Add Watermark: {{job.add_watermark}}</div>
                    <div v-if="job.white_background" style="margin-top: 5px;">White Background: {{job.white_background}}</div>
                    <div v-if="job.red_eye" style="margin-top: 5px;">Red Eye: {{job.red_eye}}</div>
                    <div v-if="job.status" style="margin-top: 5px;">Status: {{job.status}}</div>
                    <div v-if="job.color_palette" style="margin-top: 5px;">Color Palette: {{job.color_palette}}</div>
                    <div v-if="job.typography" style="margin-top: 5px;">Typography: {{job.typography}}</div>
                    <div v-if="job.file_video_instruction_id" style="margin-top: 5px;">File Video Instruction: {{job.file_video_instruction_id}}</div>
                    <div v-if="job.timezone" style="margin-top: 5px;">Timezone: {{job.timezone}}</div>
                    <div v-if="job.description" style="margin-top: 5px;">Description: {{job.description}}</div>
                    <div v-if="job.invisible_mannequin" style="margin-top: 5px;">Invisible Mannequin: {{job.invisible_mannequin}}</div>
                    <div v-if="job.color_matching" style="margin-top: 5px;">Color Matching: {{job.color_matching}}</div>
                    <div v-if="job.color_grading" style="margin-top: 5px;">Color Grading: {{job.color_grading}}</div>
                    <div v-if="job.sizes" style="margin-top: 5px;">Sizes: {{job.sizes}}</div>
                    <div v-if="job.is_subscribed" style="margin-top: 5px;">Is Subscribed: {{job.is_subscribed}}</div>
                    <div v-if="job.resolution" style="margin-top: 5px;">Resolution: {{job.resolution}}</div>
                    <div v-if="job.resolution_units" style="margin-top: 5px;">Resolution Units: {{job.resolution_units}}</div>
                    <div v-if="job.due_date" style="margin-top: 5px;">Due date: {{ getFormatedDate(job.due_date) }}</div>
                    <div v-if="job.created_at" style="margin-top: 5px;">Creation date: {{ getFormatedDate(job.created_at) }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "JobDetailsModal",
    data() {
        return {
            job: null,
        }
    },
    methods: {
        async getJobDetails(id) {
            let response = await this.$http.get(`${this.$http.apiUrl()}cms/get-job/` + id);
            this.job = response.data.data;
        },
        getFormatedDate(date) {
            return moment(date).format('YYYY-MM-DD HH:mm:ss');
        },
    }
}
</script>

<style lang="scss" scoped>
.modal_job_details {
    .preview_image {
        margin-left: 5px;
        height: 90px;
    }
    .img_block {
        margin-bottom: 15px;
    }
}
</style>
