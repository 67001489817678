<template>
    <button class="btn_generate_alt" @click="generateAltText">Generate</button>
</template>

<script>
import {errorMessage} from "../services/messages";
import {mapMutations} from "vuex";

export default {
    name: "AzureAiImageDescription",
    props: {
        image: String,
        image_id: null
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
        ]),
        async generateAltText() {
            try {
                this.showLoader();
                const response = await this.$http.postAuth(`${this.$http.apiUrl()}generate-alt-text`, {
                    imageUrl: this.image
                });
                if (!response?.data?.data?.result?.error) {
                    let text = this.capitalizeLetters(response?.data?.data?.result?.description?.captions[0]?.text || '');
                    this.$emit('updateAltText', [text, this.image_id]);
                    text = text.replaceAll(',', ' ').replaceAll('.', ' ');
                    text = text.replaceAll('   ', ' ');
                    text = text.replaceAll('  ', ' ');
                    text = this.capitalizeLetters(text);
                    text = text.replaceAll(' ', '-') + (response?.data?.data?.result?.metadata ? '-' + response?.data?.data?.result?.metadata?.height + 'x' + response?.data?.data?.result?.metadata?.width : '');
                    this.$emit('updatePictureName', [text, this.image_id]);
                } else {
                    errorMessage(response?.data?.data?.result?.error.message);
                }
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.load_page = true;
            this.hideLoader();
        },
        capitalizeLetters(text) {
            if(text) {
                let words = text.split(" ");
                for (let i = 0; i < words.length; i++) {
                    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
                }
                return words.join(" ");
            }
            else {
                return text;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.btn_generate_alt {
    border: 0.5px solid #494949;
    box-sizing: border-box;
    border-radius: 100px;
    cursor: pointer;
    padding: 4px 10px;
    white-space: nowrap;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: white;
    width: 200px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 992px) {
    .btn_generate_alt {
        margin-left: 0;
        margin-top: 20px;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        width: 150px;
        height: 45px;
    }
}
</style>
