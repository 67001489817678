<template>
    <div class="modal modalPostDeleteConfirm" id="modalPostDeleteConfirm" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-body box_md_t">
                    <div class="row">
                        <div class="col-12">
                            <h4 class="text-center">Are you sure you want to delete this post?</h4>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12 d-flex flex-row justify-content-between">
                            <div class="modal-btn btn-cancel" data-bs-dismiss="modal" aria-label="Close">Cancel</div>
                            <div class="modal-btn" @click="deletePost">Confirm</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {errorMessage} from "../../services/messages";
import {Modal} from "bootstrap";

export default {
    name: "DeletePost",
    props: {
        id: null,
    },
    data() {
        return {
            modalPostDeleteConfirm: null,
        }
    },
    async mounted() {
        this.modalPostDeleteConfirm = new Modal(document.getElementById('modalPostDeleteConfirm'));
    },
    destroyed() {
        if (this.modalPostDeleteConfirm) {
            this.modalPostDeleteConfirm = null;
        }
    },
    methods: {
        load() {
            this.modalPostDeleteConfirm.show();
        },
        async deletePost() {
            try {
                await this.$http.postAuth(`${this.$http.apiUrl()}schedule/` + this.id, { _method: 'Delete' });
                this.$emit('postDeleted');
                this.modalPostDeleteConfirm.hide();
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-dialog {
    max-width: 900px !important;
    width: 900px !important;
}
.modal-body {
    padding: 0;
}
.modal-content {
    background-color: white!important;
    border-radius: 12px;
    border: none;
}
.modal-btn {
    width: 142px;
    height: 48px;

    display: flex;
    justify-content: center;
    align-items: center;

    background: white;
    border: 0.5px solid #494949;
    border-radius: 100px;

    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #494949;

    cursor: pointer;

    &.btn-save {
        background: #FCF5EF;
    }
    &:hover {
        border-width: 2px;
    }
}

@media only screen and (max-width: 992px) {
    .modal-dialog {
        width: 100% !important;
        margin-left: 0;
        margin-right: 0;
    }
    .modal-content {
        background-color: white!important;
        margin: 16px 16px 70px;
    }
}
.modalPostDeleteConfirm {
    .modal-content {
        background-color: white!important;
        width: 380px !important;
        padding: 15px;
        margin-left: auto;
        margin-right: auto;

        border: 1px solid #494949;
    }
}
</style>
