<template>
    <div class="modal" id="recreateJobModal" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content" v-if="jobForRecreating">
                <div class="modal-header">
                    <h5 class="modal-title">
                        Are you sure you want to recreate job #{{jobForRecreating.id}}
                        <span v-if="jobForRecreating.name"> "{{jobForRecreating.name}}"</span>?
                    </h5>
                </div>
                <div class="modal-body">
                    <DatePicker
                        :locale="{id: 'en', masks: {weekdays: 'WW'}}"
                        mode='range'
                        v-model='recreate_date_full'
                        :is-expanded = "true"
                        title-position="left"
                        @dayclick="onDayClick"
                        :min-date="(new Date()).setDate((new Date()).getDate()+1)"
                        :max-date="(new Date()).setDate((new Date()).getDate()+6)"
                        show-caps/>
                </div>
                <div class="modal-footer">
                    <button class="btn_modal_t1" data-bs-dismiss="modal">Cancel</button>
                    <button class="btn_modal_t2" :class="{disabled: !recreate_date}" @click="recreateJob" :disabled="!recreate_date">Yes</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Modal} from "bootstrap";
import {errorMessage} from "../../services/messages";
import {mapMutations} from "vuex";
import moment from "moment-timezone";
import DatePicker from "v-calendar/lib/components/date-picker.umd";

export default {
    name: "RecreateJobModal",
    components: {
        DatePicker,
    },
    data() {
        return {
            recreateJobModal: null,
            jobForRecreating: null,
            recreate_date_full: null,
            recreate_date: null,
        }
    },
    async mounted() {
        this.recreateJobModal = new Modal(document.getElementById('recreateJobModal'));
        this.recreate_date_full = moment().add(1, 'days').format('YYYY-MM-DD');
        this.recreate_date = moment().add(1, 'days').format('YYYY-MM-DD');
    },
    destroyed() {
        if (this.recreateJobModal) {
            this.recreateJobModal = null;
        }
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
        ]),
        load(job) {
            this.jobForRecreating = job;
            this.recreateJobModal.show();
        },
        async recreateJob() {
            try {
                this.showLoader();
                let d = new Date();
                let date = `${this.recreate_date}T${d.getUTCHours() < 10 ? '0' : ''}${d.getUTCHours()}:${d.getUTCMinutes() < 10 ? '0' : ''}${d.getUTCMinutes()}:00.000Z`;
                await this.$http.postAuth(`${this.$http.apiUrl()}recreate-job/${this.jobForRecreating.id}`, {
                    'recreate_date': date,
                    'timezone': moment.tz.guess(),
                });
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
            this.$emit('getJobs');
            this.recreateJobModal.hide();
        },
        onDayClick(day) {
            if (this.recreate_date == day.id) {
                this.recreate_date = null;
            } else {
                this.recreate_date = day.id;
            }
        },
    },
}
</script>

<style>
.is-disabled {
    pointer-events: none;
}
</style>
<style lang="scss" scoped>
.modal-title {
    width: 100%;
    text-align: center;
}
.modal-footer {
    border-top: none;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    gap: 35px;
}
.btn_modal_t1 {
    border: 0.5px solid #494949;
    box-sizing: border-box;
    border-radius: 100px;
    background: white;
    height: 47px;
    width: 148px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #494949;
}
@media (max-width: 992px) {
    .modal-footer {
        border-top: none;
        display: flex;
        justify-content: center;
        padding-bottom: 30px;
        gap: 15px;
    }
}
</style>
