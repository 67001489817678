<template>
    <div class="modal" id="amazonModalPostPreview" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-body box_md_t" v-if="publish_model.platform === 'amazon'">
                    <div class="row">
                        <div class="col-12 col-lg-6 left-part">
                            <div class="slide-image-container">
                                <carousel :scrollPerPage="false" :perPage="1" :centerMode="true" :navigationEnabled="false" :paginationEnabled="true">
                                    <slide v-for="image in publish_model.image_job.finished_worked_images">
                                        <img class="postPreview__image" :src="image.image_url">
                                    </slide>
                                </carousel>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 right-part with_left-border">
                            <div class="row w-100">
                                <div class="col-12 d-flex flex-column justify-content-between">
                                    <div class="row">
                                        <div class="col-4 offset-8 text-end">
                                            <img class="postPreview__delete_icon cp" src="@/assets/icons/trash.png" @click="deletePost">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="mt-3">
                                            <div class="col-12">
                                                <label class="mt-3 postPreview__hashtags-label">Method</label>
                                                <div class="postPreview__hashtags-block with_underline">
                                                    <div class="form-check me-3">
                                                        <input class="form-check-input" v-model="publish_model.is_replaced" :value="false" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                                        <label class="form-check-label" for="flexRadioDefault1">Add</label>
                                                    </div>
                                                    <div class="form-check">
                                                        <input class="form-check-input" v-model="publish_model.is_replaced" :value="true" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
                                                        <label class="form-check-label" for="flexRadioDefault2">Replace</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-12">
                                            <label class="mt-3 postPreview__date-time-label">Product</label>
                                            <div class="postPreview__date-time-block with_underline">
                                                <div v-if="publish_model.product_id">
                                                    <div class="cp">{{publish_model.product_name || 'Problem with name'}}</div>
                                                </div>
                                                <div v-else>
                                                    <div class="cp">No selected products</div>
                                                </div>
                                            </div>
                                            <div class="mt-2">
                                                <div class="box_search_i w-100">
                                                    <input @keyup="keypressSearchAmazonProduct" type="text" class="search_prod_inp w-100" placeholder="name, id, sku">
                                                    <img class="search_icon" src="@/assets/icons/search.svg" alt="">
                                                </div>
                                                <div v-for="product in amazon_products" class="row mb-2">
                                                    <div class="col-9">{{product.name}} (sku:{{product.sku}})</div>
                                                    <div class="col-3">
                                                        <button class="facebook-btn" :class="{active: publish_model.product_id === product.sku}" @click="selectAmazonProduct(product)">
                                                            Select
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-12">
                                            <label class="mt-3 postPreview__date-time-label">Scheduled Time</label>
                                            <div class="postPreview__date-time-block with_underline">
                                                <img src="@/assets/icons/timer-icon.svg">
                                                <span class="postPreview__date-time cp" @click="show_calendar = !show_calendar">
                                                    {{publish_model.available_at}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="show_calendar" class="row mt-3">
                                        <div class="col-12">
                                            <SelectTime :available_at="publish_model.available_at" @timeChanged="timeChanged"/>
                                            <Calendar :selectedDateInput="selected_date" @selected_date="selectedDate"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row w-100 mt-3">
                                <div class="col-12 d-flex justify-content-between">
                                    <div class="modal-btn btn-cancel" data-bs-dismiss="modal" aria-label="Close">Back</div>
                                    <div class="modal-btn btn-save" @click="save">Save</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {errorMessage} from "../../services/messages";
import {mapMutations} from "vuex";
import moment from 'moment';
import {Modal} from "bootstrap";
import { Carousel, Slide } from 'vue-carousel';
import Calendar from "@/components/Calendar";
import helpFunctions from "../../helpFunctions";
import SelectTime from "../SelectTime";
import ('@/css/carousel.css');

export default {
    name: "AmazonPost",
    props: ['user_id'],
    components: {
        SelectTime,
        Carousel,
        Slide,
        Calendar,
    },
    data() {
        return {
            show_calendar: false,

            am_pm: 'AM',
            hour: '0',
            minute: '00',
            selected_date: null,

            modalPostPreview: null,
            amazon_products: [],

            publish_model: {}
        }
    },
    async mounted() {
        this.modalPostPreview = new Modal(document.getElementById('amazonModalPostPreview'));
    },
    destroyed() {
        if (this.modalPostPreview) {
            this.modalPostPreview = null;
        }
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
            'setUser',
        ]),
        async load() {
            this.publish_model.available_at = moment.utc(this.publish_model.available_at).local().format('YYYY-MM-DD HH:mm:ss');
            this.am_pm = moment(this.publish_model.available_at).format('A');
            this.hour = moment(this.publish_model.available_at).format('h');
            this.minute = moment(this.publish_model.available_at).format('mm');
            this.selected_date = moment(this.publish_model.available_at).format('YYYY-MM-DD');

            let result = await this.$http.getAuth(`${this.$http.apiUrl()}seller-partner/products/search?search=${this.publish_model.product_id}`);
            this.publish_model.product_name = result?.data?.data?.data[this.publish_model.product_id]?.name;
            this.amazon_products = [{
                'name': this.publish_model.product_name,
                'sku': this.publish_model.product_id,
            }];
            this.modalPostPreview.show();
        },
        selectedDate(data) {
            this.selected_date = data;
            this.publish_model.available_at = helpFunctions.getFormattedDateTime(this.selected_date, this.hour, this.minute, this.am_pm);
        },
        timeChanged(new_time) {
            this.hour = new_time[0];
            this.minute = new_time[1];
            this.am_pm = new_time[2];
            this.publish_model.available_at = helpFunctions.getFormattedDateTime(this.selected_date, this.hour, this.minute, this.am_pm);
        },
        deletePost() {
            this.$emit("deletePost");
        },
        postDeleted() {
            this.modalPostPreview.hide();
        },
        async save() {
            this.showLoader();
            this.publish_model.date_publication = helpFunctions.getFormattedDateTime(this.selected_date, this.hour, this.minute, this.am_pm);
            this.publish_model.timezone = moment.tz.guess(true);
            this.publish_model._method = 'Put';
            if(this.user_id) {
                this.publish_model.user_id = this.user_id;
            }
            try {
                await this.$http.postAuth(`${this.$http.apiUrl()}schedule/` + this.publish_model.schedule_id, this.publish_model);
                this.$emit("getCalendar");
                this.modalPostPreview.hide();
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }

            this.hideLoader();
        },
        async keypressSearchAmazonProduct(event) {
            let search_text = event.target.value;
            if (search_text && search_text.length > 2) {
                this.showLoader();
                try {
                    let result = await this.$http.getAuth(`${this.$http.apiUrl()}seller-partner/products/search?search=${this.publish_model.product_id}`, { search: search_text });
                    this.amazon_products = result?.data?.data?.data;
                } catch (e) {
                    const message = e?.response?.data?.error?.message || 'ERROR';
                    errorMessage(message);
                }
                this.hideLoader();
            } else {
                this.amazon_products = [];
            }
        },
        selectAmazonProduct(product) {
            if (this.publish_model.product_id === product.sku) {
                this.publish_model.product_id = null;
                this.publish_model.product_name = null;
            } else {
                this.publish_model.product_id = product.sku;
                this.publish_model.product_name = product.name;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.search_prod_inp {
    background: white;
    border: 0.3px solid #494949;
    box-sizing: border-box;
    border-radius: 100px;
    height: 41px;
    width: 100%;
    padding-left: 45px;
}
.search_icon {
    position: relative;
    left: 15px;
    top: -35px;
    cursor: pointer;
}
.slide-image {
    &-container {
        max-width: 350px;
        //display: inline-table;
        //max-height: 350px;
        //margin-bottom: 180px;
    }
}
.modal-dialog {
    max-width: 900px !important;
    width: 900px !important;
}
.postPreview {
    &__delete_icon {
        max-width: 18px;
        max-height: 18px;
    }
    &__description {
        width: 100%;
        border: none;
        font-size: 20px;
        line-height: 44px;
        font-weight: 600;
        color: #494949;

        &-label {
            font-size: 12px;
        }
    }
    &__hashtags {
        width: 100%;
        border: none;
        font-size: 16px;
        line-height: 30px;

        &-icon {
            width: 18px;
            height: 18px;
            margin-right: 12px;
        }
        &-block {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: -5px;
        }
        &-label {
            font-size: 12px;
        }
    }
    &__date-time {
        margin-left: 10px;
        font-size: 14px;
        line-height: 30px;

        &-block {
        }
        &-label {
            font-size: 12px;
        }
    }
    &__picture-name {
        &-icon {
            width: 18px;
            height: 18px;
            margin-right: 12px;
        }
        &-field {
            border: none;
            width: 100%;
        }
        &-block {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px;
        }
        &-label {
            font-size: 12px;
        }
    }
    &__alt-text {
        &-icon {
            width: 18px;
            height: 18px;
            margin-right: 12px;
        }
        &-field {
            border: none;
            width: 100%;
        }
        &-block {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px;
        }
        &-label {
            font-size: 12px;
        }
    }
}
.with_underline {
    border-bottom: 0.5px solid rgba(73, 73, 73, 0.25);
}
.with_left-border {
    border-left: 0.5px solid rgba(73, 73, 73, 0.25);
}
.modal-body {
    padding: 0;
}
.modal-content {
    background-color: white!important;
    border-radius: 12px;
    border: none;
}
.left-part {
    padding: 52px 32px 52px 52px;
    display: flex;
    justify-content: center;
}
.right-part {
    padding: 52px 52px 52px 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.modal-btn {
    width: 142px;
    height: 48px;

    display: flex;
    justify-content: center;
    align-items: center;

    background: white;
    border: 0.5px solid #494949;
    border-radius: 100px;

    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #494949;

    cursor: pointer;

    &.btn-save {
        background: #FCF5EF;
    }
    &:hover {
        border-width: 2px;
    }
}

@media only screen and (max-width: 992px) {
    .modal-dialog {
        width: 100% !important;
        margin-left: 0;
        margin-right: 0;
    }
    .modal-content {
        background-color: white!important;
        margin: 16px 16px 70px;
    }
    .left-part {
        padding: 25px 25px 10px 25px !important;
    }
    .right-part {
        padding: 10px 25px 25px 25px !important;
    }
    .slide-image {
        &-container {
            max-width: 250px;
            //max-height: 250px;
        }
    }
}
</style>
