<template>
    <div>
        <div class="footer">
            <div class="item">
                <div>
                    <div class="email_list">JOIN OUR EMAIL LIST</div>
                    <div class="form_join_email_list">
                        <input type="email" v-model="email_user" class="input_s form-control" placeholder="your email">
                        <button class="btn-c3" @click="signUp">Subscribe</button>
                    </div>
                </div>
                <div class="d-flex flex-row justify-content-around">
                    <ul class="list_link">
                        <li>
                            <router-link :to="{ name: 'Home' }">Home</router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'HowItWorks' }">How it Works</router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'Prices' }">Prices</router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'About' }">About</router-link>
                        </li>
                    </ul>
                    <ul class="list_link">
                        <li>
                            <router-link :to="{ name: 'PrivacyPolicy' }">Privacy Policy</router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'TermsOfUse' }">Terms of Use</router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'ContactUs' }">Contact Us</router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'FAQ' }">FAQ</router-link>
                        </li>
                    </ul>
                </div>
                <div>
                    <div class="icons_social">
                        <a target="_blank" :href="this.getInstagramLink">
                            <img class="mr-16" src="@/assets/icons/Instagram.svg" alt="icon-approved.svg"/>
                        </a>
                        <a target="_blank" :href="this.getFacebookLink">
                            <img class="mr-16" src="@/assets/icons/Facebook.svg" alt="icon-approved.svg"/>
                        </a>
                        <a target="_blank" :href="this.getTiktokLink">
                            <img class="" src="@/assets/icons/TikTok.svg" alt="icon-approved.svg"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="this.isLogged && isTypeUser" class="footer_mobile">
            <router-link :to="{ name: 'Home' }">
                <img v-if="$route.name==='Home'" class="mr-16 footer_mobile_icon footer_mobile_icon_selected" src="@/assets/icons/footer/home_selected.svg" alt="icon-approved.svg"/>
                <img v-else class="mr-16 footer_mobile_icon" src="@/assets/icons/footer/home.svg" alt="icon-approved.svg"/>
            </router-link>
            <router-link :to="{ name: 'PastJobs' }">
                <img v-if="$route.name==='PastJobs'" class="mr-16 footer_mobile_icon footer_mobile_icon_selected" src="@/assets/icons/footer/jobs_selected.svg" alt="icon-approved.svg"/>
                <img v-else class="mr-16 footer_mobile_icon" src="@/assets/icons/footer/jobs.svg" alt="icon-approved.svg"/>
            </router-link>
            <router-link :to="{ name: 'Home' }">
                <img v-if="$route.name==='Editor'" class="mr-16 footer_mobile_icon footer_mobile_icon_selected" src="@/assets/icons/footer/editor_selected.svg" alt="icon-approved.svg"/>
                <img v-else class="mr-16 footer_mobile_icon" src="@/assets/icons/footer/editor.svg" alt="icon-approved.svg"/>
            </router-link>
            <router-link :to="{ name: 'ChatsList' }">
                <img v-if="$route.name==='ChatsList'" class="mr-16 footer_mobile_icon footer_mobile_icon_selected" src="@/assets/icons/footer/mail_selected.svg" alt="icon-approved.svg"/>
                <img v-else class="mr-16 footer_mobile_icon" src="@/assets/icons/footer/mail.svg" alt="icon-approved.svg"/>
            </router-link>
            <router-link :to="{ name: 'Settings' }" active-link="active">
                <img v-if="$route.name==='Settings'" class="mr-16 footer_mobile_icon footer_mobile_icon_selected" src="@/assets/icons/footer/user_selected.svg" alt="icon-approved.svg"/>
                <img v-else class="mr-16 footer_mobile_icon" src="@/assets/icons/footer/user.svg" alt="icon-approved.svg"/>
            </router-link>
        </div>
        <div v-else class="footer_mobile_unauth" :class="{'z-index' : container == 'fluid'}">
            <img src="@/assets/images/BG_mob_white.svg" class="footer_mobile_unauth__circle_white">
            <img src="@/assets/images/BG_mob.svg" class="footer_mobile_unauth__circle">
            <div class="footer_mobile_unauth__content">
                <div class="footer_mobile_unauth__join-email-block">
                    <div class="join-email__title">JOIN OUR EMAIL LIST</div>
                    <div class="join-email__body">
                        <input type="email" v-model="email_user" class="join-email__field input_s form-control" placeholder="your email">
                        <button class="btn-c3 join-email__button" @click="signUp">Subscribe</button>
                    </div>
                </div>
                <div class="footer_mobile_unauth__links-block">
                    <router-link class="links-block__link" :to="{ name: 'HowItWorks' }">How it Works</router-link>
                    <router-link class="links-block__link" :to="{ name: 'Prices' }">Prices</router-link>
                </div>
                <div class="footer_mobile_unauth__delimiter-block">
                    <hr class="delimiter">
                </div>
                <div class="footer_mobile_unauth__copyright-block">
                    Copyright © 2022 Strobe Art. All Rights Reserved.
                </div>
                <div class="footer_mobile_unauth__info-block">
                    <router-link class="info-block__link" :to="{ name: 'TermsOfUse' }">Terms of Use</router-link>
                    <router-link class="info-block__link" :to="{ name: 'PrivacyPolicy' }">Privacy Policy</router-link>
                </div>
            </div>
        </div>
        <div class="modal" id="successModalMail" ref="successModalMail" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="input_modal">
                            <div class="modal_title">{{ message_modal }}</div>
                            <button class="btn_modal_close" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MainLayout from "../layouts/MainLayout";
import {Modal} from "bootstrap";
import {mapGetters, mapMutations} from "vuex";
import {errorMessage} from "@/services/messages";
import helpFunctions from "../helpFunctions";
import ('../css/custom.css');

export default {
    name: "Footer",
    components: {MainLayout},
    props: {
        'container': {
            default: null
        }
    },
    data() {
        return {
            user: {},
            email_user: '',
            successModalMail: null,
            message_modal: '',
        }
    },
    mounted() {
        this.user = JSON.parse(localStorage.getItem('strobeart_user'));
        this.successModalMail = new Modal(this.$refs.successModalMail);

    },
    destroyed() {
        if (this.successModalMail) {
            this.successModalMail.dispose();
        }
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
        ]),
        async signUp() {
            if (helpFunctions.isValidEmail(this.email_user)) {
                this.message_modal = 'Please type correct email address';
                this.successModalMail.show();
                return
            }
            try {
                this.showLoader();
                await this.$http.post(`${this.$http.apiUrl()}add-to-mailing-list`, {
                    'email': this.email_user,
                });
                this.message_modal = 'We’ve added you to our mailing list!';
                this.successModalMail.show();
                this.email_user = '';
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
    },
    computed: {
        ...mapGetters([
            'isLogged',
            'getInstagramLink',
            'getFacebookLink',
            'getTiktokLink',
        ]),
        isTypeUser() {
            return this.user?.type_user || null;
        }
    },
}
</script>

<style lang="scss" scoped>
.footer .item {
    display: flex;
    justify-content: space-between;
}
.email_list {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #AD967F;
    padding-bottom: 32px;
    padding-left: 20px;
}
.form_join_email_list {
    display: flex;
    gap: 27px;
}
.input_s {
    width: 443px;
    height: 46px;
    padding-left: 28px;
    background: white;
    box-sizing: border-box;
    box-shadow: 0px 1px 4px rgba(216, 195, 175, 0.15);
    border-radius: 100px 0px 0px 100px;

    @media only screen and (max-width: 1280px) {
        width: 290px;
    }
}
input::placeholder {
    color: rgba(73, 73, 73, 0.8);
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 24px;
}
.list_link li {
    list-style-type: none;
}
.list_link li a {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 29px;
    display: flex;
    text-decoration: none;
    align-items: center;
    color: #AD967F;
}
.icons_social {
    padding-top: 27px;

    @media only screen and (max-width: 1280px) {
        padding-top: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

    }
}
.icons_social img {
    cursor: pointer;
}
.icons_social img:hover, .footer_sign_in:hover, .list_link li:hover {
    opacity: 0.5;
}
.footer_sign_in {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #AD967F;
    text-decoration: none;
}
.footer_mobile {
    display: none;
}

@media only screen and (max-width: 992px) {
    .footer {
        display: none;
    }
    .footer_mobile {
        position: fixed;
        bottom: 0px;
        padding: 18px 37px;
        left: 0px;
        right: 0px;
        margin-bottom: 0px;

        background: #9E616A;
        border-radius: 42px;
        backdrop-filter: blur(25px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 9999;

        .footer_mobile_icon {
            width: 24px;
            height: 24px;
        }

    }
}
.btn_modal_close {
    border: 0.5px solid #494949;
    box-sizing: border-box;
    border-radius: 100px;
    background: white;
    height: 47px;
    width: 148px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #494949;
}
.input_modal {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 35px;
    padding: 30px;
}
.modal_title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #494949;
}
.footer_mobile_unauth {
    background: #FCF5EF;
    position: relative;
    z-index: 0;
    top: -150px;
    margin-bottom: -150px;
    &.z-index {
        .footer_mobile_unauth__circle {
            display: none;
        }
        .footer_mobile_unauth__circle_white {
            width: 100%;
            display: block !important;
        }
    }

    @media only screen and (min-width: 993px) {
        display: none;
    }
    @media only screen and (min-width: 600px) {
        top: -300px;
        margin-bottom: -300px;
    }
    @media only screen and (min-width: 750px) {
        //top: -400px;
        //margin-bottom: -400px;
    }
    &__circle {
        width: 100%;
        //background-size: cover;
        //background-repeat:no-repeat;
        //background-image: url('data:image/svg+xml;utf8,<svg width="375" height="186" viewBox="0 0 375 186" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.76385e-06 5.59455e-05C3.5004e-06 49.3303 1.3953e-06 131.522 1.3953e-06 131.522C49.7281 166.404 117.174 186 187.5 186C257.826 186 325.272 166.404 375 131.522C375 131.522 375 49.3303 375 0.000106812L187.5 8.13785e-05L4.76385e-06 5.59455e-05Z" fill="#f4f2f2"/></svg>');
    }
    &__circle_white {
        display: none;
        //background-size: cover;
        //background-repeat:no-repeat;
        //background-image: url('data:image/svg+xml;utf8,<svg width="375" height="186" viewBox="0 0 375 186" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.76385e-06 5.59455e-05C3.5004e-06 49.3303 1.3953e-06 131.522 1.3953e-06 131.522C49.7281 166.404 117.174 186 187.5 186C257.826 186 325.272 166.404 375 131.522C375 131.522 375 49.3303 375 0.000106812L187.5 8.13785e-05L4.76385e-06 5.59455e-05Z" fill="white"/></svg>');
    }

    &__content {
        margin-top: 60px;
    }

    &__join-email-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .join-email {
            &__title {
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                display: flex;
                align-items: center;
                color: #AD967F;
            }
            &__body {
                margin-top: 15px;
                padding: 0px 30px;
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
            &__field {
                width: 100%;
                max-width: 210px;

                @media only screen and (max-width: 576px) {
                    max-width: inherit;
                }
            }
            &__button {
                max-width: 90px;
                padding: 10px 3px;
                border-radius: 10px;
                border: none;

                @media only screen and (max-width: 576px) {
                    margin-left: -10px
                }
            }
        }
    }
    &__links-block {
        margin-top: 25px;
        padding: 0px 60px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .links-block__link {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            display: flex;
            align-items: center;
            color: #AD967F;
            text-decoration: none;
        }
    }
    &__delimiter-block {
        padding: 10px 16px;
        .delimiter {
            background: #AD967F;
        }
    }
    &__copyright-block {
        font-weight: 400;
        font-size: 12px;
        line-height: 32px;
        color: #AD967F;
        text-align: center;
    }
    &__info-block {
        width: 300px;
        margin-left:auto;
        margin-right: auto;
        margin-top: 25px;
        padding-bottom: 50px;
        display: flex;
        justify-content: space-between;

        .info-block__link {
            font-weight: 400;
            font-size: 12px;
            line-height: 32px;
            text-align: right;
            color: #AD967F;
            text-decoration: none;
        }
    }
}
</style>
