<template>
    <div class="modal" id="deleteJobModal" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content" v-if="jobForDeleting">
                <div class="modal-header">
                    <h5 class="modal-title">
                        Are you sure you want to delete job #{{jobForDeleting.id}}
                        <span v-if="jobForDeleting.name"> "{{jobForDeleting.name}}"</span>?
                    </h5>
                </div>
                <div class="modal-footer">
                    <button class="btn_modal_t1" data-bs-dismiss="modal">Cancel</button>
                    <button class="btn_modal_t2" @click="deleteJob">Yes</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Modal} from "bootstrap";
import {errorMessage} from "../../services/messages";
import {mapMutations} from "vuex";

export default {
    name: "DeleteJobModal",
    data() {
        return {
            deleteJobModal: null,
            jobForDeleting: null,
        }
    },
    async mounted() {
        this.deleteJobModal = new Modal(document.getElementById('deleteJobModal'));
    },
    destroyed() {
        if (this.deleteJobModal) {
            this.deleteJobModal = null;
        }
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
        ]),
        load(job) {
            this.jobForDeleting = job;
            this.deleteJobModal.show();
        },
        async deleteJob() {
            try {
                this.showLoader();
                await this.$http.postAuth(`${this.$http.apiUrl()}delete-job/${this.jobForDeleting.id}`);
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
            this.$emit('getJobs');
            this.deleteJobModal.hide();
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-title {
    width: 100%;
    text-align: center;
}
.modal-footer {
    border-top: none;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    gap: 35px;
}
.btn_modal_t1 {
    border: 0.5px solid #494949;
    box-sizing: border-box;
    border-radius: 100px;
    background: white;
    height: 47px;
    width: 148px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #494949;
}
@media (max-width: 992px) {
    .modal-footer {
        border-top: none;
        display: flex;
        justify-content: center;
        padding-bottom: 30px;
        gap: 15px;
    }
}
</style>
