<template>
    <div>
        <table class="table" style="margin-top: 15px;">
            <thead class="thead-light">
                <tr>
                    <th v-for="tab in tabs">
                        <div class="d-flex">{{ tab }}</div>
                    </th>
                    <th style="vertical-align: baseline; text-align: center; width: 110px;">
                        Action
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="table_body" v-for="email_text in email_texts">
                    <td style="padding: 15px;">{{email_text.id}}</td>
                    <td>
                        <input class="form-control" v-model="email_text.subject">
                    </td>
                    <td>
                        <input class="form-control" v-model="email_text.text">
                    </td>
                    <td style="text-align: center;">
                        <svg class="cms_user_icons" @click="updateEmailTexts(email_text)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9
                             9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"/></svg>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import {errorMessage, successMessage} from "../../services/messages";

export default {
    name: "CmsEmailTexts",
    data() {
        return {
            email_texts: [],
            tabs: [
                'ID',
                'Subject',
                'Text'
            ]
        };
    },
    mounted() {
        this.getEmailTexts();
    },
    methods: {
        async getEmailTexts() {
            let response = await this.$http.get(`${this.$http.apiUrl()}cms/get-email-texts`);
            this.email_texts = response.data.data;
        },
        async updateEmailTexts(email_text) {
            try {
                let response = await this.$http.post(`${this.$http.apiUrl()}cms/update-email-texts`, {
                    'id': email_text.id,
                    'subject': email_text.subject,
                    'text': email_text.text,
                });
                if(response.data.success) {
                    successMessage("Email text successfully updated!");
                    this.getEmailTexts();
                }
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.table_body {
    font-size: 14px;
}
.table_body input {
    width: 100%;
}
.cms_user_icons {
    width: 20px;
    margin: 5px;
    fill: #674837;
    cursor: pointer;
}
</style>
