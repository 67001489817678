<template>
    <div>
        <MainLayout :is-show-header=true>
            <div class="container_page">
                <div id="box_container_id" class="box_container">
                    <div>
                        <div class="pl-14 text-st1">Inbox</div>
                        <div class="pt-4 cp" @click="goToChats">
                            <div class="inbox">
                                <div class="me-4">
                                    <img class="" src="@/assets/icons/mail.svg" alt="icon-approved.svg"/>
                                </div>
                                <div class="inbox_text">
                                    You have <span class="count_message"><ChatsMessageCount/></span> new messages.
                                </div>
                            </div>
                        </div>
                        <div class="pt-5 text-st1" id="active-jobs">Active Jobs</div>
                        <div class="row pt-4">
                            <div class="input_box">
                                <input class="icon_input_search style_input1" v-model="search_key" @keyup="searchJob" type="text">
                                <img class="search_icon" src="@/assets/icons/search.svg" alt="">
                            </div>
                        </div>
                        <div v-if="show" class="pt-4">
                            <div class="items_jobs">
                                <div v-for="(job, index) in jobs_search" ref="refJob" :key="index">
                                    <div class="item_j" v-if="job!==job_details">
                                        <div>
                                            <div class="box_i" @click="jobDetails(job)">
                                                <div class="box_image">
                                                    <img v-if="image_preview_urls[job.id] === 'tif'" class="job_image" src="@/assets/images/tif.png" alt="icon-approved.svg"/>
                                                    <img v-else-if="image_preview_urls[job.id]" class="job_image" :src="image_preview_urls[job.id]" alt="icon-approved.svg"/>
                                                    <img v-else class="job_image" src="@/assets/images/raw.png" alt="icon-approved.svg"/>
                                                </div>
                                                <div class="ms-2 box_i2">
                                                    <div class="title_item_job">{{ job.name }}</div>
                                                    <div class="description_item_j">
                                                        <div>
                                                            <span class="text_bold">Files: </span>{{ getJobImages(job).length }}
                                                        </div>
                                                        <div>
                                                            <span class="text_bold">Editing Level: </span>{{ job.editing_level }}
                                                        </div>
                                                        <div v-bind:class="{ text_red: diffDate(job.due_date)<3 }">
                                                            {{ diffDate(job.due_date) }} days left to completion
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="bookmark" ref="bookmark" @click.prevent="bookmark(job,index)">
                                                <div v-if="!isMyWorkJob(job)" ref="bookmark_img" class="img_flag_0"/>
                                                <div v-else ref="bookmark_img" class="img_flag_1"/>
                                            </div>
                                        </div>
                                        <div>
                                            <span v-if="jobWaitingApproval(job)">In progress</span>
                                        </div>
                                    </div>
                                    <JobDetails @declineJob="declineJob" :index="index" :image_preview_urls="job_details_image_preview_urls" :job-details="job_details" v-if="job===job_details"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    </div>
</template>

<script>
import JobEditorTimerStep2 from "./JobEditorTimerStep2";
import {mapGetters, mapMutations} from 'vuex';
import {errorMessage} from "../services/messages";
import MainLayout from "../layouts/MainLayout";
import JobDetails from "./JobDetails";
import moment from 'moment-timezone'
import ChatsMessageCount from "../components/ChatsMessageCount";
import axios from "axios";
import ('../css/custom.css');

export default {
    name: "HomePage",
    components: {
        MainLayout,
        JobDetails,
        JobEditorTimerStep2,
        ChatsMessageCount
    },
    data() {
        return {
            search_key: null,
            job_details: {},
            jobs: {},
            jobs_search: {},
            image_preview_urls: {},
            job_details_image_preview_urls: [],
            show: false
        };
    },
    mounted() {
        this.getJobs();
    },
    computed: {
        ...mapGetters([
            'getUser',
        ]),
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
        ]),
        goToChats() {
            this.$router.push({name: 'ChatsList'}).then();
        },
        isMyWorkJob(job) {
            return job?.user_work?.user_id === this.getUser?.id;
        },
        async bookmark(job,index) {
        },
        declineJob(data) {
            this.$refs.refJob[data].remove();
            this.job_details = {};
        },
        diffDate(date) {
            let end_job = moment(date).tz(moment.tz.guess(true));
            let now_date = moment().tz(moment.tz.guess(true)).format("YYYY-MM-DD");
            let dif_finish_job = end_job.diff(now_date, 'days');
            if (dif_finish_job < 0) {
                return 0;
            }
            return dif_finish_job;
        },
        getJobImages(jobs) {
            if (jobs?.images || null) {
                return Object.values(jobs?.images).map(key => key.src_cropped??key.src);
            }
            return [];
        },
        async getJobs() {
            try {
                this.showLoader();
                const response = await this.$http.getAuth(`${this.$http.apiUrl()}jobs`);
                //SHOULD FILL PREVIEW ARRAY
                response?.data?.data.forEach(job => {
                    this.image_preview_urls[job.id] = null;
                });
                //CUSTOM FIX TO LOAD TIFF IMAGES
                let total_images = response?.data?.data.length;
                let image_counter = 0;
                await response?.data?.data.forEach(job => {
                    let image_path = (job?.images[0]?.src_cropped || job?.images[0]?.src) || '';
                    if(image_path) {
                        let file_name = image_path.split("/").pop();
                        let extension = file_name.split(".").pop();
                        if (extension.toLowerCase() === 'tiff') {
                            try {
                                //image_path = image_path.replace('/storage', '');
                                axios.get(image_path, {responseType: "blob"}).then(response => {
                                    const blob = new Blob([response.data], {type: response.data.type});
                                    const reader = new FileReader();
                                    reader.onload = e => {
                                        let tiff = new Tiff({buffer: e.target.result});
                                        let canvas = tiff.toCanvas();
                                        this.image_preview_urls[job.id] = canvas.toDataURL();
                                        image_counter++;
                                        if(image_counter === total_images) {
                                            this.show = true;
                                        }
                                    }
                                    reader.readAsArrayBuffer(blob);
                                });
                            } catch (e) {
                                this.image_preview_urls[job.id] = 'tif';
                                image_counter++;
                                if(image_counter === total_images) {
                                    this.show = true;
                                }
                            }
                        }
                        else if (extension.toLowerCase() === 'gif' || extension.toLowerCase() === 'webp' || extension.toLowerCase() === 'bmp' ||
                            extension.toLowerCase() === 'jpeg' || extension.toLowerCase() === 'jpg' || extension.toLowerCase() === 'png') {
                            this.image_preview_urls[job.id] = image_path;
                            image_counter++;
                            if(image_counter === total_images) {
                                this.show = true;
                            }
                        }
                        else {
                            this.image_preview_urls[job.id] = "";
                            image_counter++;
                            if(image_counter === total_images) {
                                this.show = true;
                            }
                        }
                    }
                    else {
                        this.image_preview_urls[job.id] = '';
                        image_counter++;
                        if(image_counter === total_images) {
                            this.show = true;
                        }
                    }
                });
                //CUSTOM FIX TO LOAD TIFF IMAGES
                this.jobs = response?.data?.data || {};
                this.jobs_search = response?.data?.data || {};
                this.job_details = {};
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        searchJob(event) {
            let search_text = (event.target.value).toUpperCase();
            if (search_text && search_text.length > 1) {
                this.jobs_search = this.jobs.filter(job =>
                    (job.description != null && job.description.toUpperCase().includes(search_text)) ||
                    (job.name != null && job.name.toUpperCase().includes(search_text))
                );
            } else {
                this.jobs_search = this.jobs;
            }
        },
        async jobDetails(job) {
            this.job_details_image_preview_urls = [];
            let finished_images = job?.finished_worked_images.map(el => el.image_id);
            let todo_images = job?.images.filter(el => !finished_images.includes(el.id));
            let image_paths = Object.values(todo_images).map(key => key.src_cropped ?? key.src);
            await image_paths.forEach(image_path => {
                let file_name = image_path.split("/").pop();
                let extension = file_name.split(".").pop();
                if (extension.toLowerCase() === 'tiff') {
                    try {
                        //image_path = image_path.replace('/storage', '');
                        axios.get(image_path, {responseType: "blob"}).then(response => {
                            const blob = new Blob([response.data], {type: response.data.type});
                            const reader = new FileReader();
                            reader.onload = e => {
                                let tiff = new Tiff({buffer: e.target.result});
                                let canvas = tiff.toCanvas();
                                this.job_details_image_preview_urls.push(canvas.toDataURL());
                            }
                            reader.readAsArrayBuffer(blob);
                        });
                    } catch (e) {
                        this.job_details_image_preview_urls.push('tif');
                    }
                }
                else if (extension.toLowerCase() === 'gif' || extension.toLowerCase() === 'webp' || extension.toLowerCase() === 'bmp' ||
                    extension.toLowerCase() === 'jpeg' || extension.toLowerCase() === 'jpg' || extension.toLowerCase() === 'png') {
                    this.job_details_image_preview_urls.push(image_path);
                }
                else {
                    this.job_details_image_preview_urls.push("");
                }
            });
            this.job_details = job;
        },
        jobWaitingApproval(job) {
            let nonUprroved = job?.images?.filter(i => i.approval == 0);
            return job?.finished_worked_images.length > 0 && nonUprroved.length > 0;
        },
    },
}
</script>

<style lang="scss" scoped>
.input_box {
    position: relative;
}
.icon_input_search {
    padding-left: 40px;
    padding-right: 40px;
}
.style_input1 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    padding: 10px 13px;
    height: 35px;
    color: #494949;
    background: white;
    border: 0.3px solid #494949;
    box-sizing: border-box;
    border-radius: 100px;
    width: 100%;
    padding-left: 40px;
}
.search_icon {
    position: absolute;
    left: 25px;
    top: 10px;
    cursor: pointer;
}
.container_page {
    padding-top: 29px;
}
.text-st1 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #494949;
}
.inbox {
    background: white;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    display: flex;
    padding: 22px 36px;
    align-items: center;
}
.inbox_text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #494949;
    display: flex;
    gap:5px;
    .count_message {
        color: red;
        padding: 0 5px;
    }
}
.box_i {
    display: flex;
    width: 94%;
}
.box_i2 {
    width: 80%;
}
.input_box {
    position: relative;
}
.item_j {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 8px 7px;
    background: white;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    cursor: pointer;
}
.title_item_job {
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
    letter-spacing: -0.02em;
    color: #494949;
    padding: 8px 0;
}
.description_item_j {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #494949;
}
.text_red {
    color: red;
}
.items_jobs {
    gap: 8px;
    display: flex;
    flex-direction: column;
    //height: 54vh;
    //overflow: auto;
}
.bookmark {
    position: absolute;
    top: 18px;
    right: 23px;
    cursor: pointer;
}
.description_item_j .text_bold {
    font-weight: bold;
}
.inbox, .input_box, .item_j {
    max-width: 50%;
}
.job_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1));
    border-radius: 5px;
}
.box_image {
    width: 104px;
    height: 100px;
}
.bookmark img {
    width: 17px;
    height: 22px;
}
.bookmark {
    z-index: 998;
}
.img_flag_0 {
    width: 17px;
    height: 22px;
    background: url("../assets/icons/bookmark-regular.svg");
}
.img_flag_1 {
    width: 17px;
    height: 22px;
    background: url("../assets/icons/bookmark-regular2-filled.svg");
}

@media only screen and (min-width: 993px) {
    #box_container_id.box_container {
        padding-bottom: 85px;
    }
}
@media only screen and (max-width: 992px) {
    .container_page {
        margin-top: 0px;
        padding-left: 15px;
        padding-right: 15px;
        display: inherit;
    }
    .inbox {
        padding: 22px 20px;
    }
    .items_jobs{
        padding-bottom: 90px;
    }
    .box_container {
        height: calc(100vh - 144px);
    }
    .inbox, .input_box, .item_j {
        width: 100%;
        max-width: 100%;
    }
    .box_i {
        display: flex;
        width: 88%;
    }
    .box_i2 {
        width: 60%;
    }
}
</style>
