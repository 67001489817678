<template>
    <div class="csm_body row">
        <div class="col-2 cms_select_info_display">
            <div class="cms_select_option" :class="{active: detailInfo === 'users'}" @click="changeMain('users')">Users</div>
            <div class="cms_select_option" :class="{active: detailInfo === 'ui'}" @click="changeMain('ui')">UiChanges</div>
            <div class="cms_select_option" :class="{active: detailInfo === 'social'}" @click="changeMain('social')">Social Networks</div>
            <div class="cms_select_option" :class="{active: detailInfo === 'emails'}" @click="changeMain('emails')">Email Texts</div>
            <div class="cms_select_option" :class="{active: detailInfo === 'payment'}" @click="changeMain('payment')">Payments</div>
            <div class="cms_select_option" :class="{active: detailInfo === 'coupons'}" @click="changeMain('coupons')">Coupons</div>
            <div class="cms_select_option" :class="{active: detailInfo === 'questions'}" @click="changeMain('questions')">Chat GPT Questions</div>
            <div class="cms_select_option" :class="{active: detailInfo === 'calendar'}" @click="changeMain('calendar')">Calendar</div>
            <div class="cms_select_option" :class="{active: detailInfo === 'audits'}" @click="changeMain('audits')">Audits</div>
        </div>
        <div class="col">
            <CmsUsers v-if="detailInfo === 'users'"/>
            <CmsUiChanges v-else-if="detailInfo === 'ui'"/>
            <CmsSocialNetworks v-else-if="detailInfo === 'social'"/>
            <CmsEmailTexts v-else-if="detailInfo === 'emails'"/>
            <CmsPayment v-else-if="detailInfo === 'payment'"/>
            <CmsCoupons v-else-if="detailInfo === 'coupons'"/>
            <CmsQuestions v-else-if="detailInfo === 'questions'"/>
            <CmsPostCalendar v-else-if="detailInfo === 'calendar'"/>
            <CmsAudits v-else-if="detailInfo === 'audits'"/>
        </div>
    </div>
</template>

<script>
import CmsAudits from "./CmsAudits";
import CmsUsers from "./CmsUsers";
import CmsUiChanges from "./CmsUiChanges";
import CmsSocialNetworks from "./CmsSocialNetworks";
import CmsPayment from "./CmsPayments";
import CmsPostCalendar from "./CmsPostCalendar";
import CmsCoupons from "./CmsCoupons";
import CmsQuestions from "./CmsQuestions";
import CmsEmailTexts from "./CmsEmailTexts";

export default {
    name: "CmsBody",
    components: {
        CmsEmailTexts,
        CmsCoupons,
        CmsQuestions,
        CmsUiChanges,
        CmsSocialNetworks,
        CmsPostCalendar,
        CmsUsers,
        CmsPayment,
        CmsAudits
    },
    data() {
        return {
            detailInfo: "users",
        }
    },
    mounted() {
        if(!localStorage.getItem('currentTab')) {
            localStorage.setItem('currentTab', this.detailInfo);
        }
        else {
            this.changeMain(localStorage.getItem('currentTab'));
        }
    },
    methods: {
        changeMain(main) {
            this.detailInfo = main;
            localStorage.setItem('currentTab', this.detailInfo);
        }
    }
}
</script>

<style lang="scss" scoped>
.csm_body {
    margin: 0 2% 0 2%;
    .cms_select_info_display {
        padding: 0;
        width: 10%;
        .cms_select_option {
            font-weight: bold;
            padding: 10px;
            color: #696969;
            cursor: pointer;
            .active {
                color: #E27582;
            }
        }
    }
    .cms_select_option:hover {
        color: #E27582;
    }
    .cms_select_option:active {
        color: #E27582;
    }
}
.active {
    color: #E27582!important;
}
</style>
