<template>
    <div class="box_time">
        <select v-model="hour" class="time_b" @change="timeChanged">
            <option class="h6" v-for="item in hours()" :value="item">{{ item }}</option>
        </select>
        <div class="colon">:</div>
        <select v-model="minute" class="time_b" @change="timeChanged">
            <option v-for="min in minutes()" :value="min">{{ min }}</option>
        </select>
        <div>
            <div class="box_12" :class="{active_am_pm:am_pm==='AM'}" @click="amPm('AM')">AM</div>
            <div class="box_12" :class="{active_am_pm:am_pm==='PM'}" @click="amPm('PM')">PM</div>
        </div>
    </div>
</template>

<script>
import helpFunctions from "../helpFunctions";
import moment from "moment";

export default {
    name: 'SelectTime',
    props: {
        available_at: null,
    },
    data() {
        return {
            am_pm: 'AM',
            hour: '0',
            minute: '00',
        }
    },
    mounted() {
        if(this.available_at) {
            this.hour = moment(this.available_at).format('h');
            this.minute = moment(this.available_at).format('mm');
            this.am_pm = moment(this.available_at).format('A');
        }
        else {
            this.hour = helpFunctions.getCurrentHour(5);
            this.minute = helpFunctions.getCurrentMinutes(5);
            this.am_pm = helpFunctions.getCurrentMeridiem(5);
        }
    },
    methods: {
        hours() {
            return helpFunctions.hours();
        },
        minutes() {
            return helpFunctions.minutes();
        },
        timeChanged() {
            this.$emit("timeChanged", [this.hour, this.minute, this.am_pm]);
        },
        amPm(am_pm) {
            this.am_pm = am_pm;
            this.$emit("timeChanged", [this.hour, this.minute, this.am_pm]);
        },
    },
}
</script>

<style lang="scss" scoped>
.box_time {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
        text-align: center;
    }
}
.time_b {
    padding: 9px 22px;
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #494949;
    background: white;
    border: 0.3px solid #494949;
    box-sizing: border-box;
    border-radius: 5px;
    height: 60px;
}
.colon {
    color: #494949;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
}
.box_12 {
    background: white;
    border: 0.3px solid #494949;
    box-sizing: border-box;
    border-radius: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    color: black;
    cursor: pointer;
    height: 30px;
    margin-left: 7px;
}
@media only screen and (max-width: 992px) {
    .time_b {
        font-weight: 500;
        font-size: 20px;
    }
}
</style>
