<template>
    <div class="modal" id="deleteUserModal" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Delete User</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                </div>
                <div class="modal-body justify-content-center">
                    <div style="margin-bottom: 15px;">
                        Are you sure you want to delete {{user.name}} (id:{{user.id}}) account?
                    </div>
                    <div style="margin-bottom: 10px; text-align: center;">
                        <button type="button" class="btn_post_n" @click="deleteUserAccount()">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {errorMessage, successMessage} from "../../../services/messages";

export default {
    name: "DeleteUserModal",
    props: ['user', 'modal'],
    methods: {
        async deleteUserAccount() {
            try {
                await this.$http.post(`${this.$http.apiUrl()}cms/delete-user`, { 'id': this.user.id });
                successMessage("Account " + this.user.name + " has been deleted")
                this.modal.hide();
                this.$emit('refresh');
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
        }
    }
}
</script>
