import moment from "moment-timezone";

const helpFunctions = {
    style_guide: {
        name: '',
        remove_background: '',
        size_with: '',
        size_height: '',
        unit_measurement: '',
        file_format: '',
        color_profile: '',
        resolution: '300',
        resolution_units: 'dpi',
        other: '',
        file_id_logo: '',
        file_id_watermark: '',
        file_video_instruction_ids: '',
        file_id_video_typography: '',
        file_id_color_palette: '',
    },
    requirements: {
        editing_level: "",
        style_guide: '',
        color_profile: '',
        file_format: [],
        resolution: '300',
        resolution_units: 'dpi',
        other: '',
        checked_option: {
            white_background: false,
            red_eye: false,
            invisible_mannequin: false,
            color_matching: false,
            color_grading: false,
            background_removal: false,
            logo_watermarks: false,
        },
        file_id_color_palette:'',
        file_id_typography:'',
        add_logo: '',
        add_watermark: '',
        file_video_instruction_id:'',
        video_instruction_type: 'sample_works',
        name: '',
        description: '',
        sizes: [],
    },
    publish_model: {
        image_jobs_id: null,
        date_publication: null,
        product_id: null,
        is_replaced: false,
        tag_products: {},
        hashtags: '',
        description: '',
        tag_users: '',
        sequence_pictures: null,
        timezone: moment.tz.guess(true),
        platform: null,
        facebook_pages: [],
        schedule_id: null,
        user_id: null
    },
    add_review: {
        editor_name: '',
        rating: 0,
        editor_id: null,
        image_job_id: null,
        message: '',
    },
    editModals: {
        userName: null,
        userBusiness: null,
        userLocation: null,
        userBio: null,
        userEmail: null,
        userPhone: null,
        userEnglishLevel: null,
        userPassword: null,
        userCoupon: null,
        checkPassword: null,
    },
    jobModals: {
        photoModal: null,
        addSampleWorksFolderModal: null,
        deleteSampleWorksFolderModal: null,
        deleteFileModal: null,
        addSampleWorkModal: null,
    },
    upload_file: {
        file: null,
        file_name: '',
        file_valid: false,
        file_name_valid: false
    },
    new_job_errors: {
        editing_level: false,
        style_guide: false,
        color_profile: false,
        file_format: false,
    },
    job_errors: {
        name: false,
        remove_background: false,
        size_with: false,
        size_height: false,
        unit_measurement: false,
        file_format: false,
        resolution_units: false,
        resolution: false,
        video_instructions: false,
        color_profile: false
    },
    onboading: [{
    },
    {
        image: 'undraw_save_to_bookmarks_re_8ajf_1.png',
        title: 'Post a job.',
        text: 'Upload the raw files and add a new job.',
    },
    {
        image: 'undraw_collaborators_re_hont_1.png',
        title: 'Find an editor.',
        text: 'After posting a job, we are going to match you with an editor.',
    },
    {
        image: 'undraw_add_color_re_buro_2.png',
        title: 'Use your edited files.',
        text: 'An editor will upload the edited files within munites. Then, you can post the files on every platform you need.',
    }],
    makeId (idLength = 10) {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < idLength; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    },
    pageChange(index, current_image, selectedImagesLength) {
        if (index === 'forward') {
            current_image = current_image + 1 >= selectedImagesLength ? selectedImagesLength - 1 : current_image + 1;
        } else if (index === 'backward') {
            current_image = current_image == 0 ? 0 : current_image - 1;
        }
        return current_image;
    },
    getCurrentHour(addTime = 0) {
        return moment().add(addTime, 'minutes').format('h');
    },
    getCurrentMinutes(addTime = 0) {
        return moment().add(addTime, 'minutes').format('mm');
    },
    getCurrentMeridiem(addTime = 0) {
        return moment().add(addTime, 'minutes').format('A');
    },
    getFormattedDateTime(selected_date, hour, minute, am_pm) {
        if (hour < 10) {
            hour = '0' + hour;
        }
        return selected_date + ' ' + hour + ':' + minute + ' ' + am_pm;
    },
    getDatePosted(date, modal_date) {
        return moment(date).format('dddd, MMM DD') + ' at ' + modal_date + '!';
    },
    hours() {
        let h = [];
        for (let i = 0; i <= 12; i++) {
            h.push('' + i);
        }
        return h;
    },
    minutes() {
        let min = [];
        for (let i = 0; i < 60; i++) {
            if (i < 10) {
                min.push('0' + i);
            } else {
                min.push('' + i);
            }
        }
        return min;
    },
    isEmptyObject(obj) {
        if (obj) {
            return Object.keys(obj).length !== 0;
        }
        return 0;
    },
    isValidEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]{1,25}\.)+[a-zA-Z]{2,6}))$/;
        return !re.test(email);
    },
    isValidPassword(password) {
        const re =  /^(?=.*[a-z])(?=.*[A-Z])/;
        return !re.test(password);
    },
    password(val) {
        if (!val) {
            return 'This field is required';
        }
        if (this.isValidPassword(val)) {
            return 'The password must be uppercase and lowercase';
        }
        if (val.length < 8) {
            return 'Password needs to be at least 8 characters';
        }
        if (this.isValidPassword(val) && val.length < 8) {
            return 'The password must be uppercase and lowercase. Password needs to be at least 8 characters';
        }
        return '';
    },
    confirm_password(password, val) {
        if (!val) {
            return 'This field is required';
        }
        if (password && val && password !== val) {
            return 'Please make sure your passwords match';
        }
        return '';
    },

}

export default helpFunctions
