import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            requiresAuth: true,
            title: 'Home',
        },
    },
    {
        path: '/profile',
        name: 'Profile',
        meta: {
            requiresAuth: true,
            title: 'Profile',
        },
        component: () => import(/* webpackChunkName: "profile" */ '../views/Profile')
    },
    {
        path: '/jobs',
        name: 'Jobs',
        meta: {
            requiresAuth: true,
            title: 'Jobs',
        },
        component: () => import(/* webpackChunkName: "profile" */ '../views/Jobs')
    },
    {
        path: '/profile/settings',
        name: 'Settings',
        meta: {
            requiresAuth: true,
            title: 'Settings',
        },
        component: () => import(/* webpackChunkName: "settings" */ '../views/ProfileSettings')
    },
    {
        path: '/brand-image',
        name: 'BrandImage',
        meta: {
            requiresAuth: true,
            title: 'Brand Image',
        },
        component: () => import(/* webpackChunkName: "profile" */ '../views/BrandImage')
    },
    {
        path: '/messages',
        name: 'Messages',
        meta: {
            requiresAuth: true,
            title: 'Messages',
        },
        component: () => import(/* webpackChunkName: "messages" */ '../views/Messages')
    },
    {
        path: '/registration',
        name: 'Registration',
        meta: {
            title: 'Home',
        },
        component: () => import(/* webpackChunkName: "registration" */ '../views/Registration')
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            title: 'Login page',
        },
        component: () => import(/* webpackChunkName: "login" */ '../views/Login')
    },
    {
        path: '/confirm-email',
        name: 'ConfirmEmail',
        meta: {
            title: 'Confirm your email',
        },
        component: () => import(/* webpackChunkName: "ConfirmEmail" */ '../views/ConfirmEmail')
    },
    {
        path: '/set-new-password',
        name: 'SetNewPassword',
        meta: {
            title: 'Set new password',
        },
        component: () => import(/* webpackChunkName: "setnewpassword" */ '../views/SetNewPassword')
    },
    {
        path: '/add-new-job',
        name: 'AddNewJob',
        meta: {
            requiresAuth: true,
            title: 'Add new job',
        },
        component: () => import(/* webpackChunkName: "AddNewJob" */ '../views/AddNewJob')
    },
    {
        path: '/business-homepage',
        name: 'BusinessHomePage',
        meta: {
            requiresAuth: true,
            title: 'Home',
        },
        component: () => import(/* webpackChunkName: "BusinessHomePage" */ '../views/BusinessHomePage')
    },
    {
        path: '/file-approval/:id',
        name: 'FileApproval',
        meta: {
            requiresAuth: true,
            title: 'File approval',
        },
        component: () => import(/* webpackChunkName: "FileApproval" */ '../views/FileApproval')
    },
    {
        path: '/working-job/:id',
        name: 'WorkingJob',
        meta: {
            requiresAuth: true,
            title: 'Working job',
        },
        component: () => import(/* webpackChunkName: "WorkingJob" */ '../views/WorkingJob')
    },
    {
        path: '/job/:id/upload-files',
        name: 'UploadFiles',
        meta: {
            requiresAuth: true,
            title: 'Upload files',
        },
        component: () => import(/* webpackChunkName: "UploadFiles" */ '../views/UploadFiles')
    },
    {
        path: '/job/:id/chat',
        name: 'Chat',
        meta: {
            requiresAuth: true,
            title: 'Chat',
        },
        component: () => import(/* webpackChunkName: "Chat" */ '../views/Chat')
    },
    {
        path: '/chats',
        name: 'ChatsList',
        meta: {
            requiresAuth: true,
            title: 'Chats',
        },
        component: () => import(/* webpackChunkName: "Chat" */ '../views/ChatsList')
    },
    {
        path: '/past-jobs',
        name: 'PastJobs',
        meta: {
            requiresAuth: true,
            title: 'Past jobs',
        },
        component: () => import(/* webpackChunkName: "PastJob" */ '../views/PastJob')
    },
    {
        path: '/schedule-grid',
        name: 'ScheduleGrid',
        meta: {
            requiresAuth: true,
            title: 'Schedule grid',
        },
        component: () => import(/* webpackChunkName: "PastJob" */ '../views/ScheduleGrid')
    },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        meta: {
            title: 'Privacy Policy',
        },
        component: () => import(/* webpackChunkName: "profile" */ '../views/PrivacyPolicy')
    },
    {
        path: '/how-it-works',
        name: 'HowItWorks',
        meta: {
            title: 'How it works',
        },
        component: () => import(/* webpackChunkName: "profile" */ '../views/HowItWorks')
    },
    {
        path: '/prices',
        name: 'Prices',
        meta: {
            title: 'Prices',
        },
        component: () => import(/* webpackChunkName: "profile" */ '../views/Prices')
    },
    {
        path: '/about',
        name: 'About',
        meta: {
            title: 'About',
        },
        component: () => import(/* webpackChunkName: "profile" */ '../views/About')
    },
    {
        path: '/terms-of-use',
        name: 'TermsOfUse',
        meta: {
            title: 'Name',
        },
        component: () => import(/* webpackChunkName: "profile" */ '../views/TermsOfUse')
    },
    {
        path: '/contact-us',
        name: 'ContactUs',
        meta: {
            title: 'Contact us',
        },
        component: () => import(/* webpackChunkName: "profile" */ '../views/ContactUs')
    },
    {
        path: '/how-connect-to-facebook',
        name: 'HowFacebookConnect',
        meta: {
            title: 'How connect to Facebook',
        },
        component: () => import(/* webpackChunkName: "profile" */ '../views/HowFacebookConnect')
    },
    {
        path: '/how-connect-to-shopify',
        name: 'HowShopifyConnect',
        meta: {
            title: 'How connect to Shopify',
        },
        component: () => import(/* webpackChunkName: "profile" */ '../views/HowShopifyConnect')
    },
    {
        path: '/how-connect-to-amazon',
        name: 'HowAmazonConnect',
        meta: {
            title: 'How connect to Amazon',
        },
        component: () => import(/* webpackChunkName: "profile" */ '../views/HowAmazonConnect')
    },
    {
        path: '/approval-process',
        name: 'ApprovalProcess',
        meta: {
            title: 'Approval ApprovalProcessProcess',
        },
        component: () => import(/* webpackChunkName: "profile" */ '../views/ApprovalProcess')
    },
    {
        path: '/facebook-error',
        name: 'FacebookError',
        meta: {
            title: 'Facebook Error',
        },
        component: () => import(/* webpackChunkName: "profile" */ '../views/FacebookError')
    },
    {
        path: '/social-networks-info',
        name: 'SocialNetworksInfo',
        meta: {
            title: 'Social Networks Info',
        },
        component: () => import(/* webpackChunkName: "profile" */ '../views/SocialNetworksInfo')
    },
    {
        path: '/FAQ',
        name: 'FAQ',
        meta: {
            title: 'FAQ',
        },
        component: () => import(/* webpackChunkName: "profile" */ '../views/FAQ')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0)
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem('strobeart_jwt') == null) {
            next({
                path: '/about',
                params: {nextUrl: to.fullPath}
            })
        } else if (localStorage.getItem('strobeart_user') == null || JSON.parse(localStorage.getItem('strobeart_user')).type_user == null) {
            next({
                path: '/registration?tab=type_user',
                params: {nextUrl: to.fullPath}
            })
        } else {
            next();
        }
    } else {
        next()
    }
})
export default router
