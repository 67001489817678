<template>
    <div class="item_forgot_password">
        <div class="forgot_password">
            <div class="app_logo">
                <img class="" src="@/assets/icons/app_logo.svg" alt="icon-approved.svg"/>
            </div>
            <div class="form_forgot_password">
                <div class="text_title">Password reset successful</div>
            </div>
            <div class="mb-4 box_btn">
                <div class="btn-c2" @click="homePage"> Back to login </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapMutations} from 'vuex';
import {errorMessage} from "../services/messages";

export default {
    name: "ErrorSetNewPassword",
    props: ['errorMessage'],
    components: {
    },
    data() {
        return {
        };
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
        ]),
        async homePage() {
            this.$router.push({to: '/login', name: "Login"}).then();
        },
    }
}
</script>

<style lang="scss" scoped>
.item_forgot_password {
    padding-top: 170px;
}
.text_title {
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 44px;
    color: black;
    text-align: center;
    padding-bottom: 19px;
}
.app_logo {
    display: none;
    padding-top: 80px;
    padding-bottom: 25px;
}
.montserrat .form-control {
    padding: 15px 40px;
}
.montserrat {
    width: 100%;
    display: flex;
    justify-content: center;
}
.box_btn {
    display: grid;
    justify-content: center;
    padding-top: 90px;
    padding-bottom: 100px;
}

@media only screen and (max-width: 992px) {
    .montserrat {
        width: 100%;
    }
    .app_logo {
        display: block;
    }
    .box_btn {
        padding-top: 10px;
        padding-bottom: 40px;
    }
}
</style>
