<template>
    <div>
        <div class="text-center">
            <button class="button btn w-50 cms_user_type_display" :class="{active: userType === 'business'}" @click="changeUserType('business')">Business</button>
            <button class="button btn w-50 cms_user_type_display" :class="{active: userType === 'editor'}" @click="changeUserType('editor')">Editor</button>
        </div>
        <table class="table" style="margin-top: 15px;">
            <thead class="thead-light">
                <tr>
                    <th v-for="(value, index) in filters" @click="setFilter(value)"
                        v-if="(value !== 'coupon' && value !== 'money_spent' && value !== 'jobs' && value !== 'english_level') ||
                        (userType === 'business' && (value === 'coupon' || value === 'money_spent' || value === 'jobs')) ||
                        (userType === 'editor' && value === 'english_level')">
                        <div class="d-flex">
                            {{ tabs[index] }}
                            <div class="d-block sort_arrows">
                                <svg class="sort_arrows_up" :class="{active: sort && filter === value}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                    <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/></svg>
                                <svg class="sort_arrows_down" :class="{active: !sort && filter === value}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                    <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/></svg>
                            </div>
                        </div>
                    </th>
                    <th style="vertical-align: baseline; text-align: center; width: 110px;">
                        Action
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="table_body" v-for="user in users" v-if="user.type_user === userType">
                    <td style="padding: 15px;">{{user.id}}</td>
                    <td>
                        <input class="form-control" :id="'userName' + user.id" :value=user.name>
                    </td>
                    <td>
                        <input class="form-control" :id="'userPhone' + user.id" :value=user.phone>
                    </td>
                    <td>
                        <input class="form-control" :id="'userEmail' + user.id" :value=user.email>
                    </td>
                    <td>
                        <input class="form-control" :id="'userLocation' + user.id" :value=user.location>
                    </td>
                    <td>
                        <input class="form-control" :id="'userBusinessName' + user.id" :value=user.business_name>
                    </td>
                    <td v-if="userType === 'editor'">
                        <select :id="'userEnglishLevel' + user.id" class="form-select" :value=user.english_level>
                            <option value="Conversational">Conversational</option>
                            <option value="Advanced">Advanced</option>
                            <option value="Fluent">Fluent</option>
                        </select>
                    </td>
                    <td v-if="userType === 'business'">
                        <input class="form-control" :id="'userCoupon' + user.id" :value=user.coupon>
                    </td>
                    <td v-if="userType === 'business'" style="padding: 15px;">
                        {{ user.jobs }}
                    </td>
                    <td v-if="userType === 'business'" style="padding: 15px;">
                        {{ "$" + (user.money_spent/100).toFixed(2) }}
                    </td>
                    <td style="padding: 15px;">{{ createdAt(user.created_at) }}</td>
                    <td style="text-align: center;">
                        <svg class="cms_user_icons" @click="updateUserInfo(user.id)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9
                             9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"/></svg>
                        <svg class="cms_user_icons" @click="openNewUserPasswordModal(user.id)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                            <path d="M592 288H576V212.7c0-41.84-30.03-80.04-71.66-84.27C456.5 123.6 416 161.1 416 208V288h-16C373.6 288 352 309.6 352 336v128c0 26.4 21.6 48
                             48 48h192c26.4 0 48-21.6 48-48v-128C640 309.6 618.4 288 592 288zM496 432c-17.62 0-32-14.38-32-32s14.38-32 32-32s32 14.38 32 32S513.6 432 496
                             432zM528 288h-64V208c0-17.62 14.38-32 32-32s32 14.38 32 32V288zM224 256c70.7 0 128-57.31 128-128S294.7 0 224 0C153.3 0 96 57.31 96 128S153.3 256
                             224 256zM320 336c0-8.672 1.738-16.87 4.303-24.7C308.6 306.6 291.9 304 274.7 304H173.3C77.61 304 0 381.7 0 477.4C0 496.5 15.52 512 34.66
                              512h301.7C326.3 498.6 320 482.1 320 464V336z"/></svg>
                        <svg class="cms_user_icons" @click="deleteUser(user.id)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path d="M160 400C160 408.8 152.8 416 144 416C135.2 416 128 408.8 128 400V192C128 183.2 135.2 176 144 176C152.8 176
                            160 183.2 160 192V400zM240 400C240 408.8 232.8 416 224 416C215.2 416 208 408.8 208 400V192C208 183.2 215.2 176 224 176C232.8 176 240 183.2
                            240 192V400zM320 400C320 408.8 312.8 416 304 416C295.2 416 288 408.8 288 400V192C288 183.2 295.2 176 304 176C312.8 176 320 183.2 320
                            192V400zM317.5 24.94L354.2 80H424C437.3 80 448 90.75 448 104C448 117.3 437.3 128 424 128H416V432C416 476.2 380.2 512 336 512H112C67.82 512 32
                            476.2 32 432V128H24C10.75 128 0 117.3 0 104C0 90.75 10.75 80 24 80H93.82L130.5 24.94C140.9 9.357 158.4 0 177.1 0H270.9C289.6 0 307.1 9.358
                             317.5 24.94H317.5zM151.5 80H296.5L277.5 51.56C276 49.34 273.5 48 270.9 48H177.1C174.5 48 171.1 49.34 170.5 51.56L151.5 80zM80 432C80 449.7
                             94.33 464 112 464H336C353.7 464 368 449.7 368 432V128H80V432z"/></svg>
                    </td>
                </tr>
            </tbody>
        </table>

        <NewUserPasswordModal :user-id="userId" :modal="newUserPasswordModal"/>
        <DeleteUserModal :user="userToDelete" :modal="deleteUserModal" @refresh="getAllUsers"/>
    </div>
</template>

<script>
import {Modal} from "bootstrap";
import DeleteUserModal from "./Modals/DeleteUserModal";
import NewUserPasswordModal from "./Modals/NewUserPasswordModal";
import {errorMessage, successMessage} from "../../services/messages";
import moment from "moment";

export default {
    name: "CmsUsers",
    components: {
        DeleteUserModal,
        NewUserPasswordModal,
    },
    data() {
        return {
            userToDelete: [],
            userId: null,
            newUserPasswordModal: null,
            deleteUserModal: null,
            userType: "business",
            users: [],
            filter: "id",
            sort: false,
            filters: [
                'id',
                'name',
                'phone',
                'email',
                'location',
                'business_name',
                'english_level',
                'coupon',
                'jobs',
                'money_spent',
                'created_at'
            ],
            tabs: [
                'ID',
                'Name',
                'Phone',
                'Email',
                'Location',
                'Business name',
                'English level',
                'Coupon',
                'Jobs',
                'Money spent',
                'Registered'
            ],
        };
    },
    mounted() {
        this.newUserPasswordModal = new Modal(document.getElementById("newUserPasswordModal"));
        this.deleteUserModal = new Modal(document.getElementById("deleteUserModal"));
        this.getAllUsers();
    },
    methods: {
        async getAllUsers() {
            let response = await this.$http.get(`${this.$http.apiUrl()}cms/get-all-users-with-payments`);
            this.users = response.data.data;
            this.users.forEach(user => {
                let total = 0;
                if(user.type_user === 'business') {
                    user.payment_history.forEach(payment => {
                        if (payment.amount) {
                            total += payment.amount;
                        }
                    });
                    user.jobs = user.image_jobs.length;
                }
                else {
                    user.jobs = 0;
                }
                user.money_spent = total;
            });
        },
        changeUserType(userType) {
            this.userType = userType;
        },
        async updateUserInfo(id) {
            let userName = document.getElementById("userName" + id).value;
            let userPhone = document.getElementById("userPhone" + id).value;
            let userEmail = document.getElementById("userEmail" + id).value;
            let userLocation = document.getElementById("userLocation" + id).value;
            let userBusinessName = document.getElementById("userBusinessName" + id).value;
            let userEnglishLevel = '';
            if (this.userType === 'editor') {
                userEnglishLevel = document.getElementById("userEnglishLevel" + id).value;
            }
            let userCoupon = '';
            if (this.userType === 'business') {
                userCoupon = document.getElementById("userCoupon" + id).value;
            }
            try {
                let response = await this.$http.post(`${this.$http.apiUrl()}cms/update-user-details`, {
                    'id': id,
                    'name': userName,
                    'phone': userPhone,
                    'email': userEmail,
                    'location': userLocation,
                    'business_name': userBusinessName,
                    'english_level': userEnglishLevel,
                    'coupon': userCoupon,
                });
                if(response.data.success) {
                    successMessage("User data successfully updated!")
                }
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
        },
        openNewUserPasswordModal(id) {
            this.userId = id;
            this.newUserPasswordModal.show();
        },
        async deleteUser(id) {
            let response = await this.$http.get(`${this.$http.apiUrl()}cms/get-user/` + id);
            this.userToDelete = response.data.data;
            this.deleteUserModal.show();
        },
        setFilter(filter) {
            let _ = require('lodash');

            if (this.filter === filter) {
                this.sort = !this.sort;
            }
            else {
                this.sort = false;
            }
            if (this.sort) {
                this.users = _.sortBy(this.users, filter).reverse();
            } else {
                this.users = _.sortBy(this.users, filter);
            }
            this.filter = filter;
        },
        createdAt(date) {
            return moment(date).format('YYYY-MM-DD');
        },
    }
}
</script>

<style lang="scss" scoped>
.table_body {
    font-size: 14px;
}
.table_body input {
    width: 100%;
}
.cms_user_icons {
    width: 20px;
    margin: 5px;
    fill: #674837;
    cursor: pointer;
}
.cms_user_type_display {
    font-weight: bold;
    margin-left: -12px;
    color: #696969;
}
.cms_user_type_display:hover {
    color: #E27582;
}
.sort_arrows {
    width: 15px;
    margin-left: 10px;
    .sort_arrows_up {
        fill: lightgray;
        transform: scaleY(-1);
        width: 15px;
        margin-top: -15px;
    }
    .sort_arrows_down {
        fill: lightgray;
        width: 15px;
        margin-top: -40px;
    }
    .active {
        background-color: white;
        fill: #E27582;
    }
}
.active {
    color: #E27582;
}
</style>
