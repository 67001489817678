<template>
    <div class="scm_header">
        <router-link class="navbar-brand logo" :to="{ name: 'About' }">
            <img class="logo_project" :src="this.getLogo" alt="strobeart"/>
        </router-link>
        <div class="btn_logout desc_st" @click="logOut">
            Log out
        </div>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
    name: "CmsHeader",
    props: ['errorMessage', 'successMessage'],
    data() {
        return {
            user: {},
            showDropdown: false,
        }
    },
    mounted() {
        this.user = JSON.parse(localStorage.getItem('strobeart_user'));
        if(!this.getStyles) {
            this.loadSiteSettings();
        }
    },
    computed: {
        ...mapGetters([
            'getStyles',
            'getLogo',
            'getDefaultColor',
            'getDefaultFont',
            'getInstagramLink',
            'getFacebookLink',
            'getTiktokLink',
        ]),
    },
    methods: {
        ...mapMutations([
            'setUpdateUserProfile',
            'setLogo',
            'setDefaultColor',
            'setDefaultFont',
            'setInstagramLink',
            'setFacebookLink',
            'setTiktokLink',
            'setStyles',
        ]),
        logOut() {
            delete localStorage.strobeart_user;
            delete localStorage.strobeart_jwt;
            delete localStorage.logo;
            delete localStorage.defaultColor;
            delete localStorage.defaultFont;
            delete localStorage.instagramLink;
            delete localStorage.facebookLink;
            delete localStorage.tiktokLink;
            this.$router.push({to: '/about', name: "About"}).then();
        },
        async loadSiteSettings() {
            if(!localStorage.getItem('logo')) {
                const response = await this.$http.get(`${this.$http.apiUrl()}get-site-settings`);
                if (response.data.data) {
                    localStorage.setItem('logo', response.data.data.style.path_to_logo);
                    localStorage.setItem('defaultColor', response.data.data.style.main_color);
                    localStorage.setItem('defaultFont', response.data.data.style.font_family);
                    localStorage.setItem('instagramLink', response.data.data.links.instagram);
                    localStorage.setItem('facebookLink', response.data.data.links.facebook);
                    localStorage.setItem('tiktokLink', response.data.data.links.tiktok);
                    this.setLogo(response.data.data.style.path_to_logo);
                    this.setDefaultColor(response.data.data.style.main_color);
                    this.setDefaultFont(response.data.data.style.font_family);
                    this.setInstagramLink(response.data.data.links.instagram);
                    this.setFacebookLink(response.data.data.links.facebook);
                    this.setTiktokLink(response.data.data.links.tiktok);
                    this.setStyles(true);
                }
            }
            else {
                this.setLogo(localStorage.getItem('logo'));
                this.setDefaultColor(localStorage.getItem('defaultColor'));
                this.setDefaultFont(localStorage.getItem('defaultFont'));
                this.setInstagramLink(localStorage.getItem('instagramLink'));
                this.setFacebookLink(localStorage.getItem('facebookLink'));
                this.setTiktokLink(localStorage.getItem('tiktokLink'));
                this.setStyles(true);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
$animation-show-time: .5s;

.slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(-10px);
    opacity: 0;
}
.scm_header {
    padding: 15px;
    display: flex;
    font-size: 45px;
    .logo_project {
        margin-left: 100px;
        font-style: normal;
        width: 160px;
        font-weight: 600;
        font-size: 24px;
        line-height: 44px;
        color: black;
    }
    .btn_logout {
        text-decoration: none;
        cursor: pointer;
        margin: 10px 100px auto auto;
        padding: 11px 38px;
        background: #FCF5EF;
        border: 0.3px solid #a2a4a8;
        border-radius: 50px;
        height: 50px;
        color: #494949;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    }
}
</style>
