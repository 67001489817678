<template>
    <div class="fc-view-container">
        <table class="main-table">
            <thead class="fc-day-header-line">
            <tr>
                <th class="fc-day-header"/>
                <th v-for="day in calendar[Object.keys(calendar)[0]]" class="fc-day-header">{{getDayNameNumber(day.full_date)}}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(time, time_name) in calendar">
                <td  class="main-table-td main-table-side">{{time_name}}</td>
                <td v-for="day in time"
                    :data-date="day.full_date"
                    class="main-table-td"
                    :class="{'fc-other-month':day.month != 'current', 'fc-past':day.timeline == 'past', 'fc-future':day.timeline == 'future', 'fc-today fc-state-highlight':day.timeline == 'today'}">
                    <table class="secondary-table">
                        <tr v-for="post in day.posts">
                            <td :data-date="day.full_date">
                                <div class="post" :class="{post_instagram: post.platform == 'instagram', post_shopify: post.platform == 'shopify'}" @click="postPreview(post.id)">
                                    <img class="post__image" :src="post.publishing_file.image_job.finished_worked_images.filter(x => post.publishing_file.sequence_pictures.includes(x.id))[0].image_url">
                                    <div>
                                        <div class="post__time">
                                            {{getTime(post.available_at)}}
                                        </div>
                                        <div class="post__description">{{post.publishing_file.description != null &&
                                            post.publishing_file.description.length > post_description_length ?
                                            post.publishing_file.description.substring(0, post_description_length) + '...' :
                                            post.publishing_file.description}}
                                        </div>
                                    </div>
                                    <div>
                                        <img v-if="post.platform == 'shopify'" class="post__shop-icon" src="@/assets/icons/Shopify_icon.svg">
                                        <img v-if="post.platform == 'instagram'" class="post__shop-icon" src="@/assets/icons/Instagram_icon.svg">
                                        <img v-if="post.platform == 'amazon'" class="post__shop-icon" src="@/assets/icons/Amazon_icon.png">
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: "WeekGrid",
    props: ['calendar'],
    data() {
        return {
            post_description_length: 10,
        }
    },
    methods: {
        getTime: function (time) {
            return moment(time).local().format('LT');
        },
        getDayNameNumber: function (time) {
            return moment(time).local().format('ddd M/D');
        },
        postPreview(id) {
            this.$emit('postPreview', id);
        }
    }
}
</script>

<style lang="scss" scoped>
.fc-view-container {
    margin-top: 10px;
}
.main-table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    &-td {
        border: 0.5px solid rgba(73, 73, 73, 0.25) !important;
        vertical-align: top;

        min-height: 140px !important;
        height: 80px !important;
    }
    &-side {
        padding-left: 10px;
        vertical-align: middle !important;
    }
    .fc-day-number {
        text-align: right;
        padding: 4px 6px 2px 2px;
        vertical-align: top;
    }
    .fc-day-header {
        text-align: right;
        padding: 10px 10px 10px 0;
        text-transform: uppercase;
        color: rgba(73, 73, 73, 0.6);
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;

        border-right: 0.5px solid white !important;
        border-left: 0.5px solid white !important;

        &-line {
            border-bottom: 1px solid black !important;
            position: -webkit-sticky;
            position: sticky;
            top: 149px;
            background: white;
        }
    }
    .fc-other-month {
        opacity: 0.3;
    }
    .fc-state-highlight {
        background: #FBF5F1;
    }
}
.secondary-table {
    width: 100%;
    text-align: center;
    min-height: 56px;

    tr {
        width: 100%;
    }
    .post {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        border-radius: 5px;
        background: #F8EDE6;

        margin: 2px 6px;
        cursor: pointer;
        padding: 8px;
        max-width: 100%;

        &_instagram {
            background: #C9ABB0;

            .post__time, .post__description {
                color: #FDFDFD;
            }
        }
        &_shopify {
            background: #9FB399;

            .post__time, .post__description {
                color: #FDFDFD;
            }
        }
        &__image {
            max-width: 32px;
            max-height: 32px;
        }
        &__description {
            overflow-wrap: anywhere;
            text-align: left;
            font-size: 12px;
            line-height: 15px;
            color: #494949;
        }
        &__time {
            font-size: 12px;
            line-height: 15px;
            color: #494949;
        }
        &__shop-icon {
            height: 20px;
            width: 20px;
        }
    }
}

@media only screen and (max-width: 992px) {
    .fc-view-container {
        margin-top: 0 !important;
        margin-right: -12px;
        margin-left: -12px;
    }
    .fc-day-header-line {
        top: 120px !important;
    }
    .main-table {
        margin-bottom: 70px;

        &-td {
            min-height: 72px !important;
            height: 72px !important;
        }
    }
    .post {
        display: flex;
        flex-direction: column-reverse !important;
        margin-left: auto !important;
        margin-right: auto !important;

        &__image {
            display: none;
        }
        &__description {
            display: none;
        }
    }
    .main-table-side {
        padding: 0;
        font-size: 12px;
        text-align: center;
    }
}
</style>
