<template>
    <div class="dt_row">
        <div class="mt-4 info_box">
            <div class="job_info_item">
                <div class="title_2">Editing Level:</div>
                <div class="desc2 title_3 ml-24">{{ job.editing_level }}</div>
            </div>
            <div class="job_info_item">
                <div class="title_2">Style Guide:</div>
                <div class="desc2 title_3 ml-24">{{ job.style_guide? job.style_guide:'No' }}</div>
            </div>
            <div class="job_info_item">
                <div class="title_2">Add Logo:</div>
                <div class="desc2 title_3 ml-24">{{ job.add_logo ? 'Yes' : 'No' }}</div>
            </div>
            <div class="job_info_item">
                <div class="title_2">Background:</div>
                <div class="desc2 title_3 ml-24">{{ job.white_background ? 'White' : 'No' }}</div>
            </div>
            <div class="job_info_item">
                <div class="title_2">Resolution:</div>
                <div class="desc2 title_3 ml-24">{{ job.resolution }} {{ job.resolution_units }}</div>
            </div>
            <div class="job_info_item">
                <div class="title_2">Color Profile:</div>
                <div class="desc2 title_3 ml-24">{{ job.color_profile }}</div>
            </div>
            <div class="job_info_item">
                <div class="title_2">Watermark:</div>
                <div class="desc2 title_3 ml-24">{{ job.add_watermark ? 'Yes' : 'No' }}</div>
            </div>
            <div class="job_info_item">
                <div class="title_2">Video Instructions:</div>
                <div class="desc2 title_3 ml-24">{{ job.file_video_instruction_id ? 'Yes' : 'No' }}</div>
            </div>
            <div class="job_info_item">
                <div class="title_2">Color palette:</div>
                <div class="desc2 title_3 ml-24">{{ job.color_palette ? 'Yes' : 'No' }}</div>
            </div>
            <div class="job_info_item">
                <div class="title_2">Typography:</div>
                <div class="desc2 title_3 ml-24">{{ job.typography ? 'Yes' : 'No' }}</div>
            </div>
            <div class="job_info_item">
                <div class="title_2">Red eye:</div>
                <div class="desc2 title_3 ml-24">{{ job.red_eye ? 'Yes' : 'No' }}</div>
            </div>
            <div class="job_info_item">
                <div class="title_2">File Format:</div>
                <div class="desc2 title_3 ml-24">{{ job.file_format }}</div>
            </div>
            <div class="job_info_item" v-if="job.sizes">
                <div class="title_2">Size:</div>
                <div class="desc2 title_3 ml-24">
                    <span v-for="size in job.sizes.split(';')">{{ size }} <br></span>
                </div>
            </div>
            <div class="job_info_item">
                <div class="title_2">Other:</div>
                <div class="desc2 title_3 ml-24">{{ job.other ? job.other : 'None' }}</div>
            </div>
            <div class="job_info_item">
                <div class="title_2">Invisible mannequin:</div>
                <div class="desc2 title_3 ml-24">{{ job.invisible_mannequin  ? 'Yes' : 'No'  }}</div>
            </div>
            <div class="job_info_item">
                <div class="title_2">Color matching:</div>
                <div class="desc2 title_3 ml-24">{{ job.color_matching  ? 'Yes' : 'No'  }}</div>
            </div>
            <div class="job_info_item">
                <div class="title_2">Creative color grading:</div>
                <div class="desc2 title_3 ml-24">{{ job.color_grading  ? 'Yes' : 'No'  }}</div>
            </div>
            <div class="job_info_item">
                <div class="title_2">Background removal:</div>
                <div class="desc2 title_3 ml-24">{{ job.background_removal  ? 'Yes' : 'No'  }}</div>
            </div>
            <div class="job_info_item">
                <div class="title_2">Logo & watermarks:</div>
                <div class="desc2 title_3 ml-24">{{ job.logo_watermarks ? 'Yes' : 'No'  }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Requirements',
    props: {
        job: {
            default: {}
        }
    },
}
</script>

<style lang="scss" scoped>
.title_2 {
    width: 200px;
    text-align: right;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 40px;
    color: black;
}
.desc2 {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: black;
}
.job_info_item {
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 992px) {
    .dt_row {
        display: flex;
        justify-content: center;
    }
    .info_box {
        width: 100%;
    }
    .title_2 {
        font-style: normal;
        font-weight: 500;
        font-size: 12.5px;
        line-height: 25px;
        color: #494949;
        text-align: start;
    }
    .title_3 {
        font-style: normal;
        font-weight: 400;
        font-size: 12.5px;
        line-height: 25px;
        color: #494949;
    }
    .desc2 {
        font-style: normal;
        font-weight: 500;
        font-size: 12.5px;
        line-height: 20px;
        color: black;
    }
    .job_info_item {
        width: 100%;
        max-width: 400px;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
    }
    .ml-24 {
        margin-left: 64px;
    }
}
</style>
