<template>
    <div>
        <div class="d-flex" style="margin-top: 25px;">
            <div class="dropdown d-flex" style="padding: 0 10px;">
                <span class="filter_name">User:</span>
                <div class="dropdown">
                    <select class="form-select" style="width: auto;" v-model="filterUser" @change="setFilter()">
                        <option value="all">All users</option>
                        <option v-for="user in users" :value="user.id">{{ user.name }} (id: {{user.id}})</option>
                    </select>
                </div>
            </div>
            <div class="dropdown d-flex" style="padding: 0 10px;">
                <span class="filter_name">Event:</span>
                <select class="form-select" style="width: auto;" v-model="filterEvent" @change="setFilter()">
                    <option value="all">All events</option>
                    <option value="error">Error</option>
                    <option value="created">Created</option>
                    <option value="updated">Updated</option>
                    <option value="deleted">Deleted</option>
                </select>
            </div>
            <div class="dropdown d-flex" style="padding: 0 10px;">
                <span class="filter_name">Model:</span>
                <select class="form-select" style="width: auto;" v-model="filterModel" @change="setFilter()">
                    <option value="all">All models</option>
                    <option v-for="model in models" :value="model">{{ model }}</option>
                </select>
            </div>
            <div class="dropdown d-flex" style="padding: 0 10px;">
                <span class="filter_name">Model ID:</span>
                <select class="form-select" style="width: auto;" v-model="filterModelID" @change="setFilter()">
                    <option value="all">All model IDs</option>
                    <option v-for="modelID in modelIDs" :value="modelID">{{ modelID }}</option>
                </select>
            </div>
        </div>
        <table class="table" style="margin-top: 15px;">
            <thead class="thead-light">
                <tr>
                    <th v-for="(value, index) in filters" @click="setFilter(value)">
                        <div class="d-flex">
                            {{ tabs[index] }}
                            <div class="d-block sort_arrows">
                                <svg class="sort_arrows_up" :class="{active: sort && filter === value}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                    <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/></svg>
                                <svg class="sort_arrows_down" :class="{active: !sort && filter === value}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                    <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/></svg>
                            </div>
                        </div>
                    </th>
                    <th style="vertical-align: baseline;">
                        Old Values
                    </th>
                    <th style="vertical-align: baseline;">
                        New Values
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="table_body" v-for="audit in displayedAudits">
                    <td>{{ getFormatedDate(audit.created_at) }}</td>
                    <td>{{ user_names[audit.user_id] }}</td>
                    <td>{{ audit.event }}</td>
                    <td>{{ audit.auditable_type }}</td>
                    <td>{{ audit.auditable_id }}</td>
                    <td v-if="audit.event === 'error'">
                        {{ String(audit.old_values).substring(0, 50) }}
                    </td>
                    <td v-else>
                        <template v-for="(value, key) in audit.old_values" v-if="value != null">
                            {{ key }}: {{ String(value).substring(0, 40) }}<br/>
                        </template>
                    </td>
                    <td v-if="audit.event === 'error'">
                        {{ String(audit.new_values).substring(0, 50) }}
                    </td>
                    <td v-else>
                        <template v-for="(value, key) in audit.new_values" v-if="value != null">
                            {{ key }}: {{ String(value).substring(0, 40) }}<br/>
                        </template>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: "CmsAudits",
    data() {
        return {
            audits: [],
            displayedAudits: [],
            users: [],
            user_names: {},
            models: [],
            modelIDs: [],
            filterUser: 'all',
            filterEvent: 'all',
            filterModel: 'all',
            filterModelID: 'all',
            filter: "created_at",
            sort: true,
            filters: [
                'created_at',
                'user_id',
                'event',
                'auditable_type',
                'auditable_id',
            ],
            tabs: [
                'Date',
                'User',
                'Event',
                'Model',
                'Model ID',
            ],
        }
    },
    mounted() {
        this.getAudits();
        this.getAllUsers();
    },
    methods: {
        async getAllUsers() {
            let response = await this.$http.get(`${this.$http.apiUrl()}cms/get-all-users`);
            this.users = response.data.data;
            this.users.forEach(user => {
                this.user_names[user.id] = user.name;
            });
        },
        async getAudits() {
            let response = await this.$http.get(`${this.$http.apiUrl()}cms/get-audits`);
            this.audits = response.data.data;
            this.displayedAudits = this.audits;
            this.audits.forEach(audit => {
                if(!this.models.includes(audit.auditable_type)) {
                    this.models.push(audit.auditable_type);
                }
                if(!this.modelIDs.includes(audit.auditable_id)) {
                    this.modelIDs.push(audit.auditable_id);
                }

            });
        },
        getFormatedDate(date) {
            return moment(date).format('YYYY-MM-DD HH:mm:ss');
        },
        setFilter(filter = null) {
            let _ = require('lodash');

            if (!filter) {
                filter = this.filter;
            } else {
                if (this.filter === filter) {
                    this.sort = !this.sort;
                } else {
                    this.sort = false;
                }
            }
            if (this.sort) {
                this.audits = _.sortBy(this.audits, filter).reverse();
            } else {
                this.audits = _.sortBy(this.audits, filter);
            }
            this.filter = filter;

            this.displayedAudits = this.audits.filter(item =>
                (this.filterEvent === "all" || item.event === this.filterEvent) &&
                (this.filterModel === "all" || item.auditable_type === this.filterModel) &&
                (this.filterModelID === "all" || item.auditable_id === this.filterModelID) &&
                (this.filterUser === "all" || item.user_id === parseInt(this.filterUser))
            );
        },
    }
}
</script>

<style lang="scss" scoped>
.table_body {
    font-size: 14px;
}
.filter_name {
    padding: 10px;
}
.sort_arrows {
    width: 15px;
    margin-left: 10px;
    .sort_arrows_up {
        fill: lightgray;
        transform: scaleY(-1);
        width: 15px;
        margin-top: -15px;
    }
    .sort_arrows_down {
        fill: lightgray;
        width: 15px;
        margin-top: -40px;
    }
    .active {
        background-color: white;
        fill: #E27582;
    }
}
.active {
    color: #E27582;
}
</style>
