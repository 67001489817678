<template>
    <div v-if="page_loaded" class="container body_active" style="margin-top: 25px;">
        <div class="row radio-grid-container with_underline">
            <div class="col-12 col-lg-4 date-changer-container text-lg-start text-center mt-lg-0">
                <span>GRID</span>
                <span class="date-changer date-changer_prev" @click="decCurrentTime">
                    <img src="@/assets/icons/direction-left.svg">
                </span>
                <span class="date-changer date-changer_next" @click="incCurrentTime">
                    <img src="@/assets/icons/direction-right.svg">
                </span>
                <span>{{ getCurrentTime().toUpperCase() }}</span>
            </div>
            <div class="col-12 col-lg-4 text-lg-center" style="margin-top: -5px;">
                <div class="dropdown">
                    <select class="form-select" v-model="user_id" @change="getCalendar">
                        <option :value="user.id" v-for="user in users" v-if="user.type_user === 'business'">{{ user.name }} (id: {{user.id}})</option>
                    </select>
                </div>
            </div>
            <div class="col-12 col-lg-4 text-lg-end text-center mt-lg-0">
                <input type="radio" class="radio-grid-button" id="one" value="Month" v-model="grid"/>
                <label for="one" class="radio-grid-selector" :class="{'active':grid==='Month'}">MONTH</label>
                <input type="radio" class="radio-grid-button" id="two" value="Week" v-model="grid"/>
                <label for="two" class="radio-grid-selector" :class="{'active':grid==='Week'}">WEEK</label>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <MonthGrid v-if="grid==='Month'" :calendar="calendar" @postPreview="postPreview"/>
                <WeekGrid v-if="grid==='Week'" :calendar="calendar" @postPreview="postPreview"/>
            </div>
        </div>

        <AmazonPost ref="amazon_post" @deletePost="deletePost" @getCalendar="getCalendar" :user_id="user_id"/>
        <ShopifyPost ref="shopify_post" @deletePost="deletePost" @getCalendar="getCalendar" :user_id="user_id"/>
        <InstagramPost ref="instagram_post" @deletePost="deletePost" @getCalendar="getCalendar" :user_id="user_id"/>
        <DeletePost ref="delete_post" :id="publish_model.schedule_id" @postDeleted="postDeleted"/>
    </div>
</template>

<script>
import MonthGrid from "../PostsGrid/MonthGrid";
import WeekGrid from "../PostsGrid/WeekGrid";
import AmazonPost from "../PostsGrid/AmazonPost";
import ShopifyPost from "../PostsGrid/ShopifyPost";
import InstagramPost from "../PostsGrid/InstagramPost";
import DeletePost from "../PostsGrid/DeletePost";
import moment from "moment";
import {errorMessage} from "../../services/messages";
import {mapMutations} from "vuex";
import helpFunctions from "../../helpFunctions";
import ('@/css/carousel.css');

export default {
    name: "CmsPostCalendar",
    components: {
        MonthGrid,
        WeekGrid,
        AmazonPost,
        ShopifyPost,
        InstagramPost,
        DeletePost
    },
    data() {
        return {
            users: [],
            calendar: {},
            currentStartDate: '',
            grid: 'Month',
            user_id: null,
            page_loaded: false,
            post: {},
            publish_model: {}
        }
    },
    async mounted() {
        this.publish_model = helpFunctions.publish_model;
        this.currentStartDate = moment().format();
        await this.getAllUsers();
        await this.getCalendar();
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
            'setUser',
        ]),
        async getAllUsers() {
            let response = await this.$http.get(`${this.$http.apiUrl()}cms/get-all-users`);
            this.users = response.data.data;
            this.user_id = this.users[0].id;
        },
        async getCalendar() {
            this.showLoader();
            const result = await this.$http.get(`${this.$http.apiUrl()}cms/get-calendar`, {
                id: this.user_id,
                type: this.grid,
                timezone: moment.tz.guess(),
                date: moment(this.currentStartDate).format('YYYY-MM-DD')
            });
            if (!result?.data?.success) {
                this.hideLoader();
                return errorMessage('OOPS... Something went wrong...');
            }
            this.calendar = result?.data?.data;
            this.page_loaded = true;
            this.hideLoader();
        },
        getCurrentTime() {
            return this.grid === 'Month' ? moment.utc(this.currentStartDate).local().format('MMMM YYYY') :
                moment.utc(this.currentStartDate).local().startOf('week').format('MMM DD') + ' — '
                + moment.utc(this.currentStartDate).local().endOf('week').format('MMM DD,  YYYY');
        },
        async decCurrentTime() {
            this.currentStartDate = moment.utc(this.currentStartDate).local().subtract(1, this.grid).startOf(this.grid);
            await this.getCalendar();
        },
        async incCurrentTime() {
            this.currentStartDate = moment.utc(this.currentStartDate).local().add(1, this.grid).startOf(this.grid);
            await this.getCalendar();
        },
        postPreview(id) {
            if (this.publish_model === null || this.publish_model.id !== id) {
                this.getPost(id);
            }
            else if (this.publish_model.platform === 'instagram') {
                this.$refs.instagram_post.load();
            }
            else if (this.publish_model.platform === 'shopify') {
                this.$refs.shopify_post.load();
            }
            else if(this.publish_model.platform === 'amazon') {
                this.$refs.amazon_post.load();
            }
        },
        async getPost(id) {
            this.showLoader();
            let result = await this.$http.getAuth(`${this.$http.apiUrl()}schedule/` + id);
            if (!result?.data?.success) {
                return errorMessage('OOPS... Something went wrong...');
            }
            else {
                this.post = result?.data?.data;
                this.publish_model = this.post.publishing_file;
                if (this.publish_model.platform === 'instagram') {
                    this.$refs.instagram_post.publish_model = this.publish_model;
                    await this.$refs.instagram_post.load();
                }
                if (this.publish_model.platform === 'shopify') {
                    this.$refs.shopify_post.publish_model = this.publish_model;
                    await this.$refs.shopify_post.load();
                }
                if (this.publish_model.platform === 'amazon') {
                    this.$refs.amazon_post.publish_model = this.publish_model;
                    await this.$refs.amazon_post.load();
                }
            }
            this.hideLoader();
        },
        deletePost() {
            this.$refs.delete_post.load();
        },
        postDeleted() {
            if (this.publish_model.platform === 'instagram') {
                this.$refs.instagram_post.postDeleted();
            }
            else if (this.publish_model.platform === 'shopify') {
                this.$refs.shopify_post.postDeleted();
            }
            else if(this.publish_model.platform === 'amazon') {
                this.$refs.amazon_post.postDeleted();
            }
            this.getCalendar();
        },
    },
    watch: {
        async grid() {
            await this.getCalendar();
        }
    }
}
</script>

<style>
.fc-day-header-line {
    position: inherit !important;
}
</style>
<style  lang="scss" scoped>
.date-changer {
    max-height: 26px;
    cursor: pointer;
    opacity: 0.6;

    &-container {
        font-weight: 700;
    }
    &_prev {
    }

    &_next {
    }
    &:hover {
        opacity: 1;
    }
}
.radio-grid {
    &-container {
         top: 93px;
         background: white;
         padding-bottom: 20px;
         z-index: 100;

         color: #494949;
         font-size: 16px !important;
         line-height: 20px;
    }
    &-selector {
         margin: 0 10px;
         padding-bottom: 16px;
         cursor: pointer;
         font-size: 14px;
         line-height: 17px;
         font-weight: 600;

        &.active {
            box-shadow: 0 -3px 0 black inset !important;
        }
    }
    &-button {
        display: none;
    }
}
.with_underline {
    border-bottom: 0.5px solid rgba(73, 73, 73, 0.25);
}

@media only screen and (max-width: 992px) {
    .radio-grid-container {
        top: 0;
    }
}
</style>
