<template>
    <div style="margin-left: 25px;">
        <div style="margin-top: 25px;">
            <input class="form-control w-25" style="margin-left: 25px;" v-model="instagram_link">
        </div>
        <div style="margin-top: 25px;">
            <input class="form-control w-25" style="margin-left: 25px;" v-model="facebook_link">
        </div>
        <div style="margin-top: 25px;">
            <input class="form-control w-25" style="margin-left: 25px;" v-model="tiktok_link">
        </div>
        <div style="margin-top: 25px; margin-bottom: 25px;">
            <button type="button" class="btn_post_n" @click="saveChanges()">Save changes!</button>
        </div>
    </div>
</template>

<script>
import {mapMutations} from "vuex";
import {errorMessage, successMessage} from "../../services/messages";

export default {
    name: "CmsSocialNetworks",
    data() {
        return {
            instagram_link: 'https://www.instagram.com/drskinfeed/?hl=en',
            facebook_link: 'https://www.facebook.com/drskinfeed/',
            tiktok_link: 'https://www.tiktok.com/@dr.skinfeed',
        };
    },
    mounted() {
        this.getCurrentLinks();
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
            'setInstagramLink',
            'setFacebookLink',
            'setTiktokLink'
        ]),
        async getCurrentLinks() {
            let response = await this.$http.get(`${this.$http.apiUrl()}cms/get-current-links`);
            this.instagram_link = response.data.data.instagram;
            this.facebook_link = response.data.data.facebook;
            this.tiktok_link = response.data.data.tiktok;
        },
        async saveChanges() {
            try {
                let response = await this.$http.post(`${this.$http.apiUrl()}cms/update-links`, {
                    'instagram': this.instagram_link,
                    'facebook': this.facebook_link,
                    'tiktok': this.tiktok_link,
                });
                if(response.data.success) {
                    successMessage('Social network links updated');
                    localStorage.setItem('instagramLink', this.instagram_link);
                    localStorage.setItem('facebookLink', this.facebook_link);
                    localStorage.setItem('tiktokLink', this.tiktok_link);
                    this.setInstagramLink(this.instagram_link);
                    this.setFacebookLink(this.facebook_link);
                    this.setTiktokLink(this.tiktok_link);
                }
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
        }
    }
}
</script>
