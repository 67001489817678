<template>
    <div>
        <div class="d-flex" style="margin-top: 25px;">
            <div class="dropdown d-flex" style="padding: 0 10px;">
                <span class="filter_name">User:</span>
                <div class="dropdown">
                    <select class="form-select" style="width: auto;" v-model="filterUser" @change="setFilter()">
                        <option value="all">All users</option>
                        <option v-for="user in users" :value="user.id" v-if="user.type_user === 'business'">{{ user.name }} (id: {{user.id}})</option>
                    </select>
                </div>
            </div>
        </div>
        <table class="table" style="margin-top: 15px;">
            <thead class="thead-light">
                <tr>
                    <th v-for="(value, index) in filters" @click="setFilter(value)">
                        <div class="d-flex">
                            {{ tabs[index] }}
                            <div class="d-block sort_arrows">
                                <svg class="sort_arrows_up" :class="{active: sort && filter === value}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                    <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/></svg>
                                <svg class="sort_arrows_down" :class="{active: !sort && filter === value}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                    <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/></svg>
                            </div>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="table_body" v-for="payment in displayedPayments">
                    <td>{{ payment.id }}</td>
                    <td :style="'color: ' + getDefaultColor + '; cursor: pointer;'" @click="userDetails(payment.user_id)">{{ user_names[payment.user_id] }}</td>
                    <td :style="'color: ' + getDefaultColor + '; cursor: pointer;'" @click="userDetails(payment.client_id)">{{ user_names[payment.client_id] }}</td>
                    <td class="d-flex">
                        <img class="payments_image" @click="jobDetails(payment.job_image_id)" :src="payment.img_src">
                        <div class="more_image" @click="jobDetails(payment.job_image_id)" v-if="payment.count_src">+{{payment.count_src - 1}}</div>
                    </td>
                    <td>{{ "$" + (payment.amount/100).toFixed(2) }}</td>
                    <td>{{ "$" + (payment.amount_editor/100).toFixed(2) }}</td>
                    <td>{{ payment.type_payment.replaceAll('_', ' ') }}</td>
                    <td v-if="payment.status === 'succeeded'" class="success">{{ payment.status }}</td>
                    <td v-else class="error">{{ payment.status }}</td>
                </tr>
            </tbody>
        </table>
        <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center">
                <li class="page-item"><a class="page-link pagination" style="cursor: pointer" @click="updatePaymentsTable(currentPage - 1)" v-bind:class="{disabled: currentPage === 1}">Previous</a></li>
                <li v-for="index in lastPage" class="page-item" :class="{invisible: index - 5 > currentPage || index + 5 < currentPage}" style="cursor: pointer"><a :id="'pagination' + index" class="page-link pagination" @click="updatePaymentsTable(index)" v-bind:class="{active: (currentPage === index)}">{{index}}</a></li>
                <li class="page-item"><a class="page-link pagination" style="cursor: pointer" @click="updatePaymentsTable(currentPage + 1)" v-bind:class="{disabled: currentPage === lastPage}">Next</a></li>
            </ul>
        </nav>
        <UserDetailsModal ref="userDetailsRef"/>
        <JobDetailsModal ref="jobDetailsRef"/>
    </div>
</template>

<script>
import UserDetailsModal from "./Modals/UserDetailsModal";
import JobDetailsModal from "./Modals/JobDetailsModal";
import {Modal} from "bootstrap";
import {mapGetters} from "vuex";

export default {
    name: "CmsPayments",
    components: {UserDetailsModal, JobDetailsModal},
    data() {
        return {
            payments: [],
            filteredPayments: [],
            displayedPayments: [],
            users: [],
            user_names: {},
            userDetailsModal: null,
            jobDetailsModal: null,
            jobsPerPage: 15,
            currentPage: 1,
            lastPage: 0,
            filterUser: 'all',
            filter: "id",
            sort: false,
            filters: [
                'id',
                'clientName',
                'editorName',
                'numOfImg',
                'amount',
                'amount_editor',
                'type_payment',
                'status'
            ],
            tabs: [
                'ID',
                'Client',
                'Editor',
                'Images',
                'Amount',
                'Amount editor',
                'Product',
                'Status',
            ],
        };
    },
    mounted() {
        this.userDetailsModal = new Modal(document.getElementById('userDetails'));
        this.jobDetailsModal = new Modal(document.getElementById('jobDetails'));
        this.getAllPayments();
        this.getAllUsers();
    },
    computed: {
        ...mapGetters([
            'getDefaultColor'
        ]),
    },
    methods: {
        async getAllUsers() {
            let response = await this.$http.get(`${this.$http.apiUrl()}cms/get-all-users`);
            this.users = response.data.data;
            this.users.forEach(user => {
                this.user_names[user.id] = user.name;
            });
        },
        async getAllPayments() {
            let response = await this.$http.get(`${this.$http.apiUrl()}cms/get-all-payments`);
            this.payments = response.data.data;

            this.payments.forEach(payment => {
                if(!payment.amount) {
                    payment.amount = 0;
                }
                if(!payment.amount_editor) {
                    payment.amount_editor = 0;
                }
                payment.numOfImg = payment.img_src.length;
            });

            this.filteredPayments = this.payments;

            this.lastPage = Math.ceil(this.payments.length / this.jobsPerPage) - 1;
            this.updatePaymentsTable(1);
        },
        updatePaymentsTable(page) {
            if (page <= 0) {
                page = 1;
            }
            else if (page > this.lastPage) {
                page = this.lastPage;
            }
            this.currentPage = page;
            let index = 0;
            for (let i = page * 15 - 15; i < page * 15; i++) {
                this.displayedPayments[index] = this.filteredPayments[i];
                index++;
            }
        },
        userDetails(id) {
            this.$refs.userDetailsRef.getUserDetails(id);
            this.userDetailsModal.show();
        },
        jobDetails(id) {
            this.$refs.jobDetailsRef.getJobDetails(id);
            this.jobDetailsModal.show();
        },
        setFilter(filter = null) {
            let _ = require('lodash');

            if (!filter) {
                filter = this.filter;
            } else {
                if (this.filter === filter) {
                    this.sort = !this.sort;
                } else {
                    this.sort = false;
                }
            }
            if (this.sort) {
                this.payments = _.sortBy(this.payments, filter).reverse();
            } else {
                this.payments = _.sortBy(this.payments, filter);
            }
            this.filter = filter;

            this.filteredPayments = this.payments.filter(item =>
                this.filterUser === "all" || item.user_id === parseInt(this.filterUser)
            );

            this.updatePaymentsTable(this.currentPage);
        }
    }
}
</script>

<style lang="scss" scoped>
.filter_name {
    padding: 10px;
}
.more_image {
    height: 60px;
    width: 60px;
    margin-left: 5px;
    background-color: lightgray;
    border-radius: 4px;
    border: solid 1px gray;
    text-align: center;
    font-weight: 500;
    color: #646464;
    padding: 10px;
    font-size: 24px;
}
.sort_arrows {
    width: 15px;
    margin-left: 10px;
    .sort_arrows_up {
        fill: lightgray;
        transform: scaleY(-1);
        width: 15px;
        margin-top: -15px;
    }
    .sort_arrows_down {
        fill: lightgray;
        width: 15px;
        margin-top: -40px;
    }
    .active {
        background-color: white;
        fill: #E27582;
    }
}
.active {
    color: #E27582;
}
.success {
    color: green;
}
.error {
    color: red;
}
.payments_image {
    height: 60px;
}
.pagination {
    color: #E27582;
}
.disabled {
    color: gray;
    background: lightgrey;
}
.invisible {
    display: none;
}
</style>
