<template>
    <div>
        <div class="row m_b_14">
            <div class="col-12 text-start main_text">
                <div class="row">
                    <div class="col-lg-2 col-12 d-flex align-items-center">
                        <span>Your Active Jobs</span>
                    </div>
                    <div class="col-lg-3 mt-3 col-12">
                        <button v-if="selectedJobs.length > 0" class="ms-lg-4 auto-approve-button" @click="autoApprove()">Auto approve</button>
                    </div>
                    <div v-if="jobs.length > 0" class="col-lg-7 mt-3 col-12">
                        <multiselect v-model="selectedJobs" track-by="id" label="id" :options="jobs.filter(el => getProcentDoneJob(el) === 100)"
                                     :close-on-select="false"
                                     :custom-label="customLabel"
                                     :option-height="104"
                                     :show-labels="false"
                                     placeholder="Select Job(s) to Bulk Approve"
                                     :multiple="true">
                            <template slot="singleLabel" slot-scope="props">
                                <img v-if="image_preview_urls[props.option.id] === 'tif'" class="option__image" src="@/assets/images/tif.png">
                                <img v-else-if="image_preview_urls[props.option.id]" class="option__image" :src="image_preview_urls[props.option.id]">
                                <img v-else class="option__image" src="@/assets/images/raw.png">
                                <span class="option__desc">
                                    <span class="option__title">{{ props.option.name }}</span>
                                </span>
                            </template>
                            <template slot="option" slot-scope="props">
                                <img v-if="image_preview_urls[props.option.id] === 'tif'" class="option__image" src="@/assets/images/tif.png">
                                <img v-else-if="image_preview_urls[props.option.id]" class="option__image" :src="image_preview_urls[props.option.id]">
                                <img v-else class="option__image" src="@/assets/images/raw.png">
                                <div class="option__desc">
                                    <span class="option__title">#{{props.option.id}} {{props.option.name}}</span>
                                    <span class="option__small"> {{ getAmericanDateTimeFormat(props.option.created_at) }}</span>
                                </div>
                            </template>
                        </multiselect>
                    </div>
                </div>
                <div class="row pt-4">
                    <div class="input_box">
                        <input class="icon_input_search style_input1" v-model="search_key" @keyup="searchJob" type="text">
                        <img class="search_icon" src="@/assets/icons/search.svg" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="row" :class="isMobile ? 'm_b_103' : 'm_b_196'">
            <div :class="showCreateJob ? 'col-lg-10 order-lg-1 order-2 col-12' : 'col-12'">
                <div class="active_jobs_row">
                    <div class="card_job" :class="{card_job_s:getProcentDoneJob(job) > 0}" v-for="(job, index) in jobs_search" :key="'job-card-' + index">
                        <div class="img_div" @click="goToApproval(job)">
                            <img v-if="image_preview_urls[job.id] === 'tif'" src="@/assets/images/tif.png" class="card-img-top" :alt="'job-image-' + index">
                            <img v-else-if="image_preview_urls[job.id]" :src="image_preview_urls[job.id]" class="card-img-top" :alt="'job-image-' + index">
                            <img v-else src="@/assets/images/raw.png" class="card-img-top" :alt="'job-image-' + index">
                            <div v-if="(!job.user_work || isDataInPast(job.due_date)) && getProcentDoneJob(job) === 0" class="img-delete-hover">
                                <div v-if="!job.user_work" class="w-100 h-20 d-flex justify-content-end">
                                    <img src="@/assets/icons/trash.png" class="img-delete-hover__icon" title="Delete" @click.stop="openDeleteJobModal(job)">
                                </div>
                                <div v-if="isDataInPast(job.due_date)" class="w-100 h-20 d-flex justify-content-end">
                                    <img src="@/assets/icons/refresh-button.png" class="img-delete-hover__icon" title="Recreate" @click.stop="openRecreateJobModal(job)">
                                </div>
                            </div>
                            <div class="name_div main_text text-center">
                                {{ job.name }}
                            </div>
                        </div>
                        <div class="loader_line" :class="getProcentDoneJob(job) == 100 ? 'finished' : (getProcentDoneJob(job) > 0 ? 'in-process' : (isDataInPast(job.due_date) ? 'in-past' :'non-started'))">
                            <div class="done" :style="'width: ' + (isDataInPast(job.due_date) ? 100 : getProcentDoneJob(job)) + '%;'" :class="getProcentDoneJob(job) == 100 ? 'finished' : (isDataInPast(job.due_date) ? 'in-past' :'non-finished')"/>
                        </div>
                        <div class="card_body main_text text-center">
                            {{ getFilesReady(job) }}
                        </div>
                        <div class="card_body main_text text-center">
                            {{ getFilesApproved(job) }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="showCreateJob" class="col-lg-2 order-lg-2 order-1 col-12 d-flex justify-content-center align-items-center">
                <router-link class="red-gradient-button" :class="isMobile ? 'add_button_mobile m-0 w-100' : 'add_button'" :to="{ name: 'AddNewJob'}">
                    <div v-if="!isMobile" class="icon">
                        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" v-if="isMobile"
                             xmlns="http://www.w3.org/2000/svg">
                            <line x1="39.5" y1="4.5" x2="39.5" y2="75.5" stroke="white" stroke-width="9" stroke-linecap="round"/>
                            <line x1="75.5" y1="39.5" x2="4.5" y2="39.5" stroke="white" stroke-width="9" stroke-linecap="round"/>
                        </svg>
                        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" v-else xmlns="http://www.w3.org/2000/svg">
                            <line x1="39.5" y1="4.5" x2="39.5" y2="75.5" stroke="white" stroke-width="9" stroke-linecap="round"/>
                            <line x1="75.5" y1="39.5" x2="4.5" y2="39.5" stroke="white" stroke-width="9" stroke-linecap="round"/>
                        </svg>
                    </div>
                    <div v-if="!isMobile" class="button_label button_label_white main_text">
                        Add New Job
                    </div>
                    <div v-if="isMobile" class="button_label button_label_white main_text">
                        + Add New Job
                    </div>
                </router-link>
            </div>
        </div>
        <DeleteJobModal ref="delete_job_modal" @getJobs="$emit('getJobs')" />
        <RecreateJobModal ref="recreate_job_modal" @getJobs="$emit('getJobs')" />
    </div>
</template>

<script>
import RecreateJobModal from "./Modals/RecreateJobModal";
import DeleteJobModal from "./Modals/DeleteJobModal";
import Multiselect from "vue-multiselect";
import moment from "moment-timezone";
import {errorMessage, successMessage} from "../services/messages";
import axios from "axios";

export default {
    name: 'ActiveJobs',
    props: {
        isMobile: Boolean,
        showCreateJob: Boolean,
    },
    components: {
        RecreateJobModal,
        DeleteJobModal,
        Multiselect,
    },
    data() {
        return {
            search_key: null,
            jobs: [],
            jobs_search: [],
            selectedJobs: [],
            image_preview_urls: {}
        }
    },
    methods: {
        load(image_preview_urls, jobs) {
            this.search_key = null;
            this.image_preview_urls = image_preview_urls;
            this.jobs = jobs;
            this.jobs_search = jobs;
            //CUSTOM FIX TO LOAD TIFF IMAGES
            this.jobs.forEach(job => {
                let image_path = (job?.images[0]?.src_cropped || job?.images[0]?.src) || '';
                if(image_path) {
                    let file_name = image_path.split("/").pop();
                    let extension = file_name.split(".").pop();
                    if (extension.toLowerCase() === 'tiff') {
                        try {
                            //image_path = image_path.replace('/storage', '');
                            axios.get(image_path, {responseType: "blob"}).then(response => {
                                const blob = new Blob([response.data], {type: response.data.type});
                                const reader = new FileReader();
                                reader.onload = e => {
                                    let tiff = new Tiff({buffer: e.target.result});
                                    let canvas = tiff.toCanvas();
                                    this.image_preview_urls[job.id] = canvas.toDataURL();
                                }
                                reader.readAsArrayBuffer(blob);
                            });
                        } catch (e) {
                            this.image_preview_urls[job.id] = 'tif';
                        }
                    }
                    else if (extension.toLowerCase() === 'gif' || extension.toLowerCase() === 'webp' || extension.toLowerCase() === 'bmp' ||
                            extension.toLowerCase() === 'jpeg' || extension.toLowerCase() === 'jpg' || extension.toLowerCase() === 'png') {
                        this.image_preview_urls[job.id] = image_path;
                    }
                    else {
                        this.image_preview_urls[job.id] = "";
                    }
                }
                else {
                    this.image_preview_urls[job.id] = '';
                }
            });
            //CUSTOM FIX TO LOAD TIFF IMAGES
        },
        customLabel({id, name}) {
            return `#${id} ${name}`;
        },
        getAmericanDateTimeFormat(date) {
            return moment.utc(date).local().format('MM/DD/YYYY HH:mm a');
        },
        async autoApprove() {
            let ids = this.selectedJobs.map(el => el.id);
            let _jobs = this.jobs.filter(el => ids.includes(el.id));
            let count = 0;
            this.showLoader();
            try {
                for (let i = 0; i < _jobs.length; i++) {
                    let filter = _jobs[i]?.images?.filter(el => el.approval == 1);
                    let count_finished_img = (_jobs[i]?.finished_worked_images?.length || 0) - filter.length;
                    if (count_finished_img > 0) {
                        await this.$http.postAuth(`${this.$http.apiUrl()}approval-image`, {
                            job_id: _jobs[i].id,
                            checked_images: _jobs[i].images.filter(el => el.approval == false && el.decline != 1 && el.worked_img !== null).map(el => el.id),
                            review: null
                        });
                        count++;
                    }
                }
                successMessage(`You approved ${count} jobs`);
                this.hideLoader();
                this.$emit('getJobs');
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
                console.log(e);
                this.hideLoader();
            }
        },
        getFilesApproved(job) {
            let filter = job?.images?.filter(i => i.approval == 1);
            return filter.length + " files already approved";
        },
        getFilesReady(job) {
            let filter = job?.images?.filter(i => i.approval == 1);
            let count_img = (job?.images?.length || 0) - filter.length;
            let count_finished_img = (job?.finished_worked_images?.length || 0) - filter.length;
            if (!count_img) {
                return "All files approved";
            }
            return count_finished_img + "/" + count_img + " files ready for approval";
        },
        getLengthImagesJob(job) {
            return job?.images?.length || 0;
        },
        getCountFinishedImage(job) {
            return job?.finished_worked_images?.length || 0;
        },
        getProcentDoneJob(job) {
            let filter = job?.images?.filter(i => i.approval == 1);
            const countDoneJob = this.getCountFinishedImage(job) - filter.length;
            const countImagesJob = this.getLengthImagesJob(job) - filter.length;
            if (!countImagesJob) {
                return 100;
            }
            if (countDoneJob && countImagesJob) {
                return (countDoneJob / countImagesJob) * 100;
            }
            return 0;
        },
        goToApproval(job) {
            if (job.status === 'FINISHED') {
                this.$router.push('/job/' + job.id + '/upload-files/');
            }
            else if (this.getProcentDoneJob(job) > 0) {
                this.$router.push('/file-approval/' + job.id);
            }
            else if (this.getCountFinishedImage(job) > 0) {
                this.$router.push('/job/' + job.id + '/upload-files/');
            }
        },
        searchJob(event) {
            let search_text = (event.target.value).toUpperCase();
            if (search_text && search_text.length > 1) {
                this.jobs_search = this.jobs.filter(job =>
                    (job.description != null && job.description.toUpperCase().includes(search_text)) ||
                    (job.name != null && job.name.toUpperCase().includes(search_text))
                );
            } else {
                this.jobs_search = this.jobs;
            }
        },
        openDeleteJobModal(job) {
            this.$refs.delete_job_modal.load(job);
        },
        openRecreateJobModal(job) {
            this.$refs.recreate_job_modal.load(job);
        },
        isDataInPast(date) {
            return moment().diff(moment(date).tz(moment.tz.guess(true))) > 0;
        },
    },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>
<style lang="scss" scoped>

.red-gradient-button {
    text-decoration: none !important;
    background: linear-gradient(180deg, #E27582 0%, #FEC0C8 99.5%), #FCF5EF !important;
    border: 0.5px solid white !important;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25) !important;
}
.add_button {
    width: 120px;
    height: 120px;
    border: none;
    background: white;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .icon {
        text-align: center;

        svg {
            width: 50%;
        }
    }
    .button_label {
        font-size: 13px;

        &_white {
            color: white;
        }
    }
}
.input_box {
    position: relative;
}
.icon_input_search {
    padding-left: 40px;
    padding-right: 40px;
}
.style_input1 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    padding: 10px 13px;
    height: 35px;
    color: #494949;
    background: white;
    border: 0.3px solid #494949;
    box-sizing: border-box;
    border-radius: 100px;
    width: 100%;
    padding-left: 40px;
}
.search_icon {
    position: absolute;
    left: 25px;
    top: 10px;
    cursor: pointer;
}
.img-delete-hover {
    display: none;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: white;
    opacity: 0.6;
    padding: 15px;

    &__icon {
        cursor: pointer;
        opacity: 1;
        height: 18px !important;
        width: 18px !important;
    }
}
.img_div:hover {
    .img-delete-hover {
        display: flex;
        flex-direction: column;
        align-items: start;
    }
    .name_div {
        display: none;
    }
}
.main_text {
    color: #494949;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
}
.active_jobs_row {
    width: 100%;
    height: 300px;
    overflow: auto;
    white-space: nowrap;
    margin-top: 14px;
}
.card_job {
    width: 249px !important;
    height: 284px !important;
    display: inline-block;
    padding: 12px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    margin-right: 7px;
}
.img_div {
    width: 225px;
    height: 217px;
    overflow: hidden;
    border-radius: 5px;
    margin-bottom: 12px;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.name_div {
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 215px;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    position: absolute;
    top: 97px;
    left: 5px;
}
.loader_line {
    width: 170px;
    height: 7px;
    border: 0.2px solid;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 0 auto;
    overflow: hidden;

    &.finished {
        border-color: #ABFCA4 !important;
    }
    &.in-process{
        border-color: #FFA159;
    }
    &.non-started{
        border-color: #FD5860;
    }
    &.in-past {
        border-color: #FD5860;
    }
}
.done {
    height: 100%;
    background: #494949;

    &.finished {
        background: #ABFCA4 !important;
    }
    &.non-finished {
        background: #FFA159 !important;
    }
    &.in-past {
        background: #FD5860 !important;
    }
}
.card_job_s {
    cursor: pointer;
}
.card_body {
    width: 200px;
    height: 10px;
    margin: 5px auto;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
}
.m_b_103 {
    .card_job {
        width: 169px !important;
        height: 212px !important;
    }
    .img_div {
        width: 145px;
        height: 145px;
    }
    .name_div {
        width: 145px;
        top: 58px;
    }
    .loader_line {
        width: 110px;
    }
    .card_body {
        width: 130px;
    }
    .active_jobs_row {
        height: 240px;
    }
}
.auto-approve-button {
    padding: 14px 42px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: white;
    background: linear-gradient(180deg, #E27582 0%, #FEC0C8 99.5%), #FCF5EF;
    border: 0.5px solid white;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    text-decoration: none;
}
.option {
    &__desc, &__image {
        display: inline-block;
        vertical-align: middle;
    }
    &__image {
        max-height: 80px;
        margin-right: 10px;
    }
    &__desc {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

@media only screen and (max-width: 992px) {
    .add_button {
        width: 100%;
        height: 65px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px;
        margin-top: 28px;
    }
    .add_button_mobile {
        display: flex;
        justify-content: center;
        align-items: center;

        //width: 100%;
        height: 48px;
        margin-left: 5px;
        margin-right: 5px;

        background: #FCEFEF;
        border-radius: 8px;

        .button_label {
            font-size: 15px;
            text-align: center;
            color: #9E616A;
        }
    }
}
</style>
