<template>
    <div class="dropdown__container dropdown_header">
        <div class="dropdown__left-part">
            <div class="menu__item" :class="{active: submenu == 'BUSINESS'}" @mouseenter="changeSubmenu('BUSINESS')">
                Business Owner
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 153.000000 204.000000" preserveAspectRatio="xMidYMid meet" class="dropdown__right-arrow">
                    <g transform="translate(0.000000,204.000000) scale(0.100000,-0.100000)" fill="#ACACAC" stroke="none">
                        <path d="M395 1901 c-46 -19 -80 -85 -69 -135 4 -23 88 -113 337 -364 l332 -332 -325 -324 c-361 -360 -369 -371 -334 -442 25 -50 79 -79 132 -69 32 6 86 55 396 363 197 196 371 375 387 396 32 44 38 91 15 134 -22 42 -747 760 -779 772 -33 12 -64 12 -92 1z"/>
                    </g>
                </svg>
            </div>
            <div class="menu__item" :class="{active: submenu == 'EDITOR'}" @mouseenter="changeSubmenu('EDITOR')">
                Photo Retoucher
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 153.000000 204.000000" preserveAspectRatio="xMidYMid meet" class="dropdown__right-arrow">
                    <g transform="translate(0.000000,204.000000) scale(0.100000,-0.100000)" fill="#ACACAC" stroke="none">
                        <path d="M395 1901 c-46 -19 -80 -85 -69 -135 4 -23 88 -113 337 -364 l332 -332 -325 -324 c-361 -360 -369 -371 -334 -442 25 -50 79 -79 132 -69 32 6 86 55 396 363 197 196 371 375 387 396 32 44 38 91 15 134 -22 42 -747 760 -779 772 -33 12 -64 12 -92 1z"/>
                    </g>
                </svg>
            </div>
        </div>
        <div class="dropdown__right-part">
            <div v-if="submenu == 'BUSINESS'" class="dropdown__submenu">
                <div class="dropdown__item item">
                    <div class="item__image">
                        <img src="@/assets/images/HeaderDropdown/Bitmap.png" alt="">
                    </div>
                    <div class="item__body">
                        <router-link :to="{ name: 'SocialNetworksInfo' }" class="item__title" style="margin-top:-10px;">Ecommerce & Social Connectors</router-link>
                    </div>
                </div>
                <div class="dropdown__item item">
                    <div class="item__image">
                        <img src="@/assets/images/HeaderDropdown/Bitmap(1).png" alt="">
                    </div>
                    <div class="item__body">
                        <router-link :to="{ name: 'FAQ' }" class="item__title">FAQ</router-link>
                    </div>
                </div>
                <div class="dropdown__item item">
                    <div class="item__image">
                        <img src="@/assets/images/HeaderDropdown/Bitmap(2).png" alt="">
                    </div>
                    <div class="item__body">
                        <router-link :to="{ name: 'Prices' }" class="item__title">Prices & Plans</router-link>
                    </div>
                </div>
            </div>
            <div v-if="submenu == 'EDITOR'" class="dropdown__submenu">
                <div class="dropdown__item item">
                    <div class="item__image">
                        <img src="@/assets/images/HeaderDropdown/Bitmap(2).png" alt="">
                    </div>
                    <div class="item__body">
                        <router-link :to="{ name: 'HowItWorks' }" class="item__title">Prices</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderDropdown',
    data() {
        return {
            user: {},
            submenu:'BUSINESS',
        }
    },
    methods: {
        changeSubmenu(_submenu) {
            this.submenu = _submenu;
        }
    }
}
</script>

<style lang="scss" scoped>
$border: 1px solid #D8D8D8;

.dropdown {
    &__container {
        width: 100%;
        height: 240px;

        display: flex;
        flex-direction: row;

        background-color: white;

        border-top: $border;
        border-bottom: $border;
    }
    &__right-arrow {
        display: none;
    }
    &__left-part {
        height: 100%;
        width: 25%;

        display: flex;
        flex-direction: column;

        border-right: $border;
    }
    &__right-part {
        height: 100%;
        width: 75%;

        display: flex;
        flex-direction: row;
        align-items: center;
    }
    &__item {
        box-shadow: 0px 4px 14px rgba(35, 0, 24, 0.08);
        border-radius: 10px;
    }
    &__submenu {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 40px;
        padding-right: 40px;
    }
}
.item {
    width: 200px;
    height: 150px;
    margin-right: 20px;

    &__body {
        padding-top: 19px;
        padding-left: 24px;
        padding-right: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__title {
        text-align: start;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #333333;

        text-decoration: none;
    }
}
.menu {
    &__item {
        height: 100%;

        border-bottom: $border;
        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #696969;

        &:last-child {
            border: none;
        }
        &.active {
            color: #E27582;
            font-weight: 700;
        }
    }
}

@media only screen and (max-width: 922px) {
    .dropdown {
        &__container {
            width: 100%;
            height: 400px;
            display: block;
            background-color: white;
            border-top: none;
            border-bottom: none;
        }
        &__right-arrow {
            display: flex;
            margin-left: 5px;
            height: 20px;
        }
        &__left-part {
            height: 20%;
            margin-left: auto;
            margin-right: auto;
            width: 90%;
            display: flex;
            border-bottom: 1px solid #D8D8D8;
            border-left: 1px solid #D8D8D8;
            border-bottom-left-radius: 9px;
            border-bottom-right-radius: 9px;
        }
        &__right-part {
            height: 100%;
            width: 100%;
            overflow-y: auto;
            display: block;
        }
        &__submenu {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 15px;
            margin-bottom: 15px;
        }
        &__item {
            border: solid 1px #ACACAC;
            margin-bottom: 15px;
            margin-right: 0;
        }
    }
    .item__body {
        //padding-top:5px;
        padding-left: 24px;
        padding-right: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        a {
            text-align: center;
        }
    }
}
</style>
