<template>
    <div class="item_forgot_password">
        <div class="forgot_password">
            <div class="app_logo">
                <img class="" src="@/assets/icons/app_logo.svg" alt="icon-approved.svg"/>
            </div>
            <div class="form_forgot_password">
                <div class="text_title">Password reset</div>
                <span class="info__block" v-bind:title="password_info_rules">
                    <img class="info__icon" src="@/assets/icons/info.svg">
                    <span class="info__title">Password rules</span>
                </span>
                <div class="montserrat">
                    <div class="box_input">
                        <div class="mb-4">
                            <input type="password" @keypress="keypressPassword" :class="{ 'is-invalid': password_is_valid }" v-model="password" class="form-control" placeholder="new password">
                            <div class="invalid-feedback">{{ password_is_valid }}</div>
                        </div>
                        <div class="mb-4">
                            <input type="password" @keypress="keypressConfirmPassword" :class="{ 'is-invalid': confirm_password_is_valid }" v-model="confirm_password" class="form-control" placeholder="confirm new password">
                            <div class="invalid-feedback">{{ confirm_password_is_valid }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-4 box_btn">
                <div class="btn-c2" :class="{ 'not-active-btn':!active_btn}" @click="newPassword">Reset password</div>
                <div class="link justify-content-center" >
                    <div class="style_link" @click="backToLogin">Back to login</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapMutations} from 'vuex';
import {errorMessage} from "../services/messages";
import LoginEnum from "../enums/LoginEnum";
import helpFunctions from "../helpFunctions";

export default {
    name: "SetNewPassword",
    data() {
        return {
            password: null,
            confirm_password: null,
            password_is_valid: false,
            confirm_password_is_valid: false,
            password_info_rules: 'The password must be uppercase and lowercase. \n Password needs to be at least 8 characters.',
        };
    },
    computed: {
        active_btn() {
            return (this.password && this.confirm_password);
        }
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
        ]),
        backToLogin() {
            this.$emit('forgotPassword', LoginEnum.LOGIN);
        },
        keypressPassword() {
            this.password_is_valid = false;
        },
        keypressConfirmPassword() {
            this.confirm_password_is_valid = false;
        },
        getTokenFromUrlQuery() {
            const queryString = window.location.search;
            const parameters = new URLSearchParams(queryString);
            return parameters.get('t');
        },
        async newPassword() {
            if (this.formValidate()) {
                return;
            }
            try {
                this.showLoader();
                await this.$http.post(`${this.$http.apiUrl()}set_new_password`, {
                    'password': this.password,
                    'token':this.getTokenFromUrlQuery()
                });
                this.$emit('responseSetPassword', 'success_new_password');
                this.hideLoader();
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
        },
        clearValid() {
            this.password_is_valid = false;
            this.confirm_password_is_valid = false;
        },
        formValidate() {
            let error_form =  false;
            this.clearValid();
            if (!this.password) {
                this.password_is_valid = 'This field is required';
                error_form = true;
            }
            if (!this.confirm_password) {
                this.confirm_password_is_valid = 'This field is required';
                error_form = true;
            }
            if (helpFunctions.isValidPassword(this.password)) {
                this.password_is_valid = 'The password must be uppercase and lowercase';
                error_form = true;
            }
            if (this.password.length < 8) {
                this.password_is_valid = 'Password needs to be at least 8 characters';
                error_form = true;
            }
            if (helpFunctions.isValidPassword(this.password) && this.password.length < 8) {
                this.password_is_valid = 'The password must be uppercase and lowercase. Password needs to be at least 8 characters';
                error_form = true;
            }
            if (this.password && this.confirm_password && this.password !== this.confirm_password) {
                this.confirm_password_is_valid = 'Please make sure your passwords match';
                error_form = true;
            }
            return  error_form;
        },
    },
    watch: {
        password(val) {
            this.password_is_valid = helpFunctions.password(val);
        },
        confirm_password(val) {
            this.confirm_password_is_valid = helpFunctions.confirm_password(this.password, val);
        },
    }
}
</script>

<style lang="scss" scoped>
.text_title {
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 44px;
    color: black;
    text-align: center;
    padding-bottom: 19px;
}
.forgot_password {
    // height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.app_logo {
    display: none;
    padding-top: 80px;
    padding-bottom: 25px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
.montserrat .form-control {
    padding: 15px 40px;
}
.item_forgot_password {
    display: flex;
    justify-content: center;
    margin-top: 179px;
}
.montserrat {
    width: 100%;
    display: flex;
    justify-content: center;
}
.box_input {
    width: 550px;
}
.style_link {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: black;
    opacity: 0.65;
    cursor: pointer;
    text-decoration-line: underline;
}
.box_btn {
    display: grid;
    justify-content: center;
    padding-top: 90px;
    padding-bottom: 100px;
}
.link {
    display: flex;
    width: 100%;
    padding-top: 27px;
}
.info {
    &__block {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-bottom: 10px;
        cursor: help;
    }
    &__icon {
        width: 18px;
        height: 18px;
    }
    &__title {
        margin-left: 10px;
    }
}
@media only screen and (max-width: 992px) {
    .item_forgot_password {
        margin-top: 0px;
        display: inherit;
    }
    .montserrat {
        width: 100%;
    }
    .form_forgot_password {
        padding: 0 45px 0 45px;
    }
    .app_logo {
        display: block;
    }
    .box_btn {
        padding-top: 10px;
        padding-bottom: 40px;
    }
    .forgot_password {
        height: 100vh;
    }
}
</style>
