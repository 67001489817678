<template>
        <div>
            <HomePage v-if="is_type_user && user.type_user === editor"/>
            <BusinessHomePage v-if="is_type_user && user.type_user === business"/>
            <Cms v-if="is_type_user && user.type_user === admin"/>
        </div>
</template>

<script>
import HomePage from "../components/HomePage";
import BusinessHomePage from "@/views/BusinessHomePage";
import MainLayout from "../layouts/MainLayout";
import {errorMessage} from "../services/messages";
import {mapMutations} from "vuex";
import TypeUserEnum from "../enums/TypeUserEnum";
import Cms from "./Cms";

export default {
    name: 'Home',
    components: {
        Cms,
        MainLayout,
        HomePage,
        BusinessHomePage,
    },
    data() {
        return {
            user: {},
            is_type_user: true,
            business: TypeUserEnum.BUSINESS,
            editor: TypeUserEnum.EDITOR,
            admin: TypeUserEnum.ADMIN,
        }
    },
    mounted() {
        this.getUser();
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
            'setUser',
        ]),
        async getUser() {
            try {
                this.showLoader();
                const response = await this.$http.getAuth(`${this.$http.apiUrl()}user`);
                this.user = response?.data?.data || {};
                this.is_type_user = response?.data?.data.type_user || false;
                this.setUser(response?.data?.data || {});
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
    },
}
</script>
