<template>
    <div>
        <div class="modal" id="newCouponModal" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">New Coupon</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div class="modal-body justify-content-center">
                        <div style="display: flex;">
                            <input class="form-control" v-model="coupon.name" placeholder="Coupon name">
                        </div>
                        <div style="display: flex;">
                            <input class="form-control mt-3" v-model="coupon.discount" placeholder="Coupon discount: 20, 30, 50, etc">
                        </div>
                        <div style="display: flex;">
                            <select class="form-select mt-3" v-model="coupon.type">
                                <option value="unique">Unique</option>
                                <option value="usual">Usual</option>
                            </select>
                        </div>
                        <div style="display: flex;">
                            <select class="form-select mt-3" v-model="coupon.active">
                                <option :value="true">Yes</option>
                                <option :value="false">No</option>
                            </select>
                        </div>
                        <div style="text-align: center;">
                            <button type="button" class="btn_post_n mt-3" @click="createCoupon()">Create coupon</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {successMessage} from "../../../services/messages";

export default {
    name: "NewCouponModal",
    props: ['modal'],
    data() {
        return {
            coupon: {
                name: null,
                discount: null,
                type: "unique",
                active: true
            }
        };
    },
    methods: {
        async createCoupon() {
            this.coupon.discount = this.coupon.discount.replace("%", this.coupon.discount);
            await this.$http.post(`${this.$http.apiUrl()}cms/create-coupon`, this.coupon);
            successMessage("New coupon successfully created");
            this.$emit('refresh');
            this.modal.hide();
        },
    },
}
</script>
