<template>
    <div id="app">
        <router-view/>
        <Loader/>
        <div ref="app_css"></div>
        <div ref="app_font"></div>
    </div>
</template>

<script>
import Loader from "./components/Loader";
import {mapGetters} from "vuex";

export default {
    components: {
        Loader
    },
    metaInfo() {
        return {
            title: this.$route.meta.title || 'Strobeart'
        };
    },
    async mounted() {
        this.$refs.app_font.innerHTML = '<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=' + this.getDefaultFont + '">' +
            '<style>#app { font-family: ' + this.getDefaultFont + '!important; }</style>';

        this.$refs.app_css.innerHTML = '<style>' +
            '.btn-c2 { background: ' + this.getDefaultColor + '; }' +
            '.btn-c3 { background: ' + this.getDefaultColor + '; font-family: ' + this.getDefaultFont + '; }' +
            '.btn-mc2 { background: ' + this.getDefaultColor + '; }' +
            '.btn_modal_t2 { background: ' + this.getDefaultColor + '; }' +
            '.btn_add_prod { background: ' + this.getDefaultColor + '; }' +
            '.btn-carousel { background: ' + this.getDefaultColor + '; }' +
            '.btn_post_n { background: ' + this.getDefaultColor + '; }' +
            '.facebook-btn { background: ' + this.getDefaultColor + '; }' +
            '.btn_p1 { background: ' + this.getDefaultColor + '; }' +
            '.post_button { background: ' + this.getDefaultColor + '; }' +
            '.btn_generate_alt { background: ' + this.getDefaultColor + '; }' +
            '.item_cards_st { background: ' + this.getDefaultColor + '; }' +
            '.btn_d { background: ' + this.getDefaultColor + '; }' +
            '.btn_accept { background: ' + this.getDefaultColor + '; }' +
            '.active_am_pm { background: ' + this.getDefaultColor + '!important; }' +
            '.item_plan { border: 0.3px solid ' + this.getDefaultColor + '; }' +
            '.input_s { border: 0.5px solid ' + this.getDefaultColor + '; }' +
            '.custom_background { background: ' + this.getDefaultColor + '; }' +
            '.btn_added_prod { color: ' + this.getDefaultColor + '; border: 0.5px solid ' + this.getDefaultColor + '; }' +
            '.form-check-input:checked { background-color: ' + this.getDefaultColor + '; border-color: ' + this.getDefaultColor + '; }' +
            '.vc-highlight { background-color: ' + this.getDefaultColor + '!important; color: white!important; }' +
            '.vc-day-content:focus { background-color: ' + this.getDefaultColor + '!important; color: white!important; }' +
            '</style>';
    },
    computed: {
        ...mapGetters([
            'getDefaultColor',
            'getDefaultFont',
        ]),
    },
    watch: {
        getDefaultColor() {
            this.$refs.app_css.innerHTML = '<style>' +
                '.btn-c2 { background: ' + this.getDefaultColor + '; }' +
                '.btn-c3 { background: ' + this.getDefaultColor + '; font-family: ' + this.getDefaultFont + '; }' +
                '.btn-mc2 { background: ' + this.getDefaultColor + '; }' +
                '.btn_modal_t2 { background: ' + this.getDefaultColor + '; }' +
                '.btn_add_prod { background: ' + this.getDefaultColor + '; }' +
                '.btn-carousel { background: ' + this.getDefaultColor + '; }' +
                '.btn_post_n { background: ' + this.getDefaultColor + '; }' +
                '.facebook-btn { background: ' + this.getDefaultColor + '; }' +
                '.btn_p1 { background: ' + this.getDefaultColor + '; }' +
                '.post_button { background: ' + this.getDefaultColor + '; }' +
                '.btn_generate_alt { background: ' + this.getDefaultColor + '; }' +
                '.item_cards_st { background: ' + this.getDefaultColor + '; }' +
                '.btn_d { background: ' + this.getDefaultColor + '; }' +
                '.btn_accept { background: ' + this.getDefaultColor + '; }' +
                '.active_am_pm { background: ' + this.getDefaultColor + '!important; }' +
                '.item_plan { border: 0.3px solid ' + this.getDefaultColor + '; }' +
                '.input_s { border: 0.5px solid ' + this.getDefaultColor + '; }' +
                '.custom_background { background: ' + this.getDefaultColor + '; }' +
                '.btn_added_prod { color: ' + this.getDefaultColor + '; border: 0.5px solid ' + this.getDefaultColor + '; }' +
                '.form-check-input:checked { background-color: ' + this.getDefaultColor + '; border-color: ' + this.getDefaultColor + '; }' +
                '.vc-highlight { background-color: ' + this.getDefaultColor + '!important; color: white!important; }' +
                '.vc-day-content:focus { background-color: ' + this.getDefaultColor + '!important; color: white!important; }' +
                '</style>';
        },
        getDefaultFont() {
            this.$refs.app_font.innerHTML = '<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=' + this.getDefaultFont + '">' +
                '<style>#app { font-family: ' + this.getDefaultFont + '!important; }</style>';
        }
    }
}
</script>

<style lang="scss">
@import "~toastify-js/src/toastify.css";

* {
    // background: #F4F2F2;
}
main {
    flex-grow: 1;
}
body{
}
/* width */
::-webkit-scrollbar {
    width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.footer {
    flex-grow: 0;
    background: #FCF5EF;
    padding: 95px 105px 148px 88px;
}
.app_logo {
    width: 100%;
    text-align: center;
}
select:focus,
textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
    outline: 0 !important;
    -webkit-appearance: none;
    box-shadow: none !important;
}
.cp {
    cursor: pointer;
}
.modal-content {
    background-color: white!important;
}

.montserrat .form-control, .montserrat .form-select {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: black;
    padding: 15px 17px;
    border: 0.5px solid #494949;
    box-sizing: border-box;
    border-radius: 10px;
}
.montserrat .form-control:focus, .montserrat .form-select:focus {
    border-color: #cccccc;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.link-s1 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    text-decoration-line: underline;
    color: black;
}
.btn-c2 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    color: black;
    border: 0.5px solid #494949;
    box-sizing: border-box;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    padding: 17px 23px;
    text-align: center;
    cursor: pointer;
    width: 250px;
    white-space: nowrap;
}
.btn-c3 {
    border: 0.5px solid #494949;
    box-sizing: border-box;
    border-radius: 10px;
    cursor: pointer;
    padding: 7px 33px;
    white-space: nowrap;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: white;
}
.btn-mc3 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #494949;
    background: white;
    border: 0.5px solid #494949;
    box-sizing: border-box;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    height: 60px;
}
.btn-mc2 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: white;
    border: 0.5px solid #494949;
    box-sizing: border-box;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
}
.btn_modal_t2 {
    border: 0.5px solid #494949;
    box-sizing: border-box;
    border-radius: 100px;
    color: white;
    height: 47px;
    width: 148px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.disabled {
        background: #494949;
        opacity: 0.5;
    }
}
.btn_add_prod {
    border: 0.5px solid #494949;
    box-sizing: border-box;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    height: 34px;
    padding: 15px;
    margin-left: 10px;
    &.disabled {
        background: #494949;
        opacity: 0.6;
        cursor: default;
    }
}
.btn_added_prod {
    background: white;
    box-sizing: border-box;
    border-radius: 100px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    width: max-content;
    cursor: auto;
    padding: 15px;
    margin-left: 10px;
}
.facebook-btn {
    width: 80px;
    border: 0.5px solid #494949;
    box-sizing: border-box;
    border-radius: 100px;
    cursor: pointer;
    padding: 5px;
    white-space: nowrap;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: white;
    margin-left: auto;
    margin-right: auto;
    &.active {
        background: #494949;
    }
}
.btn_d {
    border: 0.5px solid #494949;
    box-sizing: border-box;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: white;
    height: 60px;
    cursor: pointer;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
}
.btn_p1 {
    border: 0.5px solid #494949;
    box-sizing: border-box;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    width: 140px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: white;
}
.btn_p1_0 {
    border: 0.5px solid #494949;
    box-sizing: border-box;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    width: 140px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #AD967F;
    background: white;
}
.post_button {
    width: 150px;
    height: 60px;
    color: white;
    border: 0.5px solid #494949;
    box-sizing: border-box;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
}
.btn-carousel {
    border: 0.5px solid #494949 !important;
    border-radius: 10px;
    padding: 7px 33px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: white;
    font-style: normal;
    box-sizing: border-box;
    cursor: pointer;
    white-space: nowrap;
}
.btn_accept {
    border: 0.5px solid #494949;
    box-sizing: border-box;
    border-radius: 100px;
    cursor: pointer;
    padding: 4px 10px;
    white-space: nowrap;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: white;
    height: 60px;
    width: 200px;
    margin-left: 20px;
    margin-right: 20px;
}
.btn_post_n {
    border: 0.5px solid #494949;
    box-sizing: border-box;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: white;
    width: 220px;
    height: 60px;
}
.btn_post_n2 {
    background: white;
    border: 0.5px solid #494949;
    box-sizing: border-box;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #494949;
    width: 220px;
    height: 60px;
}
.btn-c3:hover {
    opacity: 0.5;
}
.toastify {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    border-radius: 4px;
    overflow: hidden;
    .toast-close {
        color: #ffffff00;
        position: relative;
        background: none;
        &::after {
            content: url("./assets/images/close_toast_icon.svg");
            position: absolute;
            right: 4px;
        }
    }
}
.not-active-btn {
    cursor: default;
    opacity: 0.5;
}
.title_s {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 44px;
    color: #494949;
}

@media only screen and (max-width: 992px) {
    body {
        background: #F4F2F2!important;
        overflow: scroll !important;
    }
    .app-content {
        z-index: 10;
        padding: 0 10px;
    }
    .btn_post_n, .btn_post_n2 {
        width: 155px;
    }
    .title_s {
        font-size: 20px;
        line-height: 40px;
    }
}

@media only screen and (min-width: 993px) {
    .onboarding_footer{
       text-align: center;
    }
    .app-content-fluid{
        padding: 0 !important;
    }
    .app-content{
        padding: 0 96px;;
    }
    .box_container{
        padding-bottom: 100px;
    }
    .app-content {
        background: url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 2 2%22%3E %3Ccircle r=%221%22 cx=%221%22 cy=%221%22 fill=%22%23ffffff%22/%3E %3C/svg%3E'), #FCF5EF;
        background-repeat: no-repeat, no-repeat;
        background-position: bottom center, center;
        background-size: 400%, cover;
    }
}
</style>
